import {CurrencySetting} from '../../enums';

// For this to work, it is important that a whitespace is included, otherwise the currency
// will still default to USD where an invalid currencyCode is provided to the CurrencyPipe
export const EMPTY_CURRENCY_CODE = ' '

export const currencySelectOptsPlayers = [
	Object.freeze({label: 'Base Currency', value: CurrencySetting.euroBase}),
	Object.freeze({label: 'Operator Currency', value: CurrencySetting.operator}),
	Object.freeze({label: 'Player Currency', value: CurrencySetting.player})
];

export const currencySelectOptsAgents = [
	Object.freeze({label: 'Base Currency', value: CurrencySetting.euroBase}),
	Object.freeze({label: 'Operator Currency', value: CurrencySetting.operator}),
	Object.freeze({label: 'Agent Currency', value: CurrencySetting.player})
];
