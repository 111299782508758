import {Injectable} from '@angular/core';
import {AppConfigService} from '../../helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormatParams, ServiceAction, ServiceController} from '../utilities';
import {Observable, of} from 'rxjs';
import {TableDataResponse} from '../components';
import {WalletTransaction} from '../models/finance/wallet-transaction.model';
import {GATEWAY_TRANS_ID, GATEWAY_TRANS_PAYMENT_REF, WALLET_TRANS_ID} from '../constants/ui-db-name-mappings';
import {ResponseBodyType} from '../enums';
import {CSV_FORMAT, FORMAT_URL_PARAM} from '../constants';
import {AdjustWalletRequest} from '../models/finance/adjust-wallet-request';
import {BaseResponse} from '../models/general/update-response';
import {delay, map} from 'rxjs/operators';
import {BaseServiceImpl} from './base-service-impl';
import {GatewayTransaction} from '../models/finance/gateway-transaction.model';
import {dummyGatewayTransactionData} from './dummy-data/players.service.dummy';


@Injectable({
	providedIn: 'root'
})
export class PlayerTransactionService extends BaseServiceImpl {

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.WALLET_TRANSACTION, appConfigService.serviceBaseURL);
	}

	/**
	 * Fetch the {@link WalletTransaction}s for an operator or a specific player of the operator.
	 * @param searchParams The HttpParams for the request.
	 * @param playerID If provided then the returned transactions are for a specific player
	 * @param walletTypeID optional
	 */
	getWalletTransactions(
		playerID?: number,
		searchParams?: HttpParams,
		walletTypeID?: number
	): Observable<TableDataResponse<WalletTransaction>> {
		const segmentValues = playerID ? [playerID] : undefined;

		let params = searchParams ? searchParams : new HttpParams();

		if (walletTypeID) {
			params = params.set('walletTypeID', walletTypeID);
		}

		return this.validateDataTableRes(
			[WALLET_TRANS_ID.DB, 'walletType'], ServiceAction.GET_WALLET_TRANSACTIONS,
			segmentValues, params, ResponseBodyType.JSON
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((transaction: WalletTransaction) => {
					transaction.timestamp = transaction?.timestamp ? new Date(transaction.timestamp) : null;
					return transaction;
				});

				return res as TableDataResponse<WalletTransaction>;
			}));

		// return of(dummyWalletTransactionData).pipe(delay(200));
	}

	getWalletTransactionsCsv(playerId?: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const params = searchParams ?
			searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT) : searchParams;

		return this.getCsv(ServiceAction.GET_WALLET_TRANSACTIONS, playerId ? [playerId] : undefined, params,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	adjustBalance(playerId: number, data: AdjustWalletRequest): Observable<BaseResponse> {
		return this.post(ServiceAction.ADJUST_WALLET_BALANCE, data, null, [playerId]);
	}

	getGatewayTransactions(
		playerID?: number,
		paymentStateID?: number | string,
		searchParams?: HttpParams
	): Observable<TableDataResponse<GatewayTransaction>> {
		let params = searchParams ? searchParams : new HttpParams();

		if (paymentStateID) {
			params = params.set('paymentStateID', paymentStateID);
		}

		if (playerID) {
			params = params.set('playerID', playerID);
		}

		return this.validateDataTableRes(
			[GATEWAY_TRANS_ID.DB, GATEWAY_TRANS_PAYMENT_REF.DB], ServiceAction.GET_GATEWAY_TRANSACTIONS,
			undefined, params, ResponseBodyType.JSON, ServiceController.PAYMENTS
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((transaction: GatewayTransaction) => {
					transaction.createdDate = transaction?.createdDate ?
						new Date(transaction.createdDate) : null;

					transaction.confirmationDate = transaction?.confirmationDate ?
						new Date(transaction.confirmationDate) : null;

					return transaction;
				});

				return res as TableDataResponse<GatewayTransaction>;
			}));

		// return of(dummyGatewayTransactionData).pipe(delay(200));
	}

	getGatewayTransactionsCsv(playerId?: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const params = searchParams ?
			searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT) : searchParams;

		return this.getCsv(ServiceAction.GET_GATEWAY_TRANSACTIONS, playerId ? [playerId] : undefined, params,
			ResponseBodyType.Text, ServiceController.PAYMENTS, xFormatParams);
	}
}
