import {Component, OnInit, Input} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {HttpParams} from '@angular/common/http';
import {DrawProcessingService, DrawType} from '../shared';
import {
	SearchFieldType, ColumnType, DataTableLazyLoadEvent
} from '../../shared/components/data-table-v3';
import {TenantService} from '../../helio-core-services/services/tenants.service';
import {BoErrorHandlerService} from '../../helio-core-services/services/bo-error-handler.service';
import {AppConfigService} from '../../helio-core-services/services/app-config.service';
import {TenantDraw} from '../../shared/models/draws/tenant-draw.model';
import {FileDownloadUtility} from '../../shared/utilities/general-utilities/file-download.utility';
import {ExportCSVUtility} from '../../shared/utilities/general-utilities/export-csv.utility';
import {Draw, DrawSales} from '../../shared';
import {ResponsiveContent} from '../../shared/interfaces/responsive-content';
import * as ColumnNames from '../../shared/constants/ui-db-name-mappings';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LookupService} from '../../shared/services/lookup.service';

@Component({
	selector: 'he-draw-sales',
	styleUrls: ['./draw-sales.component.scss'],
	templateUrl: './draw-sales.component.html'
})
export class DrawSalesComponent extends ResponsiveContent<DrawSales> implements OnInit {
	private _gameDraw$ = new BehaviorSubject<Draw>(null);
	@Input() drawType: DrawType = DrawType.ACTIVE;

	getDrawForTenant$: Subscription;

	// advanced search

	constructor(
		protected appConfigService: AppConfigService,
		private drawProcService: DrawProcessingService,
		private tenantService: TenantService,
		private lookupService: LookupService,
		protected boErrorHandlerService: BoErrorHandlerService
	) {
		super(appConfigService, boErrorHandlerService);
	}

	@Input() set gameDraw(value: Draw) {
		this._gameDraw$.next(value);
	}

	get gameDraw() {
		return this._gameDraw$.getValue();
	}

	ngOnInit() {
		this.initDataTable();

		/*if (this.appConfigService.isCentralServer) {
			this.getTenants();
		}*/
		this.fetchLookups();

		this._gameDraw$.subscribe({
			next: value => {
				if (value) {
					this.getTableData(); // since it is important to only make the call after this.gameDraw?.drawID is ready
				}
			}
		});
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getDrawForTenant$);
	}

	initDataTable() {
		this.dataKey = ColumnNames.TENANT_ID.DB;

		this.cols = [
			{
				field: ColumnNames.TENANT_NAME.DB,
				header: ColumnNames.TENANT_NAME.UI
			},
			{
				field: ColumnNames.DRAW_LINES.DB,
				header: ColumnNames.DRAW_LINES.UI,
				columnType: ColumnType.Number
			},
			{
				field: ColumnNames.PAID_LINES.DB,
				header: ColumnNames.PAID_LINES.UI,
				columnType: ColumnType.Number
			},
			{
				field: ColumnNames.FREE_LINES.DB,
				header: ColumnNames.FREE_LINES.UI,
				columnType: ColumnType.Number
			},
			{
				field: ColumnNames.DRAW_DATE.DB,
				header: ColumnNames.DRAW_DATE.UI,
				columnType: ColumnType.Date
			}
		];

		this.searchFields = [
			{
				// For efficiency, BE indexing is utilised: TENANT_ID rather than TENANT is used for search
				property: ColumnNames.TENANT_ID.DB,
				label: ColumnNames.TENANT_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [],
				isArraySearch: false
			},
			{
				property: ColumnNames.DRAW_LINES.DB,
				label: ColumnNames.DRAW_LINES.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.PAID_LINES.DB,
				label: ColumnNames.PAID_LINES.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.FREE_LINES.DB,
				label: ColumnNames.FREE_LINES.UI,
				type: SearchFieldType.Number
			}
		];

		this.tableActions = this.getDefaultTableActions();
	}

	fetchLookups(): void {
		this.lookupService.getOperators().subscribe({
			next: res => {
				const tenantLookups: SelectItem[] = res;

				// Update the previously added transType object by reference
				const temp = this.searchFields.find(value => {
					return value.property === ColumnNames.TENANT_ID.DB;
				});
				temp.listOptions = tenantLookups;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams): void {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getDrawForTenant$);

		this.getDrawForTenant$ = this.drawProcService.getDrawSalesTotalByTenant(this.gameDraw?.drawID, this.params).subscribe({
			next: (res) => {
				this.data = res.resultSet as DrawSales[];
				this.totalRecords = res.totalRowCount;

				if (res.resultSet.length === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error, undefined, 'Getting DrawSales');
			}
		});
	}

	exportToCSV(selectedDraws: TenantDraw[], isAllDataSelected: boolean): void {
		this.loading = true;

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedDraws, isAllDataSelected, ColumnNames.TENANT_ID.DB
		);

		const observable = (this.drawType === DrawType.ACTIVE) ?
			this.drawProcService.getActiveTenantDrawsCsv(this.gameDraw.gameGroupID, exportHttpParams) :
			this.drawProcService.getPastTenantDrawsCsv(this.gameDraw.gameGroupID, exportHttpParams);

		observable.subscribe({
			next: res => {
				if (res !== undefined) {
					FileDownloadUtility.downloadCsv(res, `SalesForDrawID_${this.gameDraw?.drawID}`);
				}

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error, undefined, 'Exporting to CSV');
			}
		});
	}
}
