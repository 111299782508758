import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from './app-config.service';
import {ServiceController} from '../../shared/utilities/service-utilities/service-controller.urls';
import {ServiceAction} from '../../shared/utilities/service-utilities/service-action.urls';
import {GameGroup} from '../../shared/models/games/game-group.model';
import {Board} from '../../shared/models/games/board.model';
import {GamesDataLookup} from '../../shared/interfaces/lookup-interfaces';
import {API_DATA_INVALID} from '../../shared/constants';
import {isDefinedArray} from '../../shared/utilities/general-utilities/arrays.utils';
import {ResponseBodyType} from '../../shared';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';


/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class GameAdministrationService extends BaseServiceImpl {

	private static _getGamesRes: GamesDataLookup[] = [];

	public allGames: any[] = [];
	public defaultGames: any[] = [];
	public secondaryGames: any[] = [];

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.GAME_ADMINISTRATION, appConfigService.serviceBaseURL);
	}

	getAllGames() {
		if (this.allGames.length === 0) {
			return this.getAllGamesFromServer().pipe(
				map(res => {
					this.allGames = res;
					return this.allGames;
				}));
		} else {
			return of(this.allGames);
		}
	}

	getAllGamesFromServer() {
		return this.get(ServiceAction.GENERIC_GAMES);
	}

	getSecondaryGames(value?: any[]) {
		if (this.secondaryGames.length === 0) {
			return this.getSecondaryGamesFromServer(value).pipe(
				map(res => {
					this.secondaryGames = res;
					return this.secondaryGames;
				}));
		} else {
			return of(this.secondaryGames);
		}
	}

	getSecondaryGamesFromServer(value?: any[]) {
		return this.get(ServiceAction.GAME_SECONDARYGAMES, value);
	}

	getDefaultGames(value?: any[]) {
		if (this.defaultGames.length === 0) {
			return this.getDefaultGamesFromServer(value).pipe(
				map(res => {
					this.defaultGames = res;
					return this.defaultGames;
				}));
		} else {
			return of(this.defaultGames);
		}
	}

	getDefaultGamesFromServer(value?: any[]) {
		return this.get(ServiceAction.GAME_DEFAULTGAMES, value);
	}

	/**
	 * Gets GAMEGROUPS by user tenant IDs in token
	 */
	getGames(): Observable<GamesDataLookup[]> {
		if (isDefinedArray(GameAdministrationService._getGamesRes)) {
			return of(GameAdministrationService._getGamesRes);
		}

		return new Observable<GamesDataLookup[]>(subscriber => {
			this.get(ServiceAction.AWARD_TICKETS_GET_GAMES_ALL, undefined, undefined).subscribe({
				next: res => {
					GameAdministrationService._getGamesRes = [];

					res.forEach(obj => {
						// Validate, to present adding null or undefined lookup
						if (obj.name && obj.gameGroupID) {
							GameAdministrationService._getGamesRes.push({
								...obj,
								label: obj.name,
								value: obj.gameGroupID
							} as GamesDataLookup);
						}
					});

					if (GameAdministrationService._getGamesRes) {
						subscriber.next(GameAdministrationService._getGamesRes);
					} else {
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		});
	}

	getGamesByPlayer(playerID: number): Observable<GamesDataLookup[]> {
		return new Observable<GamesDataLookup[]>(subscriber => {
			this.get(undefined, [playerID, ServiceAction.AWARD_TICKETS_GET_GAMES_PLAYER],
				undefined, ResponseBodyType.JSON, ServiceController.PLAYER).subscribe({
				next: res => {
					const tempRes = [];

					res.forEach(obj => {
						// Validate, to present adding null or undefined lookup
						if (obj.name && obj.gameGroupID) {
							tempRes.push({
								...obj,
								label: obj.name,
								value: obj.gameGroupID
							} as GamesDataLookup);
						}
					});

					if (tempRes.length > 0) {
						subscriber.next(tempRes);
					} else {
						subscriber.error(new Error(API_DATA_INVALID));
						console.warn('getGamesByPlayer: ', API_DATA_INVALID);
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		});
	}

	/**
	 * Used only for Wallet until call in Wallet BE is updated
	 */
	getGamesData(tenantID: number, activeOnly: boolean = true): Observable<GamesDataLookup[]> {
		const gamesParams = new HttpParams().set('tenantID', tenantID.toString())
			.set('activeOnly', activeOnly.toString());
		return this.get(ServiceAction.AWARD_TICKETS_GET_GAMES_ALL, undefined, gamesParams);
	}

	getGameGroupByID(value?: any[]): Observable<GameGroup> {
		return this.get(ServiceAction.GAMEGROUP_GAMEGROUPBYID, value);
	}

	getCurrentGameRuleBoardsByGroup(gameGroupID: number): Observable<Board[]> {
		return this.get(ServiceAction.GAMEGROUP_CURRENT_GAMEGROUP_RULE_BOARDS, [gameGroupID]);
	}
}
