import {LogService} from '../services/log.service';

export class LoadingManager {
	public static readonly LOADING_INIT_DELAY = 200; // in ms
	public static readonly MIN_LOADING_TIME = 60;

	public tag = LoadingManager.name;

	private timeoutId: number;

	private loadingSet: boolean;
	private loading: boolean;
	private startTime: number;
	private endTime: number;

	/**
	 * @param immediateLoad is necessary in some cases so that if an edit or delete op is done,
	 *  loading can be shown immediately to ensure the UI appears responsive.
	 * @return true wrapped in a Promise if {@link loading} is set to true after 200ms and before next returns.
	 * the UI should align its loading field in the then block in such case.
	 */
	public async init(immediateLoad: boolean = false): Promise<boolean> {
		this.loadingSet = false;
		this.startTime = null;
		this.endTime = null;

		if (immediateLoad) {
			// todo: refactor Promise<boolean> to Observable<boolean> so that this.loading can be returned
			//  here rather than relying on caller to remember to align its value with this class
			this.loading = true;
			this.startTime = performance.now();

			// Important to set true to trigger "this.loadingSet && timeElapsed < LoadingManager.MIN_LOADING_TIME"
			//  in handleLoadingCompletion
			this.loadingSet = true;
		}

		LogService.devLog(this.tag + ': init');

		if (this.timeoutId) {
			// Use class scope for #loadingTimeout to ensure that multiple instances do not
			// exist if multiple calls are made to the method.
			window.clearTimeout(this.timeoutId);
		}

		return new Promise(resolve => {
			// Start a timeout to set loading to true if next is not called back within 200ms
			this.timeoutId = window.setTimeout(() => {
				this.loading = true;
				this.startTime = performance.now();
				this.loadingSet = true;

				LogService.devLog(this.tag + ': startTime = ' + this.startTime);

				resolve(this.loading);
			}, LoadingManager.LOADING_INIT_DELAY);
		});
	}

	public async handleLoadingCompletion(): Promise<boolean> {
		let timeoutIdLocal: number;

		// 1. Clear the initial timeout immediately to prevent loadingTimeout being activated if this call is made in <200ms
		// 2. Calling before setting endTime also ensures that startTime is always the lower value.
		window.clearTimeout(this.timeoutId);

		this.endTime = performance.now();
		LogService.devLog(this.tag + ': endTime = ' + this.endTime);
		const timeElapsed = this.startTime ? this.endTime - this.startTime : LoadingManager.MIN_LOADING_TIME;

		return new Promise(resolve => {
			const onCompletion = () => {
				this.loading = false;
				this.loadingSet = false; // reset so that if init is not called again waiting to set loading is skipped and a warning logged

				if (timeoutIdLocal) {
					window.clearTimeout(timeoutIdLocal);
					timeoutIdLocal = null;
				}

				resolve(this.loading);
			}

			// If loadingNotes was set to true, wait at least MIN_TIME_ELAPSED before resetting it
			if (this.loadingSet && timeElapsed < LoadingManager.MIN_LOADING_TIME) {
				LogService.devLog(this.tag + ': IF timeElapsed = ' + timeElapsed);
				const remainingTime = LoadingManager.MIN_LOADING_TIME - timeElapsed;
				// remainingTime = remainingTime >= 0 ? remainingTime : 0; // to ensure that remaining time is not negative

				timeoutIdLocal = window.setTimeout(onCompletion, remainingTime);
			} else {
				LogService.devLog(this.tag + ': ** ELSE ** timeElapsed = ' + timeElapsed);
				onCompletion();
			}
		});
	}
}
