import {Injectable} from '@angular/core';
import {AppConfigService} from '../../helio-core-services';
import {HttpClient} from '@angular/common/http';
import {ServiceAction, ServiceController} from '../utilities';
import {Observable, of} from 'rxjs';
import {API_DATA_INVALID} from '../constants';
import {OperatorSettingDTO} from '../models/operator/operator-setting';
import {BaseServiceImpl} from './base-service-impl';

@Injectable({
	providedIn: 'root'
})
export class OperatorSettingService extends BaseServiceImpl {

	private static _operatorSetting: OperatorSettingDTO = undefined;

	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.USER_OWN, appConfigService.serviceBaseURL);
	}

	getSettings(): Observable<OperatorSettingDTO> {
		// return of(dummyGetOperatorSettings);

		if (OperatorSettingService._operatorSetting) {
			return of(OperatorSettingService._operatorSetting);
		}

		return new Observable<OperatorSettingDTO>(subscriber => {
			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.get(ServiceAction.OPERATOR_SETTINGS).subscribe({
				next: res => {
					OperatorSettingService._operatorSetting = undefined;

					// Validate before passing on to caller (currently the bare minimum validation is done here)
					if (res.tenants) {
						OperatorSettingService._operatorSetting = res as OperatorSettingDTO;
					}

					if (OperatorSettingService._operatorSetting) {
						subscriber.next(OperatorSettingService._operatorSetting);
					} else {
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}
}
