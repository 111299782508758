import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { DataTableServerResponse } from '../../shared/components/data-table-v3/shared/data-table-server-response.model';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';

@Injectable()
export class ActionPermissionsService extends BaseServiceImpl {
	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.ACTION_PERMISSIONS, appConfigService.serviceBaseURL);
	}

	getActions(): Observable<DataTableServerResponse> {
		return this.get();
	}
}
