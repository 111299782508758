import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Player} from '../../../models/player/players.model';
import {
	EWalletCreateRequest,
	EWalletDetails,
	EWalletProvider,
	EWalletUpdateRequest,
	PaymentMethodLookup
} from '../../../models/finance/transfer-information.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LookupService} from '../../../services/lookup.service';
import {BoErrorHandlerService} from '../../../../helio-core-services';
import {forkJoin, Subscription} from 'rxjs';
import {ActivatedRoute, ParamMap} from '@angular/router';

@Component({
	selector: 'he-e-wallet-editor-dialog',
	templateUrl: './e-wallet-editor-dialog.component.html',
	styleUrls: ['./e-wallet-editor-dialog.component.scss']
})
export class EWalletEditorDialogComponent implements OnInit {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() forCRUD = false;

	@Input() playerPartialData: Player;

	@Input() operation!: 'Create' | 'Edit'

	@Input() submitLabel = 'Submit';

	@Input() editable = true;

	@Input() provider: EWalletProvider;
	@Input() selectedEWallet: EWalletDetails;
	@Input() tenantID?: number;

	@Output() closeEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<EWalletCreateRequest | EWalletUpdateRequest>();

	dialogTitle!: 'eWallet Details Editor' | 'Add eWallet Details' | 'Edit eWallet Details';

	form: FormGroup;

	eWalletPaymentMethodsOpts: PaymentMethodLookup[] = [];

	isLoadingLookups = false;

	routePlayerID!: number;
	private routeSubs$: Subscription;

	constructor(
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private lookupService: LookupService,
		private boErrorHandlerService: BoErrorHandlerService) {
	}

	ngOnInit() {
		// this.canUpdateBankDetails = this.appGuard.hasActionPermission(ServiceController.WITHDRAWALS, ServiceAction.UPDATE_BANK_DETAILS);

		this.dialogTitle = this.operation === 'Create' ?
			'Add eWallet Details' : this.operation === 'Edit' ?
				'Edit eWallet Details' : 'eWallet Details Editor'

		this.formInit();
		this.setFormData();

		if (this.forCRUD) {
			this.setState(true); // so that all editable inputs can take user input
		}

		this.routeSubs$ = this.route.paramMap.subscribe((params: ParamMap) => {
			this.routePlayerID = Number(params.get('id'));
		});
	}

	onSubmit() {
		if (this.routePlayerID !== this.playerPartialData.playerID) {
			console.error('EWalletEditorDialogComponent#onSubmit: routeID does not equal dto ID.')
			return;
		}

		const rawValue = this.form.getRawValue();

		if (this.operation === 'Create') {
			const request: EWalletCreateRequest = {
				playerID: this.routePlayerID,
				paymentMethodID: rawValue['paymentMethodID'],
				holderName: rawValue['accHolderName'],
				accountReference: rawValue['accReference'],
			}

			this.submitEvent.emit(request);
		} else if (this.operation === 'Edit') {
			const request: EWalletUpdateRequest = {
				playerPaymentAccountID: rawValue['playerPaymentAccountID'],
				holderName: rawValue['accHolderName'],
				accountReference: rawValue['accReference'],
			}

			this.submitEvent.emit(request);
		} else {
			console.error('EWalletEditorDialogComponent: unhandled dialog type.')
		}
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	private formInit() {
		const accHolderName = this.playerPartialData.fullName ?? '';
		const tempPlayerID = this.playerPartialData.playerID ?? ''

		this.form = this.formBuilder.group({
			playerID: new FormControl({value: tempPlayerID, disabled: true}, {
				validators: [Validators.required]
			}),
			accHolderName: new FormControl(accHolderName, {
				validators: [Validators.required]
			}),
			paymentMethodID: new FormControl('', { // this.provider?.paymentMethodID
				validators: [Validators.required]
			}),
			accReference: new FormControl('', {
				validators: [Validators.required] // Validators.pattern(RE_ALPHABETIC_WS)
			}),
		});

		if (this.operation === 'Edit') {
			this.form?.addControl(
				'playerPaymentAccountID',
				new FormControl(this.selectedEWallet?.playerPaymentAccountID ?? '', {
					validators: [Validators.required]
				})
			);

			this.form.controls['accReference'].setValue(this.selectedEWallet?.accountReference)
		}

		this.setState(false);
	}

	setState(enabled: boolean) {
		for (const entry in this.form?.controls) {
			if (entry && enabled) {
				this.form.controls[entry].enable();
			} else if (entry && !enabled) {
				this.form.controls[entry].disable();
			}
		}

		this.form?.updateValueAndValidity();
	}

	private setFormData() {
		this.isLoadingLookups = true;

		const observables: any = {
			getPaymentMethods: this.lookupService.getPaymentMethods(this.playerPartialData.tenantID, true)
		};

		forkJoin(observables).subscribe({
			next: (res: any) => {
				this.eWalletPaymentMethodsOpts = res.getPaymentMethods;

				if (this.form) {
					this.form.get('paymentMethodID').setValue(this.provider?.paymentMethodID);
				}

				this.isLoadingLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}
}
