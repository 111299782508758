import {Injectable} from '@angular/core';
import {AppConfigService} from 'src/app/helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
	ServiceController, DataTableServerResponse, ServiceAction, ResponseBodyType,
	Currency, FormatParams, TableDataResponse, CurrencySetting
} from 'src/app/shared';
import {Observable} from 'rxjs';
import {RetailAgent, RetailAgentRequest} from '../models/retail-agent.model';
import {API_DATA_INVALID, CSV_FORMAT, CURRENCY_TYPE_PARAM, FORMAT_URL_PARAM} from '../constants';
import {PlayerTrail} from '../models/player/player-trail.model';
import {PlayerParticipation} from '../models/player/player-participation.model';
import {AgentPasswordChangeResponse} from '../models/agents/agent-password.model';
import {BaseServiceImpl} from './base-service-impl';

@Injectable()
export class RetailAgentService extends BaseServiceImpl {

	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController?.RETAIL_AGENT, appConfigService?.serviceBaseURL);
	}

	public getRetailAgentByID(id: number): Observable<RetailAgent> {
		return this.get<RetailAgent>(undefined, [id], undefined);
	}

	public getRetailAgents(searchParams?: HttpParams): Observable<TableDataResponse<RetailAgent>> {
		return this.validateDataTableRes([], undefined, undefined, searchParams);
	}

	public getRetailAgentsCsv(searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<any> {
		const tempParams = searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT);
		return this.getCsv(
			undefined, undefined, tempParams, ResponseBodyType.Text, undefined, xFormatParams
		);
	}

	public getOperatorCurrencies(tenantID: number): Observable<Currency[]> {
		// Do not retain res as singleton since this will 80/20 be different for each call

		return new Observable<Currency[]>(subscriber => {
			this.get(ServiceAction.AGENTS_OPERATOR_CURRENCIES, [String(tenantID)], undefined,
				undefined, ServiceController.USER_OWN)
				.subscribe({
					next: res => {
						const currencies: Currency[] = []

						res.forEach(obj => {
							// Validate, to present adding null or undefined lookup
							if (obj.currencyCode && obj.currencyID) {
								currencies.push({
									...obj,
									label: `${obj.currencyCode} - ${obj.name}`,
									value: obj.currencyID
								} as Currency);
							}
						});

						if (res.length > 0) {
							subscriber.next(currencies);
						} else {
							subscriber.error(API_DATA_INVALID);
						}

						subscriber.complete();
					},
					error: err => {
						subscriber.error(err);
					}
				})
		});
	}

	public createRetailAgent(request: RetailAgentRequest): Observable<RetailAgent> {
		return this.post(undefined, request);
	}

	public updateRetailAgent(request: RetailAgentRequest): Observable<RetailAgent> {
		return this.edit(undefined, request);
	}

	public requirePasswordChange(id: number): Observable<AgentPasswordChangeResponse> {
		return this.edit(`${ServiceAction.AGENTS_RESET_PASSWORD}/${id}`);
	}

	public topUpRetailAgent(retailAgentID: number, amount: any) {
		return this.edit(`${ServiceAction.AGENTS_TOPUP_BALANCE}/${retailAgentID}`, amount);
	}

	public adjustRetailAgentBalance(retailAgentID: number, newBalance: any) {
		return this.edit(`${ServiceAction.AGENTS_ADJUST_BALANCE}/${retailAgentID}`, newBalance);
	}

	public adjustRetailAgentBalanceToDefaultAll(tenantID: number, newBalance: number) {
		return this.get(`${ServiceAction.AGENTS_ADJUST_BALANCE_DEFAULT_ALL}?tenantID=${tenantID}&balance=${newBalance}`);
	}

	public getTopUpHistory(retailAgentID: number, searchParams: HttpParams): Observable<DataTableServerResponse> {
		return this.validateDataTableRes([], ServiceAction.AGENTS_TOPUP_HISTORY, [String(retailAgentID)], searchParams);
	}

	public getTopUpHistoryCsv(retailAgentID: number, searchParams: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		return this.getCsv(
			ServiceAction.AGENTS_TOPUP_HISTORY, [String(retailAgentID)], searchParams,
			ResponseBodyType.Text, undefined, xFormatParams
		);
	}

	/**
	 * Return all player transactions associated with a given agent.
	 */
	public getTransactions(agentId: number, currency: CurrencySetting = CurrencySetting.euroBase, searchParams?: HttpParams)
		: Observable<TableDataResponse<PlayerTrail>> {
		// return this.playersService.getPlayerTransactions(searchParams);
		// return of(dummyTransactionsData);

		const params = searchParams
			.set(CURRENCY_TYPE_PARAM, currency);

		return this.validateDataTableRes(
			['playerID', 'transactionID'], undefined,
			[String(agentId), ServiceAction.AGENTS_PLAYERS_TRAIL], params
		);
	}

	public getTransactionsCsv(
		agentId: number,
		currency: CurrencySetting = CurrencySetting.euroBase,
		searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const params = searchParams
			.set(CURRENCY_TYPE_PARAM, currency)
			.set(FORMAT_URL_PARAM, CSV_FORMAT);

		return this.getCsv(
			undefined, [agentId, ServiceAction.AGENTS_PLAYERS_TRAIL],
			params, ResponseBodyType.Text, undefined, xFormatParams
		);
	}

	/**
	 * Return all participation associated with a given agent.
	 */
	getPlayerParticipations(agentId: number, currency: CurrencySetting = CurrencySetting.euroBase, searchParams?: HttpParams)
		: Observable<TableDataResponse<PlayerParticipation>> {
		const params = searchParams
			.set(CURRENCY_TYPE_PARAM, currency);

		return this.validateDataTableRes(
			['playerID', 'participationID'], undefined,
			[String(agentId), ServiceAction.AGENTS_GAME_PARTICIPATION], params
		);
	}

	getPlayerParticipationCSV(
		agentId: number,
		currency: CurrencySetting = CurrencySetting.euroBase,
		searchParams?: HttpParams,
		xFormatParams?: FormatParams[]): Observable<string> {
		// return of(dummyParticipationData);
		// ServiceController.PLAYER_CONTROLLER
		const tempParams = searchParams
			.set(CURRENCY_TYPE_PARAM, currency)
			.set(FORMAT_URL_PARAM, CSV_FORMAT);

		return this.getCsv(undefined, [String(agentId), ServiceAction.AGENTS_GAME_PARTICIPATION], tempParams,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

}
