<he-base-dialog header="{{dialogTitle}}" [(visible)]="visible" [isLoadingLookups]="isLoadingLookups"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="onCloseDialog()"
				[rejectBtnOnly]="!forCRUD" [useConfirmDialog]="false" [submitLabel]="submitLabel"
				(submitEvent)="onSubmit()" [disableSubmit]="!form.valid">

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<label for="playerID">PlayerID</label>
		<input pInputText id="playerID" formControlName="playerID"
			   autocomplete="off" type="text" [class.he-disabled]="true"/>

		<!--operation === 'Edit'  [class.he-always-disabled]="true"-->
		<label for="accHolderName">Beneficiary Name</label>
		<input pInputText id="accHolderName" [class.he-disabled]="true"
			   formControlName="accHolderName" autocomplete="off" type="text"/>

		<label>{{ 'Provider' }}</label>
		<p-dropdown [inputId]="'paymentMethodID'" [options]="eWalletPaymentMethodsOpts" appendTo="body"
					[class.he-disabled]="true"
					formControlName="paymentMethodID" placeholder="Please select a Payment Method">
		</p-dropdown>

		<ng-container *ngIf="operation === 'Edit'">
			<label for="playerPaymentAccountID">Payment Account ID</label>
			<input pInputText id="playerPaymentAccountID"
				   formControlName="playerPaymentAccountID" autocomplete="off" type="text"/>
		</ng-container>

		<label for="accReference">Account Reference</label>
		<input pInputText id="accReference" formControlName="accReference" autocomplete="off" type="text"/>
	</form>
</he-base-dialog>


