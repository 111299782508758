import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from './app-config.service';
import {TenantType, Tenant} from '../../tenant-management/shared';
import {DataTableServerResponse} from '../../shared/components/data-table-v3';
import {ServiceController, ServiceAction} from '../../shared/utilities/service-utilities';
import {ResponseBodyType} from '../../shared/enums';
import {AppGuard} from '../../shared';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';

/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class TenantService extends BaseServiceImpl {
	private static tenants: Tenant[] = [];
	public retailTenants: Tenant[] = [];
	public tenantTypes: TenantType[] = [];
	private loggedOperatorIDs: string[];

	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.TENANTS, appConfigService.serviceBaseURL);

		this.loggedOperatorIDs = AppGuard.getLoggedOperatorIDs();
	}

	public getTenantTypes(): Observable<TenantType[]> {
		if (this.tenantTypes.length === 0) {
			return this.getTenantTypesFromServer().pipe(
				map(res => {
					this.tenantTypes = res;
					return this.tenantTypes;
				}));
		} else {
			return of(this.tenantTypes);
		}
	}

	public getTenantsFromServer(searchParams?: HttpParams): Observable<DataTableServerResponse> {
		return this.get<DataTableServerResponse>(ServiceAction.GENERIC_GET, undefined, searchParams);
	}

	public getTenantsFromServerCsv(searchParams?: HttpParams): Observable<string> {
		return this.getCsv(ServiceAction.GENERIC_GET, undefined, searchParams, ResponseBodyType.Text);
	}

	public getTenantTypesFromServer(): Observable<TenantType[]> {
		return this.get(ServiceAction.TENANT_MANAGEMENT_TYPES);
	}

	public createOperatorOrBroker(isBroker = false, postData: Tenant) {
		const action = !isBroker ? ServiceAction.TENANT_MANAGEMENT_CREATE_OPERATOR : ServiceAction.TENANT_MANAGEMENT_CREATE_BROKER;
		return this.post(action, postData);
	}

	public updateOperatorOrBroker(isBroker = false, editData: Tenant) {
		const action = !isBroker ? ServiceAction.TENANT_MANAGEMENT_UPDATE_OPERATOR : ServiceAction.TENANT_MANAGEMENT_UPDATE_BROKER;
		return this.edit(action, editData);
	}

	public getContactTypes() {
		return this.get(ServiceAction.TENANT_MANAGEMENT_CONTACT_TYPES);
	}

	public getIntegrationTypes() {
		return this.get(ServiceAction.TENANT_MANAGEMENT_INTEGRATION_TYPES);
	}
}
