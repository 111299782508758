<he-base-dialog header="{{dialogTitle}}" [visible]="visible" [isLoadingLookups]="isLoadingLookups || loading"
				[style]="{'max-width': '550px', width: '550px'}" (closeEvent)="onCloseDialog()"
				[useConfirmDialog]="false" [submitLabel]="submitLabel"
				(submitEvent)="onSubmit()" [disableSubmit]="!form.valid">

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<label for="accHolderName">Beneficiary Name</label>
		<input pInputText id="accHolderName" formControlName="accHolderName" autocomplete="off" type="text"/>

		<!--Use pInputText as BE currently supports appending 00, unsuited to numbers,
		 and which also makes + prefix redundant-->
		<label for="mobileNumber">Beneficiary Mobile Number</label>
		<input pInputText id="mobileNumber" formControlName="mobileNumber" type="text"/>
		<!--<p-inputNumber id="mobileNumber" formControlName="mobileNumber" prefix="+" [useGrouping]="false"></p-inputNumber>-->

		<label for="nationalIdNum">National ID No.</label>
		<input pInputText id="nationalIdNum" formControlName="nationalIdNum" autocomplete="off" type="text"/>

		<label for="bankName">Bank Name</label>
		<input pInputText id="bankName" formControlName="bankName" autocomplete="off" type="text"/>

		<label for="bankCity">City</label>
		<input pInputText id="bankCity" formControlName="bankCity" autocomplete="off" type="text"/>

		<label for="bankState">State</label>
		<input pInputText id="bankState" formControlName="bankState" autocomplete="off" type="text"/>

		<label>{{ 'Country' }}</label>
		<p-dropdown [inputId]="'bankBranchCountry'" [options]="unsanctionedCountries" appendTo="body"
					formControlName="bankBranchCountry" placeholder="Please select a country">
		</p-dropdown>
	</form>
</he-base-dialog>

