import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { ServiceController, ServiceAction } from '../../shared/utilities';
import { LookupService } from 'src/app/shared/services/lookup.service';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';

/**
 * Service class used to handle HTTP requests related to the Groups Service
 * @deprecated use {@link LookupService#getPlayerNomenclature} as replacement.
 */
@Injectable()
export class NomenclaturesService extends BaseServiceImpl {
	public currencies: any;
	public languages: any;
	public countries: any;
	public timezones: any;
	public serverNames: any;

	/**
	 * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 */
	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(
			http,
			(appConfigService.isCentralServer) ?
				ServiceController.NOMENCLATURES_CENTRAL : ServiceController.NOMENCLATURES_TENANT,
			appConfigService.serviceBaseURL
		);
	}

	public getCurrencies(value?: any[]) {
		if (this.currencies === undefined) {
			return this.getCurrenciesFromServer(value).pipe(
				map(res => { this.currencies = res; return this.currencies; }));
		} else {
			return of(this.currencies);
		}
	}

	public getLanguages(value?: any[]) {
		if (this.languages === undefined) {
			return this.getLanguagesFromServer(value).pipe(
				map(res => { this.languages = res; return this.languages; }));
		} else {
			return of(this.languages);
		}
	}

	public getCountries(value?: any[]) {
		if (this.countries === undefined) {
			return this.getCountriesFromServer(value).pipe(
				map(res => { this.countries = res; return this.countries; }));
		} else {
			return of(this.countries);
		}
	}

	public getTimezones(value?: any[]) {
		if (this.timezones === undefined) {
			return this.getTimezonesFromServer(value).pipe(
				map(res => { this.timezones = res; return this.timezones; }));
		} else {
			return of(this.timezones);
		}
	}

	public getServerNames(value?: any[]) {
		if (this.serverNames === undefined) {
			return this.getServerNamesFromServer(value).pipe(
				map(res => { this.serverNames = res; return this.serverNames; }));
		} else {
			return of(this.serverNames);
		}
	}

	private getCurrenciesFromServer(value?: any[]) {
		return this.get(ServiceAction.NOMENCLATURES_CURRENCY, value);
	}

	private getLanguagesFromServer(value?: any[]) {
		return this.get(ServiceAction.NOMENCLATURES_LANGUAGES, value);
	}

	private getCountriesFromServer(value?: any[]) {
		return this.get(ServiceAction.NOMENCLATURES_COUNTRIES, value);
	}

	private getTimezonesFromServer(value?: any[]) {
		return this.get(ServiceAction.NOMENCLATURES_TIMEZONES, value);
	}

	private getServerNamesFromServer(value?: any[]) {
		return this.get(ServiceAction.NOMENCLATURES_SERVERNAMES, value);
	}
}
