import { Injectable } from '@angular/core';

import {Observable, fromEvent, debounceTime} from 'rxjs';

@Injectable()
export class WindowEventsService {
	onBlur: Observable<Event>;
	onFocus: Observable<Event>;
	onMouseMoveDebounced: Observable<Event>;
	onKeyUp: Observable<Event>;
	onKeyDown: Observable<Event>;
	onMouseUp: Observable<Event>;
	onMouseDown: Observable<Event>;

	constructor() {
		this.onBlurHandler();
		this.onFocusHandler();
		this.onMousemoveHandler();
		this.onKeyUpHandler();
		this.onKeyDownHandler();
		this.onMouseUpHandler();
		this.onMouseDownHandler();
	}

	private onBlurHandler() {
		this.onBlur = fromEvent(window, 'blur');
	}

	private onFocusHandler() {
		this.onFocus = fromEvent(window, 'focus');
	}

	private onMousemoveHandler() {
		this.onMouseMoveDebounced = fromEvent(window, 'mousemove')
			.pipe(debounceTime(1000)) // 1s debounce delay to deal with the high frequency nature of mousemove;
	}

	private onKeyUpHandler() {
		this.onKeyUp = fromEvent(window, 'keyup');
	}

	private onKeyDownHandler() {
		this.onKeyDown = fromEvent(window, 'keydown');
	}

	private onMouseDownHandler() {
		this.onMouseDown = fromEvent(window, 'mousedown');
	}

	private onMouseUpHandler() {
		this.onMouseUp = fromEvent(window, 'mouseup');
	}
}
