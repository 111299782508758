import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CurrencyPipe} from '@angular/common';
import {AdjustWalletRequest} from '../../../models/finance/adjust-wallet-request';
import {AdjustmentOpts} from '../../../enums/adjustment-opts';
import {WalletTransactionTypesLookup} from '../../../interfaces/lookup-interfaces';
import {WalletBalance} from '../../../models/finance/wallet-transaction.model';
import {LookupService} from '../../../services/lookup.service';
import {LABEL_ADJUST_WALLET, RE_COMMENTS} from '../../../constants/constants';
import {AppConfigService, BoErrorHandlerService, ToastDisplayService} from '../../../../helio-core-services';
import {ToastMessageType} from '../../../models';
import {ComponentHelper} from '../../../interfaces/component.helper';
import {ActivatedRoute} from '@angular/router';
import {PlayersService} from '../../../services/players.service';
import {TenantV2} from '../../../models/player/tenant.model';
import {PlayerTransactionService} from '../../../services/player-transaction.service';

@Component({
	selector: 'he-wallet-manual-adjust-dialog',
	templateUrl: './wallet-manual-adjust-dialog.component.html',
	styleUrls: [
		'./wallet-manual-adjust-dialog.component.scss',
		'../common-draw-dialog-style.scss'
	]
})
export class WalletManualAdjustDialogComponent extends ComponentHelper implements OnInit, OnDestroy {
	@Input() visible = false;

	@Output() closeEvent = new EventEmitter<void>();

	form: FormGroup;

	adjustmentOpts = AdjustmentOpts;

	submitLabel = LABEL_ADJUST_WALLET;

	selectedAdjustmentType: AdjustmentOpts;

	uiAmount: number;
	calculatedAmountAfter: number;
	amountBefore: number;

	walletTypeOpt: WalletTransactionTypesLookup[];

	isLoadingLookups = true;
	isLoadingOperator = true;
	loading = false
	hasOneWallet = false;
	cannotManuallyAdjustAny = false;

	currencySymbol: string;
	operator: TenantV2;
	routePlayerID: number;

	private _balance$ = new BehaviorSubject<WalletBalance>(null);

	getWalletTypesSub$: Subscription;
	private adjustBalanceSub$: Subscription;

	constructor(
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private lookupService: LookupService,
		private playerService: PlayersService,
		private walletTransService: PlayerTransactionService,
		protected appConfigService: AppConfigService,
		private toastDisplayService: ToastDisplayService,
		private currencyPipe: CurrencyPipe,
		private boErrorHandlerService: BoErrorHandlerService
	) {
		super();
	}

	get balance() {
		return this._balance$.getValue();
	}

	@Input() set balance(value: WalletBalance) {
		this._balance$.next(value);
	}

	get isFormDisabled(): boolean {
		return !this.form.valid && (this.form.dirty || this.form.touched) || !this.form.touched;
	}

	ngOnInit() {
		this.formInit();
		this.setFormData();

		// The order of this subscribe and that of this._balance$ is important due to call to this.getOperator
		this.activatedRoute.params.subscribe(params => {
			this.routePlayerID = params.id;
		});

		this._balance$.asObservable().subscribe({
			next: balance => {
				if (balance?.walletsEnabled) {

					this.currencySymbol = this.currencyPipe.transform(
						0, balance.currencyCode, 'symbol', '1.0-2', 'en')
						.replace('0', '');

					this.isLoadingLookups = false;
				}

				if (balance) {
					this.walletTypeOpt = undefined;
					this.getOperator(this.routePlayerID);
				}
			}
		});
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getWalletTypesSub$, this.adjustBalanceSub$);
	}

	getOperator(playerID: number): void {
		this.isLoadingOperator = true;
		this.operator = null;

		this.playerService.getOperator(playerID).subscribe({
			next: operator => {
				this.isLoadingOperator = false;
				this.operator = operator;

				this.fetchCriticalLookup();
			},
			error: err => {
				this.isLoadingOperator = false;
				this.boErrorHandlerService.handleError(err);
			}
		});
	}

	private fetchCriticalLookup(): void {
		if (this.walletTypeOpt && (this.walletTypeOpt.length > 0)) {
			this.initWalletTypes();
			return;
		}

		if (this.getWalletTypesSub$ && !this.getWalletTypesSub$.closed) {
			return;
		}

		this.isLoadingLookups = true;
		this.cannotManuallyAdjustAny = false;
		this.walletTypeOpt = null;

		this.getWalletTypesSub$ = this.lookupService.getWalletTypes(this.operator?.tenantID).subscribe({
			next: res => {
				this.walletTypeOpt = res.filter(opt => opt.canManuallyAdjust);

				// "res[0].canManuallyAdjust === false" checks the field is present, so !res[0].canManuallyAdjust cannot be used instead!!!
				if (this.walletTypeOpt && this.walletTypeOpt.length === 0 && res[0].canManuallyAdjust === false) {
					this.cannotManuallyAdjustAny = true;
				}

				this.isLoadingLookups = false;
				this.getWalletTypesSub$.unsubscribe();
				this.initWalletTypes();
			},
			error: error => {
				this.isLoadingLookups = false;
				this.getWalletTypesSub$.unsubscribe();
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onSubmit() {
		let adjustmentType;
		let adjustAmount;

		adjustmentType = this.form.get('adjustmentType').value;

		// TODO -
		//  For now, move this functionality, i.e. generating AdjustWalletRequest to a helper method so that it can be easily
		//   tested. Main test point is to ensure that the original amount derieved from the UI is positive!
		//  It should be recommended to BE that computation of the positive or neg value be done BE-side
		//  Since 1, there is currently more robust testing there
		//        2, Future changes in UI validation is likely to miss this (at the absence of automated UI tests)
		const tempAmount = Number(this.form.get('amount').value);

		if (tempAmount < 0) {
			this.boErrorHandlerService.handleError(new Error('onSubmit: UI validation error. Amount value cannot be < 0.'));
		}

		if (adjustmentType === this.adjustmentOpts.ADD) {
			adjustAmount = tempAmount;
		} else if (adjustmentType === this.adjustmentOpts.REMOVE) {
			adjustAmount = tempAmount * -1;
		} else {
			this.boErrorHandlerService.handleError(new Error('onSubmit: adjustmentType not handled correctly.'));
			return;
		}

		const tenantID = this.operator?.tenantID;

		// This approach allows the absence of tenantID to be shown when user clicks on submit; to avoid a scenario whereby
		// an original toast, were it to be shown after call to getOperator fails, is missed.
		if (!tenantID) {
			this.toastDisplayService.addMessage({
				title: 'Error',
				description: `${this.appConfigService.genericErrorMessage}. Unable to retrieve associated operator.`,
				type: ToastMessageType.error
			});
			return;
		}

		const request: AdjustWalletRequest = {
			balanceType: this.form.get('selectedWallet').value,
			adjustAmount,
			comment: this.form.get('comment').value
		};

		this.onAdjustWallet(request);
	}

	onAdjustWallet(data: AdjustWalletRequest) {
		this.loading = true
		this.releaseSubscriptions(this.adjustBalanceSub$);

		this.adjustBalanceSub$ = this.walletTransService.adjustBalance(this.routePlayerID, data).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `Player ${this.routePlayerID} balance has been adjusted.`
				});

				this.loading = false
				this.onCloseDialog()
			},
			error: err => {
				this.loading = false
				this.boErrorHandlerService.handleError(err)
			}
		});
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	initWalletTypes() {
		if (!this.walletTypeOpt) {
			console.warn('initWalletTypes: walletTypeOpt not yet defined.')
			return;
		}

		// Flag the number of lookup options so that UI reacts accordingly (as per template setup)
		if (this.balance?.walletsEnabled && this.balance.walletsEnabled?.length <= 0) {
			this.toastDisplayService.addMessage({
				title: 'Error',
				description: `${this.appConfigService.genericErrorMessage}. No wallets are associated to account.`,
				type: ToastMessageType.error
			});
		} else {
			this.hasOneWallet = this.balance?.walletsEnabled?.length === 1;
		}

		if (this.form && this.hasOneWallet) {
			// If hasOneWallet, then check that this wallet is in the list of canManuallyAdjust walletOptions
			const hasWallet = this.walletTypeOpt.find(
				((opt) => opt.playerBalanceTypeID === Number(this.balance.walletsEnabled[0])));

			if (!hasWallet) {
				this.toastDisplayService.addMessage({
					title: 'Error',
					description: `${this.appConfigService.genericErrorMessage}.
					 There is a mismatch between available and selectable options.`,
					type: ToastMessageType.error
				});
			}

			this.form.get('selectedWallet').setValue(hasWallet.value);
		}
	}

	private formInit() {
		this.form = this.formBuilder.group({
			selectedWallet: new FormControl('', {
				validators: [Validators.required]
			}),
			adjustmentType: new FormControl('', [Validators.required]),
			amount: new FormControl(undefined, [
				Validators.required,
				Validators.pattern('[0-9]*[.]{0,1}[0-9]{0,2}'),
				Validators.min(0.01)
			]),
			comment: new FormControl('', [
				Validators.required,
				Validators.pattern(RE_COMMENTS),
				Validators.minLength(6),
				Validators.maxLength(2500)
			])
		});

		this.form.controls['selectedWallet'].valueChanges.subscribe({
			next: type => {
				this.determineInitialAmount(type);
				this.calculateAmountAfter(this.uiAmount);
			}
		});

		this.form.controls['adjustmentType'].valueChanges.subscribe({
			next: type => {
				this.selectedAdjustmentType = type;
				this.calculateAmountAfter(this.uiAmount);
			}
		});

		this.form.controls['amount'].valueChanges.subscribe({
			next: value => {
				this.uiAmount = value;
				this.calculateAmountAfter(this.uiAmount);
			}
		});

		this.initWalletTypes();
	}

	private calculateAmountAfter(newAmount: number) {
		const selectWallet = this.form.get('selectedWallet').value;

		// This needs to be called each time since wallet type can differ
		this.determineInitialAmount(selectWallet);

		if (newAmount < 0 || !this.selectedAdjustmentType || !selectWallet) {
			console.log('calculateAmountAfter: condition not yet met.');
			return;
		}

		const amountBefore = this.amountBefore !== undefined && this.amountBefore !== null
			? this.amountBefore : 0

		if (this.selectedAdjustmentType === AdjustmentOpts.ADD) {
			this.calculatedAmountAfter = newAmount ? amountBefore + newAmount : undefined;
		} else if (this.selectedAdjustmentType === AdjustmentOpts.REMOVE) {
			this.calculatedAmountAfter = newAmount ? amountBefore - newAmount : undefined;
		} else {
			this.calculatedAmountAfter = undefined;
		}
	}

	private determineInitialAmount(selectedWallet: number) {
		if (selectedWallet === 1) { // credit
			this.amountBefore = this.balance.credit;
		} else if (selectedWallet === 2) { // winnings
			this.amountBefore = this.balance.winnings;
		} else {
			// for everything else - set undefined for now so it shows as N/A
			this.amountBefore = undefined;
		}
	}

	private setFormData() {
		this.isLoadingLookups = true;

		if (!this.balance) {
			return;

			// TODO: Will need to set a listener to attempt to set formData in future should balance be defined later
		}
	}
}
