import {Injectable, isDevMode, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LogService implements OnInit {
	/**
	 * Log a message to the console.
	 * @param message The message to log.
	 */
	static log(message: string): void {
		console.log(`[LOG]: ${message}`);
	}

	/**
	 * Log a message to the console only in development mode.
	 * @param message The message to log.
	 * @param obj an error or just a POJO
	 */
	static devLog(message: string, obj?: any): void {
		if (LogService.isDevMode()) {
			console.log(`[LOG]: ${message}`, obj ?? '');
		}
	}

	/**
	 * Log a warning message to the console.
	 * @param message The message to warn.
	 */
	static warn(message: string): void {
		console.warn(`[WARN]: ${message}`);
	}

	/**
	 * Log an error message to the console only in development mode.
	 * @param message The error message to log.
	 * @param error The error to log.
	 */
	static devError(message: string, error: any): void {
		if (LogService.isDevMode()) {
			console.error(`[DEV ERROR]: ${message}`, error);
		}
	}

	private static isDevMode() {
		// return isDevMode();
		return environment.env === 'dev-server';
	}

	ngOnInit(): void {
		// console.log(`[isDevMode]: ${isDevMode()}`);
	}
}
