/**
 * @deprecated Use {@link TableDataResponse} instead
 */
export interface DataTableServerResponse {
	offset: number;
	resultSet: any[];
	take: number;
	totalRowCount: number;
}

export interface TableDataResponse<D> {
	offset: number;
	resultSet: D[];
	take: number;
	totalRowCount: number;
}

export class GetQueryData {
	take: number;
	offset: number;
	orderBy: string;
	filter: string;
	format?: string;
}
