import {ToastMessageType} from '../models';

export const REQUEST_SENT_MSG = 'Request is being handled, please wait for confirmation.';
export const PLAYER_DETAILS_UPDATE_MSG = 'Player details are updated.';


export function createRetailAgentSuccessToastObj() {
	return {
		type: ToastMessageType.success,
		title: 'Success',
		description: 'Retail Agent created successfully.'
	};
}

export function updateRetailAgentSuccessToastObj(retailAgentID: number) {
	return {
		type: ToastMessageType.success,
		title: 'Success',
		description: `Retail Agent ${retailAgentID} updated successfully.`
	};
}
