<he-base-dialog header="{{'Publish Draw Results'}}" [visible]="visible" [isLoadingLookups]="loading"
				[style]="{'max-height': '80%', height: '900px', width: '100%'}" (closeEvent)="onCloseDialog()"
				rejectBtnOnly="true" rejectLabel="Close" class="basic-dialog-table" id="mainDialog">

	<!--Setting *ngIf="mainDrawData" is a easy fix to ensure the data is available before element init-->
	<he-draw-results-table *ngIf="!loading; else mainDrawLoadingUI" [loading]="loading" [drawResult]="mainDrawData"
						   (publishResult)="onPublishClicked($event)"></he-draw-results-table>

	<ng-template #mainDrawLoadingUI>
		<p-card>
			<div class="skeleton-overhead-wrapper"
				 style="display: grid; grid-template-columns: 1fr max-content; align-items: end">
				<div class="skeleton-info-container" style="display: flex; align-items: start">
					<p-skeleton class="skeleton-img" width="80px" height="80px"
								[ngStyle]="{margin: '16px'}"></p-skeleton>

					<div class="skeleton-info-texts" style="margin-top: 24px; display: grid; row-gap: 8px;">
						<p-skeleton width="150px"></p-skeleton>
						<p-skeleton width="330px"></p-skeleton>
						<p-skeleton width="180px"></p-skeleton>
					</div>
				</div>

				<p-skeleton width="150px" height="32px" [ngStyle]="{'margin-bottom': '16px', 'margin-right': '16px'}"></p-skeleton>
			</div>

			<he-skeleton-table [skeletonOverheadOptRight]="false"
							   [skeletonOverheadOptLeft]="false"
							   [skeletonOverheadOptCount]="1"
							   [skeletonHasHeader]="false" [skeletonHasFooter]="false"
							   [isTabbedTable]="false"></he-skeleton-table>

			<div class="skeleton-total-exposure" style="display: flex; justify-content: right">
				<p-skeleton width="250px" height="28px" [ngStyle]="{'margin': '12px 8px'}"></p-skeleton>
			</div>
		</p-card>
	</ng-template>

	<ng-container *ngFor="let drawResult of subDrawsData;">
		<he-draw-results-table [loading]="loading" [drawResult]="drawResult"
							   (publishResult)="onPublishClicked($event)"></he-draw-results-table>
	</ng-container>

	<div *ngIf="subDrawsData" class="publish-all">
		<p>Publishing draw results will credit each player's wallet with the appropriate monetary amount.</p>

		<button pButton type="button" class="helio-orange-btn" style="min-width: 120px;"
				[class.he-disabled]="!canPublishAll"
				(click)="onPublishAllResults()" [label]="'Publish All'" [title]="'Publish All'"
				icon="pi pi-clone" [disabled]="loading"></button>
	</div>
</he-base-dialog>

<!--[header]="entryType === 'insertion' ? 'Manually Insert Result' : 'Approve Result'"-->
<he-base-dialog *ngIf="showConfirmationDialog" id="confirmationDialog" [useConfirmDialog]="false"
				[header]="'Confirmation'" [description]="labelMsg"
				[visible]="showConfirmationDialog" [disableSubmit]="!canEnableSubmit"
				[style]="{'max-width': '550px'}" [submitStyle]="{'min-width': '160px', 'text-align': 'left'}"
				[rejectLabel]="'Close'" [submitLabel]="submitLabel" [title]="submitLabel"
				(closeEvent)="onConfirmationClosed()"
				(submitEvent)="onConfirmationSubmit()" ></he-base-dialog>
