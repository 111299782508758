export enum DrawState {
	Initialized = 0,
	Active = 1,
	Closed = 2,
	ResultsOut = 3,
	Suspended = 4,
	Cancelled = 5,
	Voided = 6
}

/**
 * NB: In BE, only these there draw statues ID exist, those from {@link DrawStatus} are string values
 * generated from the BE to give a more nuanced view to the UI.
 */
export enum GameFilter {
	ALL = 0, // These values must be associated as is, since these are mapped to BE values
	AWAITING_RESULTS = 1,
	AWAITING_APPROVAL = 2,
}

export enum DrawStatus {
	OPEN = 'Open',
	CURRENT = 'Current',
	AWAITING_RESULTS = 'Awaiting Results',
	AWAITING_APPROVAL = 'Awaiting Approval',
	PROCESSING_RESULTS = 'Processing Results',
	AWAITING_PUBLISH = 'Awaiting Publish'
}

/**
 * Mapped to BE values
 */
export enum DrawStatusID {
	AWAITING_PUBLISH = 7 // 'AwaitingPublish'
}

/**
 * Mapped to BE values
 */
export enum DrawExpandedStatusID	{
	OPEN = 1, // 'Open'
	CURRENT = 2, //  'Current'
	AWAITING_RESULTS = 4, // 'Awaiting Results'
	AWAITING_APPROVAL = 5, // 'Awaiting Approval'
	PROCESSING_RESULTS = 6, // 'Processing Results'
	AWAITING_PUBLISH = 11 // 'Awaiting Publish'
}
