/**
 * @description {@link field} and {@link header} map to the columns naming convention in {@link DataTableV3Component},
 * hence the column object can be passed for type {@link FormatParams}.
 * @summary {@link field} represents the DB naming convention; {@link header} represents the naming convention to use for UI;
 */
export interface FormatParams {
	field: string,
	header: string
}

/**
 * Class used to set the request headers of an HTTP request
 */
export class ServiceHeadersConfig {
	xFormatParams: string;

	/**
	 * @param isAnonymous boolean signifying whether the request requires authorization to access a particular service call
	 *
	 * When true Authorization header is set
	 * @param contentType string to set the Content-Type of the request to be made
	 * @param accept indicates in the request the accept type to prioritise
	 */
	constructor(
		public isAnonymous: boolean = false,
		public contentType: HeaderContentType = HeaderContentType.JSON,
		public accept: HeaderAcceptType = HeaderAcceptType.DEFAULT) {
	}

	/**
	 * Returns a **new** {@link ServiceHeadersConfig} with a string parsed from the {@link FormatParams[]}
	 * arg as xFormatParams; This enables customisation of format=csv, in terms of returned column
	 * names and the included columns.
	 */
	attachXFormatParams(customParams: FormatParams[]): ServiceHeadersConfig {
		let formatParams = '';

		for (const param of customParams) {
			formatParams += `${param.field}=${param.header}|`
		}

		const config = new ServiceHeadersConfig();
		config.xFormatParams = formatParams.endsWith('|') ? formatParams.substring(0, formatParams.length - 1) : formatParams;

		return config;
	}
}

export enum HeaderContentType {
	JSON = 'application/json',
	URL_ENCODED = 'application/x-www-form-urlencoded',
	FORM_DATA = 'multipart/form-data',
	TEXT_PLAIN = 'text/plain',
}

export enum HeaderAcceptType {
	DEFAULT = 'application/json, text/plain, */*',
	TEXT = 'text/plain, text/csv',
	JSON = HeaderContentType.JSON
}
