import { Component, OnInit } from '@angular/core';
import { DrawType } from '../shared';
import { BreadcrumbService } from '../../helio-core-services';
import {ACTIVE_DRAW, PREVIOUS_DRAW} from '../../shared/constants/navigation-titles';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'he-draws-catalogue',
	styleUrls: ['./draws-catalogue.component.scss'],
	templateUrl: './draws-catalogue.component.html'
})

export class DrawsCatalogueComponent implements OnInit {
	drawType: DrawType = DrawType.ACTIVE;
	title: string;

	constructor(
		private route: ActivatedRoute,
		private breadcrumbService: BreadcrumbService) { }

	ngOnInit() {
		this.drawType  = this.route.snapshot.data.drawType;
		this.title = (this.drawType === DrawType.ACTIVE) ? ACTIVE_DRAW : PREVIOUS_DRAW;

		this.breadcrumbService.setItems([
			{ label: 'Draws' },
			{ label: this.title }
		]);
	}
}
