import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../../helio-core-services';
import {FormatParams, ServiceAction, ServiceController} from '../utilities';
import {TableDataResponse} from '../components';
import {FallBackRequest, PaymentProvidersFallbackOrder} from '../models/finance/payment-providers-fallback.model';
import {UpdateResponse} from '../models/general/update-response';
import {DistributionRequest, PaymentProvidersDistribution, PspStateRequest} from '../models/finance/payment-providers-dist.model';
import {AssignPspRequest, PaymentRoute} from '../models/finance/psps.model';
import {BaseLookup, WithdrawalMethodsLookup} from '../interfaces/lookup-interfaces';
import {CSV_FORMAT, FORMAT_URL_PARAM, OFFSET_URL_PARAM, TAKE_URL_PARAM} from '../constants';
import {FALLBACK_ID, FALLBACK_NAME} from '../constants/ui-db-name-mappings';
import {ResponseBodyType} from '../enums';
import {BaseServiceImpl} from './base-service-impl';


@Injectable({
	providedIn: 'root'
})
export class PaymentProviderService extends BaseServiceImpl {

	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.FINANCIAL, appConfigService.serviceBaseURL);
	}

	//region Fallbacks

	getFallbacks(request: PaymentProviderRequest, searchParams?: HttpParams): Observable<TableDataResponse<PaymentProvidersFallbackOrder>> {
		return this.getPaymentProvidersHelper(request, searchParams);
		// return of(dummyFallbacksData).pipe(delay(200));
	}

	getFallbacksCsv(
		request: PaymentProviderRequest,
		searchParams?: HttpParams,
		xFormatParams?: FormatParams[]
	): Observable<string> {
		return this.getPaymentProvidersCsvHelper(request, searchParams, xFormatParams);
	}

	updateFallbackOrder(operatorID: number, requestData: FallBackRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER, ServiceAction.PAYMENT_PROVIDERS_CHANGE_ORDER]
		);

		// return of({success: true}).pipe(delay(200));
	}

	//endregion

	//region Distributions
	getPercentDistributions(request: PaymentProviderRequest, searchParams?: HttpParams
	): Observable<TableDataResponse<PaymentProvidersDistribution>> {
		return this.getPaymentProvidersHelper(
			{operatorID: request.operatorID, paymentMethodID: request.paymentMethodID}, searchParams
		);

		// return of(JSON.parse(JSON.stringify(dummyDistributionsData))).pipe(delay(200));
	}

	getPercentDistributionsCsv(
		request: PaymentProviderRequest,
		searchParams?: HttpParams,
		xFormatParams?: FormatParams[]
	): Observable<string> {
		return this.getPaymentProvidersCsvHelper(request, searchParams, xFormatParams);
	}

	updatePercentDistribution(operatorID: number, requestData: DistributionRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER, ServiceAction.PAYMENT_PROVIDERS_DISTRIBUTE]
		);
	}

	updateActivationState(operatorID: number, requestData: PspStateRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER, ServiceAction.PAYMENT_PROVIDERS_ACTIVATION]
		);
	}

	//endregion

	getPaymentMethods(tenantID: number): Observable<WithdrawalMethodsLookup[]> {
		/*return of([
			{label: 'Dummy - Bank Transfer', value: 1},
			{label: 'Dummy - Exchange House', value: 2}
		]).pipe(delay(1000));*/

		const paymentMethods = []

		return this.validateAndParseLookupResSTD<WithdrawalMethodsLookup>(
			paymentMethods, {label: 'displayName', value: 'paymentMethodID'}, undefined,
			[tenantID, ServiceAction.PAYMENT_METHODS], undefined,
			undefined, ServiceController.PAYMENTS
		);
	}

	getPaymentRoute(request: { playerID: number, paymentMethodID: number }): Observable<PaymentRoute> {
		return this.get(
			undefined, [request.playerID, ServiceAction.PAYMENT_ROUTES, request.paymentMethodID],
			undefined, undefined, ServiceController.PAYMENTS
		);
	}

	updatePlayerPaymentRoutes(playerID: number, requestData: AssignPspRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[playerID, ServiceAction.PAYMENT_ROUTES], ServiceController.PAYMENTS
		);
	}

	getPaymentProvidersLookup(operatorID: number): Observable<BaseLookup[]> {
		const searchParams: HttpParams = new HttpParams()
			.set(TAKE_URL_PARAM, 250)
			.set(OFFSET_URL_PARAM, 0);

		return this.getPaymentProvidersHelper({operatorID: operatorID, paymentMethodID: undefined}, searchParams).pipe(
			map((res: TableDataResponse<any>) => {
				return res.resultSet.map(entry => {
					const temp: BaseLookup = {label: entry.name, value: entry.paymentServiceProviderID};
					return temp;
				})
			})
		);

		// return of(JSON.parse(JSON.stringify(dummyDistributionsData))).pipe(delay(200));
	}

	//region Helper methods
	getPaymentProvidersHelper(request: PaymentProviderRequest, searchParams?: HttpParams): Observable<TableDataResponse<any>> {
		let params = searchParams ? searchParams : new HttpParams();
		params = params.set('paymentMethodID', request.paymentMethodID)

		return this.validateDataTableRes(
			[FALLBACK_ID.DB, FALLBACK_NAME.DB], undefined,
			[request.operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER], params
		);
	}

	private getPaymentProvidersCsvHelper(
		request: PaymentProviderRequest,
		searchParams?: HttpParams,
		xFormatParams?: FormatParams[]
	): Observable<string> {
		let params = searchParams ?
			searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT) : searchParams;

		params = params.set('paymentMethodID', request.paymentMethodID)

		return this.getCsv(undefined, [request.operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER], params,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	//endregion
}

export interface PaymentProviderRequest {
	operatorID: number;
	paymentMethodID: number;
}
