import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReportFilterField, SearchType} from '../advanced-search';
import {FilterSubmitEvent} from '../../../models/search/FilterSubmitEvent';
import {ToastMessageType} from '../../../models';
import {ToastDisplayService} from '../../../../helio-core-services';
import {FilterLazyLoadEvent, FilterListSearchEvent, FilterValueChangeEvent} from '../filter-component/filter-component.component';

@Component({
	selector: 'he-filter-search-v2',
	templateUrl: './filter-search-v2.component.html',
	styleUrls: ['./filter-search-v2.component.scss']
})
export class FilterSearchV2Component {

	@Input() filterFields: ReportFilterField[];
	@Input() loading = false;
	@Output() filterPaneSubmitEvent: EventEmitter<FilterSubmitEvent> = new EventEmitter<FilterSubmitEvent>();

	@Output() filterValueChange: EventEmitter<FilterValueChangeEvent> = new EventEmitter<FilterValueChangeEvent>();
	@Output() searchEvent: EventEmitter<FilterListSearchEvent> = new EventEmitter<FilterListSearchEvent>();
	@Output() lazyLoadEvent: EventEmitter<FilterLazyLoadEvent> = new EventEmitter<FilterLazyLoadEvent>();

	expandFilterPane = true;
	protected readonly SearchType = SearchType;

	hasEmptyRequiredFilters = false;

	constructor(private toastDisplayService: ToastDisplayService) {
	}

	private getFilterVariables(filterFields: ReportFilterField[]): object {
		this.hasEmptyRequiredFilters = false;
		const filterVariablesTally: any = {};

		filterFields.forEach((entry) => {
			const filterVariables = entry.filterVariables;

			// TODO - Refactor when BE impl is finalised such that #hasEmptyRequiredFilters
			//  flags the presence of 1 or more empty required filter
			if (filterVariables !== undefined) {
				Object.assign(filterVariablesTally, filterVariables);
			} else {
				this.hasEmptyRequiredFilters = true;
			}
		});

		return filterVariablesTally;
	}

	submitSearchClick() {
		const tempFilterVars = this.getFilterVariables(this.filterFields);

		// console.log('tempFilterVars: ', tempFilterVars)

		if (this.hasEmptyRequiredFilters) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: 'Error Message',
				description: 'Filter query contains empty fields, please make sure all fields are filled.'
			});
		} else if (tempFilterVars === undefined) {
			this.filterPaneSubmitEvent.emit();
		} else {
			this.filterPaneSubmitEvent.emit({
				variables: tempFilterVars
			});
		}
	}

	/**
	 * Callback after an individual filter component value has changed on the filter pane
	 */
	onFilterValueChange(event: FilterValueChangeEvent) {
		// console.log('FilterComp - onFilterValueChange: value =', event);
		this.filterValueChange.emit(event)
	}

	onLazyLoadEvent(event: FilterLazyLoadEvent) {
		this.lazyLoadEvent.emit(event);
	}

	onFilterListSearchEvent(event: FilterListSearchEvent) {
		// console.log('FilterComp - onFilterListSearchEvent: value =', event);
		this.searchEvent.emit(event);
	}
}
