import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DrawType, DrawProcessingService, GameFilter} from '../shared';
import {AppConfigService} from '../../helio-core-services/services/app-config.service';
import {BoErrorHandlerService} from '../../helio-core-services/services/bo-error-handler.service';
import {Draw} from '../../shared/models/draws/draw.model';
import {CommonTable} from '../../shared/interfaces/common-table';
import {Subscription} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {DataViewLayout} from '../../skeletons/base-skeleton';
import {HttpParams} from '@angular/common/http';

@Component({
	selector: 'he-games-draws-overview',
	styleUrls: ['./games-draws-overview.component.scss'],
	templateUrl: './games-draws-overview.component.html'
})
export class GamesDrawsOverviewComponent extends CommonTable<Draw> implements OnInit, OnDestroy {
	@Input() drawType: DrawType = DrawType.ACTIVE;

	drawTypeEnum = DrawType;

	getDrawsSub$: Subscription;

	tableFilterOpts: SelectItem[] = [];
	selectedPageFilter: GameFilter;

	viewLayout: DataViewLayout = 'grid';

	constructor(
		protected drawService: DrawProcessingService,
		protected appConfigService: AppConfigService,
		protected boErrorHandlerService: BoErrorHandlerService
	) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.tableFilterOpts = [
			{label: 'All', value: GameFilter.ALL},
			{label: 'Awaiting Approval', value: GameFilter.AWAITING_APPROVAL},
			{label: 'Awaiting Results', value: GameFilter.AWAITING_RESULTS},
		];
		this.selectedPageFilter = GameFilter.ALL;

		// this.getTableData(); // No required as lazy=true in template for p-dataView
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getDrawsSub$);
	}

	getTableData(filterData?: any, forceSearchParam?: HttpParams) {
		this.beforeTableDataServiceCall(this.getDrawsSub$, false);

		this.loading = true;
		this.params = this.computeDataViewHttpParams(filterData);

		let observable;

		if (this.drawType === DrawType.ACTIVE) {
			observable = this.drawService.getAllUpcomingDraws(this.params, this.selectedPageFilter);
		} else {
			observable = this.drawService.getAllLatestPreviousDraws(this.params);
		}

		this.getDrawsSub$ = observable.subscribe({
			next: res => {
				if (res.resultSet) {
					this.data = res.resultSet as Draw[];
					this.totalRecords = res.totalRowCount;

					if (res.resultSet.length === 0) {
						this.tableMessage = this.appConfigService.tableMissingDataMessage;
					}
				}
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error, undefined, 'Getting Draws for all Games');
			}
		});
	}

	onAnyDrawCancel(cancelPerformed: boolean) {
		if (cancelPerformed) {
			this.getTableData(undefined);
		}
	}

	onGameFilterChange(event: any) {
		this.selectedPageFilter = event;
		this.getTableData(undefined);
	}

	onRefreshCatalogue() {
		this.getTableData(undefined);
	}

	onChangeLayout(viewLayout: { layout: DataViewLayout }) {
		// Update for skeleton
		this.viewLayout = viewLayout.layout;
	}
}
