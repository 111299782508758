import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BankAccount, BankAccountRequest} from '../../../models/finance/transfer-information.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryGroupLookup} from '../../../interfaces/lookup-interfaces';
import {AppGuard} from '../../../guards';
import {LookupService} from '../../../services/lookup.service';
import {BoErrorHandlerService} from '../../../../helio-core-services';
import {RE_ALPHABETIC_WS, RE_ALPHANUMERIC, RE_ALPHANUMERIC_WS, RE_NUMERIC} from '../../../constants/constants';
import {forkJoin} from 'rxjs';
import {Player} from '../../../models/player/players.model';

@Component({
	selector: 'he-bank-details-editor-dialog',
	templateUrl: './bank-details-editor-dialog.component.html',
	styleUrls: ['./bank-details-editor-dialog.component.scss']
})
export class BankDetailsEditorDialogComponent implements OnInit {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() forCRUD = false;

	@Input() playerPartialData: Player;

	@Input() dialogTitle: 'Bank Details' | 'Add Bank Details' | 'Edit Bank Details' = 'Bank Details';
	@Input() submitLabel = 'Submit';

	@Input() editable = true;

	@Input() bankDetails: BankAccount;
	@Input() tenantID?: number;

	@Output() closeEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<BankAccountRequest>();

	form: FormGroup;

	allCountries: CountryGroupLookup[] = [];
	unsanctionedCountries: CountryGroupLookup[] = [];

	isLoadingLookups = false;

	isViewMode = false;


	// canUpdateBankDetails = false;

	constructor(
		private appGuard: AppGuard,
		private formBuilder: FormBuilder,
		private lookupService: LookupService,
		private boErrorHandlerService: BoErrorHandlerService) {
	}

	ngOnInit() {
		// this.canUpdateBankDetails = this.appGuard.hasActionPermission(ServiceController.WITHDRAWALS, ServiceAction.UPDATE_BANK_DETAILS);

		this.formInit();
		this.setFormData();

		if (this.forCRUD) {
			this.setState(true); // so that all editable inputs can take user input
		}
	}

	onSubmit() {
		const rawValue = this.form.getRawValue();

		const request: BankAccountRequest = {

			beneficiaryName: rawValue['accHolderName'],
			nationalIDNumber: rawValue['nationalIdNum'],

			bankName: rawValue['bankName'],
			bankBranch: rawValue['bankBranch'],
			bankCity: rawValue['bankCity'],
			bankState: rawValue['bankState'],
			bankBranchCountryID: rawValue['bankBranchCountry'],

			bankAccountNumber: rawValue['bankAccNumber'],
			sortCode: rawValue['sortCode'],
			iban: rawValue['iban'],
			swiftCode: rawValue['swiftCode'],
			ifsc: rawValue['ifsc'],
			routingNumber: rawValue['routingNum']
		}

		this.submitEvent.emit(request);
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	private formInit() {
		let accHolderName = '';

		// Populate fullName for accHolderName input with for create new; use the current set value when editing;
		if (this.playerPartialData && !this.bankDetails) { // This is a create-new ExchangeHouse scenario and not edit or display data
			accHolderName = this.playerPartialData.fullName;
		} else if (this.bankDetails) { // this represents an edit state
			accHolderName = this.bankDetails.beneficiaryName;
		}

		this.form = this.formBuilder.group({
			accHolderName: new FormControl(accHolderName, {
				validators: [Validators.required, Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			nationalIdNum: new FormControl(this.bankDetails ? this.bankDetails.nationalIDNumber : '', {
				validators: [Validators.required, Validators.pattern(RE_ALPHANUMERIC)]
			}),
			bankName: new FormControl(this.bankDetails ? this.bankDetails.bankName : '', {
				validators: [Validators.required, Validators.pattern(RE_ALPHANUMERIC_WS)]
			}),
			bankBranch: new FormControl(this.bankDetails ? this.bankDetails.bankBranch : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC_WS)]
			}),
			bankCity: new FormControl(this.bankDetails ? this.bankDetails.bankCity : '', {
				validators: [Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			bankState: new FormControl(this.bankDetails ? this.bankDetails.bankState : '', {
				validators: [Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			bankBranchCountry: new FormControl(undefined),
			bankAccNumber: new FormControl(this.bankDetails ? this.bankDetails.bankAccountNumber : undefined, [
				Validators.required, Validators.min(1)
			]),
			sortCode: new FormControl(this.bankDetails ? this.bankDetails.sortCode : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			iban: new FormControl(this.bankDetails ? this.bankDetails.iban : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			swiftCode: new FormControl(this.bankDetails ? this.bankDetails.swiftCode : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			ifsc: new FormControl(this.bankDetails ? this.bankDetails.ifsc : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			routingNum: new FormControl(this.bankDetails ? this.bankDetails.routingNumber : undefined, [
				Validators.min(1)
			]),
		});

		this.setState(false);
	}

	setState(enabled: boolean) {
		for (const entry in this.form.controls) {
			if (entry && enabled) {
				this.form.controls[entry].enable();
			} else if (entry && !enabled) {
				this.form.controls[entry].disable();
			}
		}

		this.form.updateValueAndValidity();
	}

	private setFormData() {
		this.isLoadingLookups = true;

		const observables: any = {
			getPlayerNomenclature: this.lookupService.getPlayerNomenclature()
		};

		forkJoin(observables).subscribe({
			next: (res: any) => {
				this.allCountries = res.getPlayerNomenclature.result.countries;
				this.unsanctionedCountries = res.getPlayerNomenclature.result.unblockedCountries;

				if (this.bankDetails) {
					// this.form.get('countryOfBirth').setValue(this.bankDetails.countryOfBirthId);
					this.form.get('bankBranchCountry').setValue(this.bankDetails.bankBranchCountryID);
				}

				this.isLoadingLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}
}
