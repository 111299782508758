import {Directive} from '@angular/core';
import {forkJoin, Subscription} from 'rxjs';
import {
	ActiveDraw,
	AppGuard,
	DataTableAction,
	DataTableActionCallback,
	Draw,
	ServiceAction,
	ServiceController,
	ToastMessageType
} from '../../../shared';
import {ComponentHelper} from '../../../shared/interfaces/component.helper';
import {AppConfigService, BoErrorHandlerService, ToastDisplayService} from '../../../helio-core-services';
import {DrawProcessingService} from '../services';
import {ApproveManualResultsData, ChangeDrawDateData, DropDrawData, ManualInsertResultsData} from '../models';
import {DrawExpandedStatusID, DrawStatusID, DrawType} from '../enums';
import {Router} from '@angular/router';
import {HeMenuItem} from '../../../shared/models/primeng-overrides/he-menu-item.interface';
import {GameFormat, LotteryResult} from '../../manual-draw-entry-dialog';

/**
 * Contains abstracted service helpers for use by UpComingDraws, PreviousDraws, IndividualUpComingDraws
 * and IndividualPreviousDraws.
 */
@Directive()
export abstract class CommonDrawOperations extends ComponentHelper {
	loading = false;
	selectedCogDraw: Draw;

	displayCancelDrawDialog = false;
	displayChangeDrawDateDialog = false;
	displayInsertResultDialog = false;
	displayVoidDrawDialog = false;
	displayApproveResultDialog = false;
	displayPublishDrawResultDialog = false;

	lotteryResults: LotteryResult[];
	gameFormat: GameFormat;

	private cancelDrawSub$: Subscription;
	private voidDrawSub$: Subscription;
	private changeDrawDateSub$: Subscription;
	private insertManualResultsForDrawSub$: Subscription;
	private approveManualResultsForDrawSub$: Subscription;

	constructor(
		protected appConfigService: AppConfigService,
		protected appGuard: AppGuard,
		protected drawService: DrawProcessingService,
		protected toastService: ToastDisplayService,
		protected errorService: BoErrorHandlerService,
	) {
		super();
	}

	beforeRequestServiceCall(sub$: Subscription) {
		this.loading = true;
		// this.selectedCogDraw = undefined;

		this.releaseSubscriptions(sub$);
	}

	handleDataRequestSuccess() {
		this.loading = false;
		this.selectedCogDraw = undefined;
	}

	handleDataRequestError() {
		this.loading = false;
		// this.selectedCogDraw = undefined;
	}

	cancelDraw(data: DropDrawData): Promise<void> {
		this.beforeRequestServiceCall(this.cancelDrawSub$);

		return new Promise<void>((resolve, reject) => {
			this.cancelDrawSub$ = this.drawService.cancelDraw(data).subscribe({
				next: () => {
					this.toastService.addMessage({
						type: ToastMessageType.success,
						title: 'Success',
						description: `Draw ${data.drawID} has been cancelled`
					});

					this.handleDataRequestSuccess();
					resolve();
				},
				error: err => {
					this.handleDataRequestError();
					reject(err?.error);
				}
			});
		});
	}

	voidDraw(data: DropDrawData): Promise<void> {
		this.beforeRequestServiceCall(this.voidDrawSub$);

		return new Promise<void>((resolve, reject) => {
			this.voidDrawSub$ = this.drawService.voidDraw(data).subscribe({
				next: () => {
					this.toastService.addMessage({
						type: ToastMessageType.success,
						title: 'Success',
						description: `Draw ${data.drawID} has been voided.`
					});

					this.handleDataRequestSuccess();
					resolve();
				},
				error: err => {
					this.handleDataRequestError();
					reject(err?.error);
				}
			});
		});
	}

	changeDrawDate(data: ChangeDrawDateData): Promise<void> {
		this.beforeRequestServiceCall(this.changeDrawDateSub$);

		return new Promise<void>((resolve, reject) => {
			this.changeDrawDateSub$ = this.drawService.changeDrawDate(data).subscribe({
				next: res => {
					this.toastService.addMessage({
						type: ToastMessageType.success,
						title: 'Success',
						description: `Date has been changed for Draw`
					});

					this.handleDataRequestSuccess();
					resolve();
				},
				error: err => {
					this.handleDataRequestError();
					reject(err?.error);
				}
			});
		});
	}

	insertManualResults(data: ManualInsertResultsData): Promise<void> {
		this.beforeRequestServiceCall(this.insertManualResultsForDrawSub$);

		// Returning Promise is necessary to cue classes implementing #this as composition to refresh their data table
		return new Promise<void>((resolve, reject) => {
			this.insertManualResultsForDrawSub$ = this.drawService.insertManualResultsForDraw(data.drawID, data).subscribe({
				next: res => {
					this.toastService.addMessage({
						type: ToastMessageType.success,
						title: 'Success',
						description: `Results inserted for Draw ${data.drawID}`
					});

					this.handleDataRequestSuccess();
					resolve();
				},
				error: err => {
					this.handleDataRequestError();
					reject(err?.error);
				}
			});
		});
	}

	approveManualResults(data: ApproveManualResultsData): Promise<void> {
		this.beforeRequestServiceCall(this.approveManualResultsForDrawSub$);

		return new Promise<void>((resolve, reject) => {
			this.approveManualResultsForDrawSub$ = this.drawService.approveManualResultsForDraw(data).subscribe({
				next: res => {
					this.toastService.addMessage({
						type: ToastMessageType.success,
						title: 'Success',
						description: `Results ${data.isRejected ? 'rejected' : 'approved'} for Draw ${data.drawID}`
					});

					this.handleDataRequestSuccess();
					resolve();
				},
				error: err => {
					this.handleDataRequestError();
					reject(err?.error);
				}
			});
		});
	}

	onDestroy() {
		this.releaseSubscriptions(
			this.cancelDrawSub$,
			this.voidDrawSub$,
			this.changeDrawDateSub$,
			this.insertManualResultsForDrawSub$,
			this.approveManualResultsForDrawSub$
		);
	}

	/**
	 * @param drawType The type of draw
	 * @param forTable when boolean is set to true, denotes that an array {@link DataTableAction[]} is returned, else {@link MenuItem[]}
	 *                 is returned. This is because {@link DataTableAction} callback is needed in tables for establishing the
	 *                 selected row. In contrast, when opt selection pertains to cards, selectedDrawForCog is passed via the card
	 *                 as this data was used to populate the card.
	 * @param viewMoreOpts When provided, this signifies the presences of "View more".
	 * 		               viewMoreOpts#gameDraw is also optional, if provided it is used as a fallback if the initialting callback fails
	 * 		               to provide a valid object.
	 */
	getRowMenuItemTableActions(
		drawType: DrawType, forTable: boolean = false,
		viewMoreOpts?: { router: Router, gameDraw?: Draw }): DataTableAction[] | HeMenuItem[] {
		// : DataTableAction[]
		const menuItems = [];

		const viewMoreMICallback = (callbackObj) => {
			let draw = callbackObj?.data;
			draw = draw ? draw[0] as Draw : undefined;
			draw = draw ? draw : viewMoreOpts?.gameDraw;

			if (!draw) {
				console.warn('An invalid row object was provided to \'View More\'.')
			}

			// -1 is used if draw.gameGroupID == undefined, to prevent error being thrown on click and making the UI appear frozen
			if (drawType === DrawType.PREVIOUS) {
				viewMoreOpts.router.navigate(
					['/draw-processing/previous-draws-for-game', draw.gameGroupID ?? -1]);
			} else if (drawType === DrawType.ACTIVE) {
				viewMoreOpts.router.navigate(
					['/draw-processing/upcoming-draws-for-game', draw.gameGroupID ?? -1]);
			}
		}

		const viewMoreMI: HeMenuItem = {
			label: 'View More',
			icon: 'ui-icon-launch'
		}

		//region Dynamically set disabled menuItems

		// It is important that the below HeMenuItem are not made constants, so that their disabled field can
		// be computed in realtime when this method is called.
		const voidMI: HeMenuItem = {
			label: 'Void Draw',
			icon: 'ui-icon-block',
			command: (event) => {
				this.displayVoidDrawDialog = true;
			},
			disabled: !this.appGuard.hasActionPermission(ServiceController.DRAW, ServiceAction.DRAW_VOID),
		};

		const cancelMI: HeMenuItem = {
			label: 'Cancel Draw',
			icon: 'ui-icon-cancel',
			command: (event) => {
				this.displayCancelDrawDialog = true;
			},
			disabled: !this.appGuard.hasActionPermission(ServiceController.DRAW, ServiceAction.DRAW_CANCEL)
		};

		const changeMI: HeMenuItem = {
			label: 'Change Draw Date',
			icon: 'ui-icon-insert-invitation',
			command: (event) => {
				this.displayChangeDrawDateDialog = true;
			},
			disabled: !this.appGuard.hasActionPermission(ServiceController.DRAW, ServiceAction.DRAW_CHANGE_DATE)
		};

		const manualInsertMI: HeMenuItem = {
			label: 'Manually Insert Result',
			icon: 'ui-icon-looks-one',
			command: (event) => {
				this.displayInsertResultDialog = true; // TODO: Audit what command is for!
			},
			enablePredicate: (obj: ActiveDraw) => {
				return this.appGuard.hasActionPermission(ServiceController.DRAW, ServiceAction.DRAW_INSERT_MANUAL_RES) &&
					obj?.drawExpandedStatusID === DrawExpandedStatusID.AWAITING_RESULTS &&
					obj.canInputResults;
			}
		};

		const approveResultMI: HeMenuItem = {
			label: 'Approve Result',
			icon: 'ui-icon-check-circle', // verified
			command: (event) => {
				this.displayApproveResultDialog = true;
			},
			enablePredicate: (obj: ActiveDraw) => {
				return this.appGuard.hasActionPermission(ServiceController.DRAW, ServiceAction.DRAW_APPROVE_MANUAL_RES) &&
					obj?.drawExpandedStatusID === DrawExpandedStatusID.AWAITING_APPROVAL;
			}
		};

		const publishResultMI: HeMenuItem = {
			label: 'Publish Result',
			icon: 'pi pi-clone',
			command: (event) => {
				this.displayPublishDrawResultDialog = true;
			},
			enablePredicate: (obj: any) => {
				// Because the fields aren't the same for the related endpoints.
				let hasStatus;
				if (obj?.drawExpandedStatusID) {
					hasStatus = obj?.drawExpandedStatusID === DrawExpandedStatusID.AWAITING_PUBLISH
				} else if (obj?.drawStatusID) {
					hasStatus = obj?.drawStatusID === DrawStatusID.AWAITING_PUBLISH
				}

				return this.appGuard.hasActionPermission(ServiceController.DRAW, 'PublishResults') && hasStatus
			}
		};

		//endregion

		function createDataTableAction(menuItem: HeMenuItem, callback: (event: DataTableActionCallback) => void) {
			return {menuItem, callback} as DataTableAction
		}

		// NB: When options use pertains to cards, then selectedDrawForCog is handled within the card as this data was
		// used to populate the card
		if (forTable) {
			const voidTableOpt = createDataTableAction(voidMI, (callbackObj) => {
				this.selectedCogDraw = callbackObj.data[0] as Draw;
				this.displayVoidDrawDialog = true;
			});

			if (viewMoreOpts) {
				menuItems.push(createDataTableAction(viewMoreMI, viewMoreMICallback));
			}

			if (drawType === DrawType.ACTIVE) {
				menuItems.push(
					createDataTableAction(cancelMI, (callbackObj) => {
						this.selectedCogDraw = callbackObj.data[0] as Draw;
						this.displayCancelDrawDialog = true;
					}),
					voidTableOpt,
					createDataTableAction(changeMI, (callbackObj) => {
						this.selectedCogDraw = callbackObj.data[0] as Draw;
						this.displayChangeDrawDateDialog = true;
					}),
					createDataTableAction(manualInsertMI, (callbackObj) => {
						this.selectedCogDraw = callbackObj.data[0] as Draw;
						this.displayInsertResultDialog = true;
						this.getLotteryResultsAndBoardInfo(true);
					}),
					createDataTableAction(approveResultMI, (callbackObj) => {
						this.selectedCogDraw = callbackObj.data[0] as Draw;
						this.displayApproveResultDialog = true;
						this.getLotteryResultsAndBoardInfo(false);
					}),
					createDataTableAction(publishResultMI, (callbackObj) => {
						this.selectedCogDraw = callbackObj.data[0] as Draw;
						this.displayPublishDrawResultDialog = true;
					}),
				);
			} else if (drawType === DrawType.PREVIOUS) {
				menuItems.push(voidTableOpt)
			}
		} else {
			if (viewMoreOpts) {
				viewMoreMI.command = viewMoreMICallback;
				menuItems.push(viewMoreMI);
			}

			if (drawType === DrawType.ACTIVE) {
				menuItems.push(
					cancelMI,
					voidMI,
					changeMI,
					// NOTE: These are commented out for the cards because of the way the statues are transitioned through state in the BE.
					// All upcoming draws shown on the cards have a statusID of 0, which means that trying to Insert or Approve
					// them will result in an error. Therefore, manual Insert and Approve is made available via the rows to suitable records.
					// manualInsertMI,
					// approveResultMI
				);
			} else if (drawType === DrawType.PREVIOUS) {
				menuItems.push(voidMI)
			}
		}

		return menuItems;
	}

	private getLotteryResultsAndBoardInfo(onlyBoardInfo: boolean) {
		// If dialogType is "approve" then get the inserted manual results for the boards first
		this.loading = true;

		const obsObj: any = {
			getInsertedResults: this.drawService.getManuallyInsertedResults(this.selectedCogDraw.drawID),
			getBoardInfo: this.drawService.getBoardInfo(this.selectedCogDraw.drawID)
		}

		if (onlyBoardInfo) {
			delete obsObj['getInsertedResults'];
		}

		const observables = forkJoin(obsObj);

		observables.subscribe({
			next: (res: any) => {
				this.lotteryResults = res?.getInsertedResults;

				this.gameFormat = res?.getBoardInfo;

				this.loading = false;
			},
			error: err => {
				this.loading = false;
				this.errorService.handleError(err, err.description, `ForkJoin for GetManuallyInsertedResults and GetBasicGameGroupInfo`);
			}
		})
	}

	resetSelected() {
		this.selectedCogDraw = undefined
	}

}
