import {Injectable} from '@angular/core';
import {AppConfigService} from '../../helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ServiceController} from '../utilities';
import {Observable} from 'rxjs';
import {TableDataResponse} from '../components';
import {
	ChangeTrail,
	NotesUpdateBase,
	NotesUpdateRequest,
	PlayerNotesChangeTrailDTO,
	PlayerNotesDTO
} from '../models/player/player-audit.model';
import {ResponseBodyType} from '../enums';
import {map} from 'rxjs/operators';
import {unescapeSpecialChars} from '../utilities/general-utilities/string.utility';
import {BaseServiceImpl} from './base-service-impl';

@Injectable({
	providedIn: 'root'
})
export class PlayerNotesService extends BaseServiceImpl {

	constructor(
		protected http: HttpClient,
		protected appConfigService: AppConfigService,) {
		super(http, ServiceController.PLAYER_NOTES, appConfigService.serviceBaseURL);
	}

	/**
	 * Extracts text content from all paragraph tags in an HTML string.
	 * @param html - The HTML string containing multiple paragraph tags.
	 * @param addNewline when true, \n is added after each parsed text from a <p> element
	 * @returns The concatenated text content of all paragraphs.
	 */
	public static extractTextFromAllParagraphs(html: string, addNewline: boolean = false): string | null {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');
		const paragraphs = doc.querySelectorAll('p');

		if (paragraphs?.length <= 0) {
			// return the original str if no paragraph match as this indicates it was already parsed.
			return html;
		}

		let textContent = '';

		paragraphs.forEach(p => {
			const endChar = addNewline ? ' \n' : '';
			textContent += (p.textContent + endChar);
		});

		return textContent.trim();
	}

	addNote(playerID: number, note: string): Observable<any> {
		return this.post(undefined, {playerID, note}, undefined, undefined);
	}

	editNote(request: NotesUpdateRequest): Observable<any> {
		return this.edit(undefined, request, undefined, undefined);
	}

	deleteNote(request: NotesUpdateBase): Observable<any> {
		const params: HttpParams = new HttpParams()
			.set('playerNoteID', request.playerNoteID);

		return this.delete(undefined, undefined, params);
	}

	getNotes(searchParams: HttpParams): Observable<TableDataResponse<PlayerNotesDTO>> {
		return this.validateDataTableRes(
			['playerNoteID', 'author', 'note'], undefined,
			undefined, searchParams, ResponseBodyType.JSON
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: PlayerNotesDTO) => {
					// Parse Date object
					entry.createdDate = new Date(entry.createdDate);
					entry.updatedDate = entry.updatedDate ? new Date(entry.updatedDate) : undefined;

					entry.note = unescapeSpecialChars(entry.note);

					// default to true so UI can use this to initially disable previous notes
					entry.readonly = true;

					return entry;
				});

				return res as TableDataResponse<PlayerNotesDTO>;
			}));
	}

	getNoteHistory(request: NotesUpdateBase): Observable<PlayerNotesChangeTrailDTO> {
		return this.get(undefined, [request.playerNoteID], undefined)
			.pipe(
				map((dto: PlayerNotesChangeTrailDTO) => {
					// Parse Date object
					dto.createdDate = dto.createdDate ? new Date(dto.createdDate) : undefined;
					dto.originalNote = unescapeSpecialChars(dto.originalNote);

					dto.changeTrails = dto.changeTrails.map((entry: ChangeTrail) => {
						// Parse Date object
						entry.updatedOn = entry.updatedOn ? new Date(entry.updatedOn) : undefined;
						entry.value = PlayerNotesService.extractTextFromAllParagraphs(unescapeSpecialChars(entry.value), true);

						return entry;
					});

					return dto as PlayerNotesChangeTrailDTO;
				}));
	}
}
