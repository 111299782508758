<div class="search-field ui-g ui-lg-12 ui-xl-10">
	<div class="ui-g-2">
		<p-selectButton [disabled]="isFirstSearchField" [options]="searchAndOr"
						[(ngModel)]="selectedSearchAndOr"></p-selectButton>
	</div>

	<div class="ui-g-8 search-selections">
		<div class="ui-g-2 ui-lg-3 search-field-name">
			<label>{{ searchField.label }}</label>
		</div>

		<div class="ui-g-5 ui-lg-4" [attr.he-label]="'search-field-operator'">
			<span class="p-float-label">
				<p-dropdown [inputId]="operatorInputID" [options]="dropdownSearchFieldOperators" placeholder="Operator"
							[ngModel]="selectedOperator" (ngModelChange)="selectSearchOperator($event)"></p-dropdown>
			</span>
		</div>

		<div [ngSwitch]="searchField.type" class="search-field-value ui-g-5 ui-lg-4">
			<!-- String -->
			<span class="p-float-label" *ngSwitchCase="searchFieldType.StringDefault">
				<input id="{{ inputID }}" pInputText
					   [ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)"/>

				<label for="{{ inputID }}">Value - Text</label>
			</span>

			<span class="p-float-label" *ngSwitchCase="searchFieldType.StringWithContains">
				<input id="{{ inputID }}" pInputText
					   [ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)"/>

				<label for="{{ inputID }}">Value - Text</label>
			</span>

			<!-- Boolean -->
			<span class="p-float-label" *ngSwitchCase="searchFieldType.Boolean">
				<p-dropdown [inputId]="inputID" [options]="booleanOptions" [autoDisplayFirst]="false"
							[ngModel]="searchField.searchValue"
							(ngModelChange)="updateFilterString($event, searchField)">
				</p-dropdown>
				<label for="{{ inputID }}">Value - True/False</label>
			</span>

			<!-- Date -->
			<ng-container *ngSwitchCase="searchFieldType.Date">
				<span class="p-float-label" *ngIf="selectedOperator !== searchOperatorType.BETWEEN">
					<p-calendar dataType="string" [inputId]="inputID" [monthNavigator]="true" dateFormat="dd/mm/yy"
								[yearNavigator]="true" yearRange="2000:2030" [showTime]="true" [showSeconds]="true"
								[ngModel]="searchField.searchValue" placeholder="Value - Date" appendTo="body"
								(ngModelChange)="updateFilterString($event, searchField)">
					</p-calendar>
				</span>

				<span class="p-float-label" *ngIf="selectedOperator === searchOperatorType.BETWEEN">
					<p-calendar dataType="string" [inputId]="input2ID" selectionMode="range" dateFormat="dd/mm/yy"
								[showTime]="true" [showSeconds]="true"
								[ngModel]="searchField.searchValue" placeholder="Value - Date Range" appendTo="body"
								(ngModelChange)="updateFilterString($event, searchField)">
					</p-calendar>
				</span>
			</ng-container>

			<!-- ID -->
			<ng-container *ngSwitchCase="searchFieldType.Id">
				<span class="p-float-label">
					<p-inputNumber [inputId]="inputID" [ngModel]="searchField.searchValue"
								   mode="decimal" [useGrouping]="false"
								   (ngModelChange)="updateFilterString($event, searchField)" [showButtons]="true">
					</p-inputNumber>
					<label for="{{ inputID }}">Value - Number</label>
				</span>
			</ng-container>

			<!-- NumberStr: Allows validation to be added for an input which should be a number UI-side,
			                but string when parsed for BE -->
			<ng-container *ngSwitchCase="searchFieldType.NumberStrDefault">
				<span class="p-float-label" *ngIf="selectedOperator !== searchOperatorType.BETWEEN">
					<p-inputNumber [inputId]="inputID" [ngModel]="searchField.searchValue" [useGrouping]="false"
								   mode="decimal" (ngModelChange)="updateFilterString($event, searchField)"
								   [showButtons]="true"></p-inputNumber>
					<label for="{{ inputID }}">Value - Number</label>
				</span>

				<div *ngIf="selectedOperator === searchOperatorType.BETWEEN" class="number-range-wrapper">
					<span class="number-range-value">
						<span class="min">{{ numberRangeMinValue }}</span>
						<span class="max">{{ numberRangeMaxValue }}</span>
					</span>
					<p-slider [min]="searchField.minNumberRange" [max]="searchField.maxNumberRange" [range]="true"
							  [style]="{'width': '100%'}"
							  [ngModel]="searchField.searchValue"
							  (ngModelChange)="updateFilterString($event, searchField)"></p-slider>
				</div>
			</ng-container>

			<!-- Number -->
			<ng-container *ngSwitchCase="searchFieldType.Number">
				<span class="p-float-label" *ngIf="selectedOperator !== searchOperatorType.BETWEEN">
					<p-inputNumber [inputId]="inputID" [ngModel]="searchField.searchValue"
								   [useGrouping]="false" mode="decimal" maxFractionDigits="7"
								   (ngModelChange)="updateFilterString($event, searchField)" [showButtons]="true">
					</p-inputNumber>
					<label for="{{ inputID }}">Value - Number</label>
				</span>

				<div *ngIf="selectedOperator === searchOperatorType.BETWEEN" class="number-range-wrapper">
					<span class="number-range-value">
						<span class="min">{{ numberRangeMinValue }}</span>
						<span class="max">{{ numberRangeMaxValue }}</span>
					</span>
					<p-slider [min]="searchField.minNumberRange" [max]="searchField.maxNumberRange" [range]="true"
							  [style]="{'width': '100%'}"
							  [ngModel]="searchField.searchValue"
							  (ngModelChange)="updateFilterString($event, searchField)"></p-slider>
				</div>
			</ng-container>

			<!-- List -->
			<ng-container *ngSwitchCase="searchFieldType.StringList">
				<ng-container *ngTemplateOutlet="listElement"></ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="searchFieldType.NumberList">
				<ng-container *ngTemplateOutlet="listElement"></ng-container>
			</ng-container>

			<ng-template #listElement>
				<!--[filterBy]="'label'"-->
				<p-multiSelect [options]="searchField.listOptions"
							   [resetFilterOnHide]="true" [filter]="true" optionLabel="label"
							   [style]="{'width': '100%'}" [ngModel]="searchField.searchValue"
							   placeholder="Choose multiple" [virtualScroll]="true" [virtualScrollItemSize]="36"
							   (ngModelChange)="updateFilterString($event, searchField)"></p-multiSelect>
			</ng-template>
		</div>

		<button class="helio-orange-btn" pButton (click)="delete()" icon="ui-icon-delete" title="Delete Search Field"
				style="margin: 6px 6px 6px 28px;" [attr.he-label]="searchField.label"></button>
	</div>
</div>
