<div id="overhead-ui" [ngStyle]="{display: isTabbedTable || skeletonOverheadOptRight ? 'grid' : 'none'}">
	<div class="external-overhead-options-left">
		<!--Represents either the tab title, or the filter options btn as used in TransKYC global page-->
		<p-skeleton [style.display]="(!isTabbedTable && skeletonOverheadOptLeft) || isTabbedTable ? 'block' : 'none'"
					width="160px" height="33px"></p-skeleton>
	</div>

	<div *ngIf="skeletonOverheadOptRight" class="external-overhead-options-right">
		<p-skeleton *ngFor="let item of emptyArrayOfSize(skeletonOverheadOptCount)"
					width="110px" height="33px"></p-skeleton>
	</div>
</div>

<div class="table" style="padding: 12px 0; background: white;">

	<div *ngIf="skeletonHasHeader" class="header-ui" style="">
		<p-skeleton width="56px" height="32px"></p-skeleton>

		<div class="table-action-wrapper">
			<p-skeleton shape="circle" *ngFor="let item of emptyArrayOfSize(2)" size="32px"></p-skeleton>
		</div>
	</div>

	<div class="body" style="padding: 0 12px">
		<p-skeleton *ngFor="let item of emptyArrayOfSize(9)" class="cell"></p-skeleton>
	</div>

	<div *ngIf="skeletonHasFooter" class="footer">
		<p-skeleton *ngFor="let item of emptyArrayOfSize(3)" width="32px" height="32px"></p-skeleton>
	</div>
</div>
