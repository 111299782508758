import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfigService} from './app-config.service';
import {ServiceAction, ServiceController} from '../../shared/utilities/service-utilities';
import {ChangePassword, User, UserRequest, UserV2} from '../../shared/models';
import {TableDataResponse} from '../../shared/components/data-table-v3';
import {ResetPasswordResponse} from '../../shared/models/user/reset-password';
import {ResponseBodyType} from '../../shared';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';

/**
 * Service class used to handle HTTP requests related to Login
 */
@Injectable()
export class UsersService extends BaseServiceImpl {

	public totalRowCount: number;

	// private accessToken = sessionStorage.getItem('access_token');

	/**
	 * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 * @param appConfigService exposes configurations
	 */
	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.USERS, appConfigService.serviceBaseURL);
	}

	createUser(user: UserRequest) {
		return this.post(undefined, user);
	}

	editUser(user: UserRequest) {
		return this.edit(undefined, user, undefined, [user.id]);
	}

	deleteUser(id: number) {
		return this.delete('', [id]);
	}

	getUser(): Observable<UserV2> {
		return this.get<UserV2>('GetClaims',
			undefined, undefined, ResponseBodyType.JSON, ServiceController.USER_OWN);
	}

	getUsers(searchParams?: HttpParams): Observable<TableDataResponse<User>> {
		return this.validateDataTableRes(['passwordExpired', 'requirePasswordChange', 'email'],
			undefined, undefined, searchParams);
	}

	activateUser(userID: number): Observable<any> {
		return this.edit(undefined, undefined, undefined, [userID, ServiceAction.USER_ACTIVATE]);
	}

	deactivateUser(userID: number): Observable<any> {
		return this.edit(undefined, undefined, undefined, [userID, ServiceAction.USER_DEACTIVATE]);
	}

	changePassword(data: ChangePassword) {
		return this.edit(ServiceAction.USER_CHANGE_PASSWORD, data);
	}

	resetPassword(userID: number): Observable<ResetPasswordResponse> {
		// newPassword is added in anticipation of future extension. For now, null is expected to be returned by default to BE.
		return this.edit(
			undefined, {userID, newPassword: null},
			undefined, [userID, ServiceAction.USER_RESET_PASSWORD]
		);
	}
}
