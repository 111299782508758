export enum DrawType {
	ACTIVE = 111,
	PREVIOUS
}

/*
export enum DropDrawType {
	VOID = 'void',
	CANCEL = 'cancel'
}
*/


export type DropDrawType = 'void' | 'cancel'; // This maps to required verification strings in API

export type ManualEntryType = 'insertion' | 'approval';

export enum ManualApprovalOpts {
	ACCEPT = 'Accept',
	REJECT = 'Reject'
}
