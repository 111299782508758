import {Injectable} from '@angular/core';
import {AppConfigService} from '../../../helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ResponseBodyType, ServiceAction, ServiceController, TableDataResponse} from '../../../shared';
import {Observable} from 'rxjs';
import {
	BankAccount,
	BankAccountRequest,
	BankCard,
	EWalletCreateRequest,
	EWalletDetails,
	EWalletUpdateRequest,
	ExchangeHouse,
	ExchangeHouseRequest
} from '../../../shared/models/finance/transfer-information.model';
import {map} from 'rxjs/operators';
import {BaseServiceImpl} from '../../../shared/services/base-service-impl';

@Injectable({
	providedIn: 'root'
})
export class BankCardService extends BaseServiceImpl {

	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.PAYMENTS, appConfigService.serviceBaseURL);
	}

	getCards(playerID: number, searchParams?: HttpParams): Observable<TableDataResponse<BankCard>> {
		const getSrc = (methodName: string): string => {
			methodName = methodName.toLowerCase();

			if (methodName === 'visa') {
				return '/assets/lottery-logos/visa-logo-blue.png';
			} else if (methodName === 'mastercard') {
				return '/assets/lottery-logos/mastercard-logo.png';
			} else if (methodName === 'amex') {
				return '/assets/lottery-logos/amex_logo.png';
			} else {
				return '/assets/lottery-logos/unknown_card_type.png';
			}
		}

		let params = searchParams ?? new HttpParams();
		params = params.set('playerID', playerID)

		return this.validateDataTableRes(
			['playerPaymentAccountID', 'accountReference', 'paymentMethodName'], undefined,
			[ServiceAction.PAYMENT_CARDS], params, ResponseBodyType.JSON, ServiceController.PAYMENT_ACCOUNTS
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: any) => {
					const tempObj: BankCard = {...entry};

					tempObj.src = getSrc(tempObj.paymentMethodName);

					// Parse Expiry date string
					// const month = String(entry.expiryMonth);
					// tempObj.expiryDateStr = `${(month.length === 1) ? '0' : ''}${entry.expiryMonth}/${entry.expiryYear}`;

					// Parse Date objects
					tempObj.dateCreated = new Date(entry.dateCreated);
					tempObj.lastUsed = new Date(entry.lastUsed);

					return tempObj;
				});

				return res as TableDataResponse<BankCard>;
			})
		);

		// return of(dummyWalletCardsData).pipe(delay(200));
	}

	setAsDefault(playerID: number, method: BankCard): Observable<void> {
		return this.edit(
			undefined, undefined, undefined,
			[
				playerID,
				ServiceAction.GET_PAYMENT_ACCOUNTS,
				method.playerPaymentAccountID,
				ServiceAction.GET_PAYMENT_ACCOUNTS_SET_DEFAULT
			]
		);
	}

	remove(playerID: number, method: BankCard): Observable<void> {
		return this.delete(
			undefined,
			[playerID, ServiceAction.GET_PAYMENT_ACCOUNTS, method.playerPaymentAccountID]
		);
	}

	//region ExchangeHouse

	getExchangeHouses(playerID: number, searchParams?: HttpParams, getDeleted: boolean = false)
		: Observable<TableDataResponse<ExchangeHouse>> {
		let params = searchParams ?? new HttpParams();
		params = params.set('playerID', playerID)
			.set('getDeleted', getDeleted);

		return this.validateDataTableRes(
			['playerPaymentAccountID', 'accountReference', 'withdrawalMethod'], ServiceAction.EXCHANGE_HOUSES,
			undefined, params, ResponseBodyType.JSON, ServiceController.PAYMENT_ACCOUNTS
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: any) => {
					const tempObj: ExchangeHouse = {...entry};

					// Parse Date objects
					tempObj.dateCreated = new Date(entry.dateCreated);
					tempObj.lastUsed = new Date(entry.lastUsed);
					tempObj.lastUpdated = new Date(entry.lastUpdated);

					return tempObj;
				});

				return res as TableDataResponse<ExchangeHouse>;
			})
		);
	}

	addExchangeHouse(data: ExchangeHouseRequest): Observable<ExchangeHouse> {
		return this.post(
			ServiceAction.EXCHANGE_HOUSES, data, undefined,
			undefined, ServiceController.PAYMENT_ACCOUNTS
		);
	}

	updateExchangeHouse(data: ExchangeHouseRequest) {
		return this.edit(
			ServiceAction.EXCHANGE_HOUSES, data, undefined,
			undefined, ServiceController.PAYMENT_ACCOUNTS
		);
	}

	deleteExchangeHouse(playerPaymentAccountID: number): Observable<void> {
		const params = new HttpParams().set('playerPaymentAccountID', playerPaymentAccountID);

		return this.delete(
			ServiceAction.EXCHANGE_HOUSES,
			undefined, params, ServiceController.PAYMENT_ACCOUNTS
		);
	}

	//endregion

	//region BankAccount

	getBankAccounts(playerID: number, searchParams?: HttpParams, getDeleted: boolean = false)
		: Observable<TableDataResponse<BankAccount>> {
		let params = searchParams ?? new HttpParams();
		params = params.set('playerID', playerID)
			.set('getDeleted', getDeleted);

		return this.validateDataTableRes(
			['playerPaymentAccountID', 'accountReference', 'withdrawalMethod'], ServiceAction.BANK_ACCOUNTS,
			undefined, params, ResponseBodyType.JSON, ServiceController.PAYMENT_ACCOUNTS
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: any) => {
					const tempObj: BankAccount = {...entry};

					// Parse Date objects
					tempObj.dateCreated = new Date(entry.dateCreated);
					tempObj.lastUsed = new Date(entry.lastUsed);
					tempObj.lastUpdated = new Date(entry.lastUpdated);

					return tempObj;
				});

				return res as TableDataResponse<BankAccount>;
			})
		);
	}

	addBankAccount(data: BankAccountRequest): Observable<ExchangeHouse> {
		return this.post(
			ServiceAction.BANK_ACCOUNTS, data, undefined,
			undefined, ServiceController.PAYMENT_ACCOUNTS
		);
	}

	updateBankAccount(data: BankAccountRequest) {
		return this.edit(
			ServiceAction.BANK_ACCOUNTS, data, undefined,
			undefined, ServiceController.PAYMENT_ACCOUNTS
		);
	}

	deleteBankAccount(playerPaymentAccountID: number): Observable<void> {
		const params = new HttpParams().set('playerPaymentAccountID', playerPaymentAccountID);

		return this.delete(
			ServiceAction.BANK_ACCOUNTS, undefined, params, ServiceController.PAYMENT_ACCOUNTS
		);
	}

	//endregion

	//region eWallets

	getEWallets(requestIds: { playerID: number, paymentMethodID: number }, searchParams?: HttpParams, getDeleted: boolean = false)
		: Observable<TableDataResponse<EWalletDetails>> {
		let params = searchParams ?? new HttpParams();
		params = params.set('playerID', requestIds.playerID)
			.set('paymentMethodID', requestIds.paymentMethodID)
			.set('getDeleted', getDeleted);

		return this.validateDataTableRes(
			['playerPaymentAccountID', 'accountReference', 'paymentMethod'], ServiceAction.E_WALLETS,
			undefined, params, ResponseBodyType.JSON, ServiceController.PAYMENT_ACCOUNTS
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: any) => {
					const tempObj: EWalletDetails = {...entry};

					// Parse Date objects
					tempObj.dateCreated = entry.dateCreated ? new Date(entry.dateCreated) : null;
					tempObj.lastUsed = entry.lastUsed ? new Date(entry.lastUsed) : null;
					tempObj.lastUpdated = entry.lastUpdated ? new Date(entry.lastUpdated) : null;

					return tempObj;
				});

				return res as TableDataResponse<EWalletDetails>;
			})
		);
	}

	addEWallet(data: EWalletCreateRequest): Observable<{ playerPaymentAccountID: number }> {
		return this.post(
			ServiceAction.E_WALLETS, data, undefined,
			undefined, ServiceController.PAYMENT_ACCOUNTS
		);
	}


	updateEWallet(data: EWalletUpdateRequest) {
		return this.edit(
			ServiceAction.E_WALLETS, data, undefined,
			undefined, ServiceController.PAYMENT_ACCOUNTS
		);
	}

	deleteEWallet(playerPaymentAccountID: number): Observable<void> {
		const params = new HttpParams().set('playerPaymentAccountID', playerPaymentAccountID);

		return this.delete(ServiceAction.E_WALLETS, undefined, params, ServiceController.PAYMENT_ACCOUNTS);
	}

	//endregion
}
