import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportFilterField, ReportFilterFieldType} from '../advanced-search';
import {SelectItem} from 'primeng/api';
import {MultiSelectFilterEvent, MultiSelectLazyLoadEvent} from 'primeng/multiselect';
import {dateToISOStr} from '../../../utilities/general-utilities/string.utility';
import {_luxonDateTime} from '../../../global-libraries';
import {BehaviorSubject} from 'rxjs';

@Component({
	selector: 'he-filter-component',
	templateUrl: './filter-component.component.html',
	styleUrls: ['./filter-component.component.scss']
})
export class FilterComponentComponent implements OnInit {
	private static previousMultiSelectLazyLoadValue = {first: undefined, last: undefined};

	protected readonly filterFieldType = ReportFilterFieldType;

	@Input() filterField: ReportFilterField;

	// This serves as an opportunity to flag and update other filters that are dependent on it from the caller
	@Output() valueChange: EventEmitter<FilterValueChangeEvent> = new EventEmitter<FilterValueChangeEvent>();
	@Output() searchEvent: EventEmitter<FilterListSearchEvent> = new EventEmitter<FilterListSearchEvent>();
	@Output() lazyLoadEvent: EventEmitter<FilterLazyLoadEvent> = new EventEmitter<FilterLazyLoadEvent>();

	booleanOptions: SelectItem[];
	operatorInputID: string;
	inputID: string;
	input2ID: string;

	isRange = false;
	numberRangeMinValue: number;
	numberRangeMaxValue: number;

	dtContext = Object.freeze({
		TIME: {isRange: false, showTime: true, timeOnly: true},
		DATE: {isRange: false, showTime: false, timeOnly: false},
		DATETIME: {isRange: false, showTime: true, timeOnly: false},
		DATE_RANGE: {isRange: true, showTime: false, timeOnly: false},
		DATETIME_RANGE: {isRange: true, showTime: true, timeOnly: false},
	});

	searchValueTemp: any; // Only added to allow use of ngModel in template with p-calender

	ngOnInit() {
		this.inputID = new Date().getTime().toString();
		this.input2ID = `${this.inputID}-2`;
		this.operatorInputID = `${this.inputID}-operator`;

		this.initDefaultValue()
	}

	private setNumberRange() {
		this.filterField.searchValue = [
			this.filterField.minNumberRange,
			this.filterField.maxNumberRange
		];

		this.numberRangeMinValue = this.filterField.minNumberRange;
		this.numberRangeMaxValue = this.filterField.maxNumberRange;
	}

	private initDefaultValue() {
		if (this.filterField.type === ReportFilterFieldType.Number) {
			this.onValueChange(this.filterField.defaultValue, this.filterField);
		} else if (this.filterField.type === ReportFilterFieldType.SearchableSelectionList ||
			this.filterField.type === ReportFilterFieldType.SearchableMultiSelectionList) {
			// Since SearchableSelectionList and SearchableMultiSelectionList are the only ones with prefetched lookup,
			// and hence a default value
			if (this.filterField.defaultValue) {
				this.onValueChange(this.filterField.defaultValue, this.filterField)
			}
		} else if (
			this.filterField.type === ReportFilterFieldType.AdvancedSearchableSelectionList ||
			this.filterField.type === ReportFilterFieldType.AdvancedSearchableMultiSelectionList
		) {
			// Trigger value change immediately with empty so that the dynamic lookup is fetched.
			// NB: Until this is done, *ngIf="filterFields && !_loadingLookup" will not be satisfied in report.component.html
			this.valueChange.emit({newValue: '', searchField: this.filterField} as FilterValueChangeEvent);
		}
	}

	onValueChange(value: any, filterField: ReportFilterField): void {
		let newValue: any;

		// console.log('onValueChange:', value, filterField);

		// This step is necessary to convert date independent of [ngModel] to a valid format expected by API
		if (filterField.type === ReportFilterFieldType.Date || filterField.type === ReportFilterFieldType.DateTime ||
			filterField.type === ReportFilterFieldType.DateRange || filterField.type === ReportFilterFieldType.DateTimeRange) {
			newValue = dateToISOStr(_luxonDateTime.fromFormat(value + ':00', 'dd/LL/yy hh:mm:ss'));
		} else {
			newValue = value;
		}

		if (newValue === undefined || newValue === null) {
			filterField.searchValue = undefined;
			filterField.filterString = undefined;
			return;
		}

		filterField.searchValue = newValue;

		const property = filterField.property;
		// const value = filterField.searchValue;
		const filterVariables: any = {};
		let arrayValue;

		if (Array.isArray(filterField.searchValue)) {
			// Since for MultiSelect, with filter=true, value is a SelectItem obj
			arrayValue = filterField.searchValue.map(entry => entry?.value)

			filterVariables[property] = arrayValue.toString(); // So that arr is sent as a comma separated string
		} else if (filterField.searchValue !== '') {
			filterVariables[property] = filterField.searchValue;
		}

		/*switch (filterField.type) {
			case FilterFieldType.String:
				if (value !== '') {
					filterVariables[property] = value;
				}
				break;
			case FilterFieldType.Date:
				const dateTimeFormat = 'dd/LL/yy hh:mm:ss';
				if (this.isRange) {
					if (value[0] !== null && value[1] !== null) {
						const strDateMin = dateToISOStr(_luxonDateTime.fromFormat(value[0], dateTimeFormat));
						const strDateMax = dateToISOStr(_luxonDateTime.fromFormat(value[1], dateTimeFormat));
						filterVariables[property] = `(ge DATETIME "${strDateMin}" AND le DATETIME "${strDateMax}")`;
					}
				} else {
					// value should be in the form: '21/03/2023 10:24:33'
					// TODO: replace with #oDataDateStr helper
					const strDate = dateToISOStr(_luxonDateTime.fromFormat(value, dateTimeFormat));
					filterVariables[property] = `DATETIME "${strDate}"`;
				}
				break;
			default:
				break;
		}*/

		filterField.filterVariables = filterVariables;
		// console.log('FC onValueChange: ', this.filterField)

		if (this.filterField.type === ReportFilterFieldType.SearchableSelectionList ||
			this.filterField.type === ReportFilterFieldType.SearchableMultiSelectionList ||
			this.filterField.type === ReportFilterFieldType.AdvancedSearchableSelectionList ||
			this.filterField.type === ReportFilterFieldType.AdvancedSearchableMultiSelectionList) {
			if (arrayValue) {
				this.valueChange.emit({newValue: `${arrayValue?.toString()}`, searchField: filterField} as FilterValueChangeEvent);
			}
		}
	}

	calendarPlaceholder(type: ReportFilterFieldType) {
		switch (type) {
			case ReportFilterFieldType.Date:
				return 'Value - Date';
			case ReportFilterFieldType.DateTime:
				return 'Value - DateTime';
			case ReportFilterFieldType.Time:
				return 'Value - Time';
			default:
				return 'Value';
		}
	}

	get heDisabled() {
		return (this.filterField.type === ReportFilterFieldType.SearchableSelectionList ||
			this.filterField.type === ReportFilterFieldType.SearchableMultiSelectionList ||
			this.filterField.type === ReportFilterFieldType.AdvancedSearchableSelectionList ||
			this.filterField.type === ReportFilterFieldType.AdvancedSearchableMultiSelectionList) && !this.filterField.listOptions
	}

	onListSearchEvent($event: MultiSelectFilterEvent, filterField: ReportFilterField) {
		// $event.originalEvent.preventDefault();
		// $event.originalEvent.stopImmediatePropagation();
		// console.log('FilterComp - onListSearchEvent: value =', $event);
		filterField.listSearchValue = $event.filter;
		this.searchEvent.emit({searchString: $event.filter, filterField});
	}

	onLazyLoadEvent(event: MultiSelectLazyLoadEvent, filterField: ReportFilterField) {
		// console.log('onLazyLoadEvent ORIG - ', event)
		// This check is necessary as it was found that multiple permutation duplicates of first and last
		// values where being sent out, this check serves to reduce unnecessary calls to api
		if ((FilterComponentComponent.previousMultiSelectLazyLoadValue.first === event.first) ||
			(FilterComponentComponent.previousMultiSelectLazyLoadValue.last === event.last)) {
			return;
		}

		FilterComponentComponent.previousMultiSelectLazyLoadValue = event;
		this.lazyLoadEvent.emit({event, filterField});
	}

	setDefaultValue(filterField: ReportFilterField) {
		const defaultValue = this.filterField.defaultValue;

		if (!defaultValue) {
			return null; // might be necessary to return '' here
		}

		return defaultValue;
	}
}

export interface FilterValueChangeEvent {
	newValue: any;
	searchField: ReportFilterField;
}

export interface FilterListSearchEvent {
	searchString: any;
	filterField: ReportFilterField;
}

export interface FilterLazyLoadEvent {
	event: MultiSelectLazyLoadEvent;
	filterField: ReportFilterField;
}
