<div *ngIf="!isTabbedTable" class="header-opts">
	<he-header headerTitle="{{title}}"></he-header>
</div>

<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data"
				  [selectedRows]="selectedTransactions"
				  [rowsPerPage]="tableFilters?.rowsPerPage" [restoreOrderBy]="tableFilters?.orderBy"
				  [lazy]="true" (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords" [offset]="offset"
				  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
				  [loading]="loading" [canExportAll]="false"
				  (exportToCsvRequested)="exportToCSV(null, false)"
				  [headerTitle]="null"
				  [isTabbedTable]="isTabbedTable" [tabbedTitle]="title" [skeletonOverheadOptRight]="isTabbedTable"
				  [showNestedOverheadOpts]="isTabbedTable">
</he-data-table-v3>
