import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AppConfigService} from './app-config.service';
import {ServiceController} from '../../shared/utilities/service-utilities/service-controller.urls';
import {TableDataResponse} from '../../shared/components/data-table-v3/shared';
import {UserLogDTO} from '../../shared/models/user/user-log.model';
import {map} from 'rxjs/operators';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';


/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class AuditService extends BaseServiceImpl {

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.AUDIT, appConfigService.serviceBaseURL);
	}

	getUserLogs(userID: number, searchParams?: HttpParams): Observable<TableDataResponse<UserLogDTO>> {
		// return of(dummyViewUserLogData)

		return this.validateDataTableRes(['username', 'timestamp'], undefined, [userID], searchParams).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: any) => {
					// Parse Date object
					entry.timestamp = new Date(entry.timestamp);

					return entry;
				})

				return res as TableDataResponse<UserLogDTO>;
			}));
	}
}
