import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {Confirmation} from 'primeng/api';
import {ToastMessageType} from '../models';
import {AppLoaderService, BoErrorHandlerService, ToastDisplayService} from '../../helio-core-services';
import {RetailAgent} from '../models/retail-agent.model';
import {RetailAgentService} from '../services/retail-agent.service';

export class AgentUtils {

	constructor() {
	}

	/**
	 * @param selected the {@link RetailAgent} to operate on
	 * @param newBalance the new balance to assign
	 * @param services required services
	 * @param onSuccessCallback intended to be used as a way of refreshing dataset after successful adjustment in backend
	 * @param acceptCallback callback to invoke when the confirmation is initially accepted
	 * @param onErrorCallback callback to invoke if the confirmation is initially accepted
	 */
	public static getDialogConfirmation(
		selected: RetailAgent, newBalance: number,
		services: {
			loader: AppLoaderService,
			decimalPipe: DecimalPipe,
			toast: ToastDisplayService,
			agent: RetailAgentService,
			err: BoErrorHandlerService
		},
		onSuccessCallback: (selected: RetailAgent, balance: { balance: number }) => void,
		acceptCallback?: () => void,
		onErrorCallback?: (errMsg: string) => void,
	) {
		const confMessage: Confirmation = {
			message: 'Are you sure you want to adjust balance?',
			accept: () => {
				// services.loader.isLoading = true;

				if (acceptCallback) {
					acceptCallback();
				}

				services.agent.adjustRetailAgentBalance(selected.retailAgentID, {newBalance: newBalance}).subscribe({
					next: (balance: { balance: number }) => {
						// services.loader.isLoading = false;

						onSuccessCallback(selected, balance);

						services.toast.addMessage({
							title: 'Success',
							description: AgentUtils.parseAgentBalSuccessMsg(selected, balance, services.decimalPipe),
							type: ToastMessageType.success
						});
					},
					error: error => {
						if (onErrorCallback) {
							onErrorCallback('Adjusting Agent Balance failed.');
						}

						// services.loader.isLoading = false;
						services.err.handleError(error, undefined, 'Adjusting Agent Balance');
					}
				});
			},
			reject: () => {
				return;
			}
		};
		return confMessage;
	}

	public static parseAgentBalSuccessMsg(selected: RetailAgent, balance: { balance: number }, decimalPipe: DecimalPipe) {
		// Format balance to two decimal places and include thousands separator
		const formattedBal = decimalPipe.transform(balance.balance, '1.2-2') || '';

		return `Agent balance has been successfully adjusted.\n\n
								Balance is now ${selected.currencyCode} ${formattedBal}`;
	}

	public static parseCurrencyCode(selected: RetailAgent, currencyPipe: CurrencyPipe): string {
		const renderedCurrencyCode = currencyPipe.transform(
			0, selected.currencyCode, 'symbol', '1.0-2', 'en');
		return renderedCurrencyCode.replace('0', '');
	}

	public static isInvalidBal(amount: number) {
		return !amount || amount <= 0; // an unset amountToAdjust will be undefined
	}
}

