//region Country and Nationality related

import {CountryGroupLookup} from '../../interfaces/lookup-interfaces';
import {Currency} from '../currency';

/**
 * Used for Nationality and Country, where {@link name} is either the country name or nationality.
 */
export interface BaseNation {
	name: string;
	countryID: number;
	countryCode: string;
}

export interface CountryGroup extends BaseNation {
	ageLimit: number;
	isActive: boolean;
	phonePrefix: string;
	contactNumberBlocked: boolean;
	residenceBlocked: boolean;
}

export interface Gender {
	name: string;
	genderID: number;
	genderCode: string;
}

export interface Language {
	languageID: number;
	languageCode: string;
	name: string;
	isBackendActive: boolean;
	isGameActive: boolean;
}

export class PlayerNomenclatureRequest {
	currencies = false;
	countries = false;
	mobilePrefixes = false;
	nationalities = false;
	languages = false;
	genders = false;
	tenantSystems = false;
	timeZones = false;
}

export interface PlayerNomenclatureResponse {
	result: {
		countries: CountryGroupLookup[],
		unblockedCountries: CountryGroupLookup[],
		nationalities: BaseNation[],
		genders: Gender[],
		languages: Language[],
		currencies: Currency[]
	};
}

//endregion
