import {Component, HostBinding, Input} from '@angular/core';
import {BaseSkeleton} from '../base-skeleton';


@Component({
	selector: 'he-skeleton-table',
	templateUrl: './skeleton-table.component.html',
	styleUrls: ['./skeleton-table.component.scss']
})
export class SkeletonTableComponent extends BaseSkeleton {
	@Input() skeletonOverheadOptRight: boolean;
	@Input() skeletonOverheadOptLeft: boolean;
	@Input() skeletonOverheadOptCount: SkeletonOverheadItemCount = 3;

	@Input() @HostBinding('class.skeletonHasHeader') skeletonHasHeader = true;
	@Input() @HostBinding('class.skeletonHasFooter') skeletonHasFooter = true;

	@Input() isTabbedTable: boolean;
}

export type SkeletonOverheadItemCount =  1 | 2 | 3 | 4;
