import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api';

/**
 * @summary
 * Although it would promote DRY to have this class call #lookupService.getOperators, doing so introduces
 * various undesirable properties.
 * 1. In the event of an error, control would have to be handed back to caller, since
 *    this affects display of its table.
 * 2. Some elaborate way is required to indicate to the UI that the options are being loaded - an overkill
 * 3. Last but not least, the data generated here is critical for displaying caller pages (e.g. Payments Fallback)
 *    hence this call should be made alongside forkJoin, so that it is guaranteed to be available with other data.
 */
@Component({
	selector: 'he-target-data-selector',
	templateUrl: './target-data-selector.component.html',
	styleUrls: ['./target-data-selector.component.scss']
})
export class TargetDataSelectorComponent {

	@Input() loading = false;
	@Input() placeholder = 'Please select an item';
	@Input() options: SelectItem[] = [];
	@Input() skeletonWidth = '310px';

	@Input() selectedItem: SelectItem;
	@Output() selectedItemChange: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();

	onOperatorChange(event: any) {
		this.selectedItemChange.emit(event.value);
	}
}
