import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { DataTableServerResponse } from '../../shared/components/data-table-v3/shared/data-table-server-response.model';
import { ServiceAction } from '../../shared/utilities/service-utilities/service-action.urls';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';

@Injectable()
export class CompanyService extends BaseServiceImpl {
	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.COMPANY, appConfigService.serviceBaseURL);
	}

	getCompanies(searchParams?: HttpParams): Observable<DataTableServerResponse> {
		return this.get(ServiceAction.COMPANIES_GET, undefined, searchParams);
	}
}
