import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import {DrawProcessingService, DrawWinningPlayer} from '../shared';
import {
	SearchFieldType, ColumnType, DataTableLazyLoadEvent, DataTableServerResponse
} from '../../shared/components/data-table-v3';
import {TenantService} from '../../helio-core-services/services/tenants.service';
import {BoErrorHandlerService} from '../../helio-core-services/services/bo-error-handler.service';
import {AppConfigService} from '../../helio-core-services/services/app-config.service';
import {TenantDraw} from '../../shared/models/draws/tenant-draw.model';
import {ExportCSVUtility} from '../../shared/utilities/general-utilities/export-csv.utility';
import {FileDownloadUtility} from '../../shared/utilities/general-utilities/file-download.utility';
import {Draw, DrawWinStats} from '../../shared';
import {CommonTable} from '../../shared/interfaces/common-table';
import * as ColumnNames from '../../shared/constants/ui-db-name-mappings';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LookupService} from '../../shared/services/lookup.service';
import {Router} from '@angular/router';
import {NAV_SEGMENT} from '../../shared/constants/navigation-titles';

@Component({
	selector: 'he-draw-winners',
	styleUrls: ['./draw-winners.component.scss'],
	templateUrl: './draw-winners.component.html'
})

export class DrawWinnersComponent extends CommonTable<DrawWinStats> implements OnInit, OnDestroy {
	private _gameDraw$ = new BehaviorSubject<Draw>(null);

	selectedDrawID: number;
	selectedTenantID: number;
	isTenantServer: boolean = !this.appConfigService.isCentralServer;
	displayWinningPlayersDialog = false;

	// advanced search
	private tenantLookups: SelectItem[] = [];

	private getPastDrawsTenantsSub$: Subscription;
	private getPastTenantDrawsCsvSub$: Subscription;

	constructor(
		private router: Router,
		protected appConfigService: AppConfigService,
		private drawProcService: DrawProcessingService,
		private tenantService: TenantService,
		private lookupService: LookupService,
		protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	@Input() set gameDraw(value: Draw) {
		this._gameDraw$.next(value);
	}

	get gameDraw() {
		return this._gameDraw$.getValue();
	}

	ngOnInit() {
		this.initDataTable();
		if (this.appConfigService.isCentralServer) {
			this.getTenants();
		}

		this._gameDraw$.subscribe({
			next: value => {
				if (value) {
					this.getTableData(); // since it is important to only make the call after this.gameDraw?.drawID is ready
				}
			}
		});
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getPastTenantDrawsCsvSub$, this.getPastDrawsTenantsSub$);
	}

	initDataTable() {
		this.dataKey = 'drawID';

		this.cols = [
			{field: 'tenantName', header: 'Operator'},
			{field: 'winningLines', header: 'Winning Lines', columnType: ColumnType.Number},
			{field: 'prizeMoneyAwarded', header: 'Prize Money Awarded', columnType: ColumnType.Money}
		];

		this.searchFields = [
			{property: 'winningLines', label: 'Winning Lines', type: SearchFieldType.Number},
			{property: 'prizeMoneyAwarded', label: 'Prize Money Awarded', type: SearchFieldType.Number}
		];

		this.tableActions = [
			{
				menuItem: {
					label: 'Export to CSV',
					icon: 'ui-icon-insert-drive-file'
				},
				callback: (callbackObj) => {
					this.exportToCSV(callbackObj.data as TenantDraw[], callbackObj.isAllDataSelected);
				},
				isRowAction: false
			}];

		// tenant only can view winning players
		if (!this.appConfigService.isCentralServer) {
			this.tableActions.push({
				menuItem: {
					label: 'View Winning Players',
					icon: 'ui-icon-people'
				},
				callback: (callbackObj) => {
					const winStats = callbackObj.data[0] as DrawWinStats;

					this.selectedTenantID = winStats.tenantID;
					this.selectedDrawID = this.gameDraw?.drawID;

					this.displayWinningPlayersDialog = true;
				},
				isBulkAction: false
			});
		}
	}

	getTenants(): void {
		this.lookupService.getOperators().subscribe({
			next: res => {
				res.forEach((item) => {
					this.tenantLookups.push({label: item.tenantName, value: item.tenantID});
				});

				this.searchFields.push({
					property: 'tenantID', label: 'Operator',
					type: SearchFieldType.NumberList,
					listOptions: this.tenantLookups,
					isArraySearch: false
				});
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getPastDrawsTenantsSub$);

		this.getPastDrawsTenantsSub$ = this.drawProcService.getDrawWinningStatsByTenant(this.gameDraw?.drawID, this.params)
			.subscribe({
				next: (res: DataTableServerResponse) => {
					if (res.resultSet !== null && res.resultSet !== undefined) {
						this.data = res.resultSet as DrawWinStats[];
						this.totalRecords = res.totalRowCount;

						if (res.resultSet.length === 0) {
							this.tableMessage = this.appConfigService.tableMissingDataMessage;
						}
					}

					this.loading = false;
				},
				error: error => {
					this.loading = false;

					this.tableMessage = this.appConfigService.genericErrorMessage;
					this.boErrorHandlerService.handleError(error, undefined, 'Getting Sales Records');
				}
			});
	}

	exportToCSV(selectedDraws: TenantDraw[], isAllDataSelected: boolean): void {
		this.loading = true;

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedDraws, isAllDataSelected, ColumnNames.TENANT_ID.DB
		);

		this.getPastTenantDrawsCsvSub$ = this.drawProcService.getPastTenantDrawsCsv(this.gameDraw?.gameGroupID, exportHttpParams).subscribe(
			res => {
				if (res !== undefined) {
					// download file
					FileDownloadUtility.downloadCsv(res, `WinnersForDrawID_${this.gameDraw?.drawID} `);
				}

				this.loading = false;
			},
			error => {
				this.loading = false;

				this.boErrorHandlerService.handleError(error, undefined, 'Exporting to CSV');
			}
		);
	}

	onNavigateToPlayerEvent(dto: DrawWinningPlayer) {
		this.displayWinningPlayersDialog = false;
		this.router.navigate([NAV_SEGMENT.PLAYER_MG__PLAYERS, dto.playerID]);
	}
}
