<he-base-dialog [header]="'Winning Players'" [(visible)]="displayWinningPlayersDialog" [isLoadingLookups]="false"
				[style]="{'max-height': '80%', height: '900px', width: '100%'}"
				(visibleChange)="displayWinningPlayersDialog = false"
				[rejectLabel]="'Close'" [rejectBtnOnly]="true">
	<he-draw-winning-players *ngIf="displayWinningPlayersDialog" [drawID]="selectedDrawID"
							 [tenantID]="selectedTenantID"
	(navigateToPlayerEvent)="onNavigateToPlayerEvent($event)"></he-draw-winning-players>
</he-base-dialog>

<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data" [lazy]="true" [loading]="loading"
				  (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords" [tableActions]="tableActions"
				  [tableMessage]="tableMessage" [searchFields]="searchFields"></he-data-table-v3>
