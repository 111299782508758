import {BaseLookup} from '../../interfaces/lookup-interfaces';

export interface DialogOpts {
	display?: boolean;
	statusTitle?: string;
	lookupList: BaseLookup[];
	selectedStatus: number
}

export enum DialogEditorType {
	CREATE = 'create',
	EDIT = 'edit'
}
