<h3 id="notes-title">Notes</h3>

<div>
	<div *ngIf="loading; else newNoteEditor" class="editor-skeleton-container" style="margin-bottom: 36px; row-gap: 12px;">
		<p-card [style]="{'height': '100%', width: '100%'}" [styleClass]="'dynamic-skeleton'">
			<div class="skeleton-header">
				<div class="skeleton-title">
					<p-skeleton width="150px" height="20px"></p-skeleton>
				</div>
			</div>

			<p-skeleton class="editor-body" width="100%" height="100%"></p-skeleton>
		</p-card>

		<div class="skeleton-submit">
			<p-skeleton width="118px" height="30px"></p-skeleton>
		</div>
	</div>

	<ng-template #newNoteEditor>
		<form [formGroup]="formGroup" class="add-new-notes">
			<p-editor [style]="{ height: '120px' }" formControlName="newNote">
				<ng-template pTemplate="header">
					<span>New Note</span>
				</ng-template>
			</p-editor>

			<button pButton type="button" class="helio-orange-btn" icon="ui-icon-check"
					[disabled]="!formGroup.valid" label="Save Note"
					name="submitButton" title="Save Note" (click)="onSaveNote()"></button>
		</form>
	</ng-template>

	<div *ngIf="data?.length > 0" id="previous-notes-wrapper">
		<div *ngFor="let dto of data;">
			<div *ngIf="loading; else editorContent" class="editor-skeleton-container">
				<p-card [style]="{'height': '100%', width: '100%'}" [styleClass]="'dynamic-skeleton'">
					<div class="skeleton-header">
						<div class="skeleton-title">
							<p-skeleton width="150px" height="20px"></p-skeleton>
							<p-skeleton width="120px" height="20px"></p-skeleton>
						</div>

						<div class="skeleton-actions">
							<p-skeleton shape="circle" width="24px" height="24px"></p-skeleton>
							<p-skeleton shape="circle" width="24px" height="24px"></p-skeleton>
							<p-skeleton shape="circle" width="24px" height="24px"></p-skeleton>
						</div>
					</div>

					<p-skeleton class="editor-body" width="100%" height="100%"></p-skeleton>
				</p-card>
			</div>

			<ng-template #editorContent>
				<p-editor [style]="{ height: '120px' }" [(ngModel)]="dto.note"
						  [readonly]="dto.readonly" [disabled]="dto.readonly">
					<ng-template pTemplate="header">
						<div class="editor-header">
							<div class="header-info">
								<span class="email-text">{{ dto.author }}</span>
								<!--use the latest changed date-->
								<span class="date-text">{{ formatDate(dto) }}</span>
							</div>

							<div class="header-actions">
								<button  pButton [disabled]="!dto.readonly || !isOriginalCreator(dto)"
										type="button" icon="pi pi-pencil"
										(click)="onEditPreviousNoteClicked(dto)"></button>

								<button pButton type="button" icon="pi pi-trash" [disabled]="!isOriginalCreator(dto)"
										(click)="onDeletePreviousNote(dto)"></button>

								<button pButton type="button" icon="pi pi-history" [disabled]="!dto.updatedDate"
										(click)="onShowPreviousNoteHistory(dto)"></button>
							</div>
						</div>
					</ng-template>
				</p-editor>
			</ng-template>

			<!--adding !loading so that this is removed immediately when loading starts as when
			 fresh data is returned dto.readonly will be set to true-->
			<div *ngIf="!dto.readonly && !loading" class="previous-note-edit-action">
				<p-button icon="pi pi-times" (onClick)="onCancelEditPreviousNote(dto);"/>

				<p-button icon="pi pi-check" (onClick)="onEditPreviousNote(dto);"/>
			</div>
		</div>

		<div class="paginator-wrapper" >
			<p-paginator #paginator [style]="{'pointer-events': 'none'}"
						 [first]="first" [rows]="numOfItemsPerLoad" [totalRecords]="totalRecords"
						 (onPageChange)="onPageChange($event)" [showCurrentPageReport]="true"
						 currentPageReportTemplate="{last} of {totalRecords}" [showPageLinks]="false"
						 [showFirstLastIcon]="false">
			</p-paginator>

			<div class="custom-paginator">
				<button [disabled]="loading" pButton type="button" icon="pi pi-arrow-down" label="Load More"
						id="load-more" (click)="onLoadMoreNotes(paginator)"></button>
			</div>
		</div>
	</div>
</div>

<he-update-history-dialog *ngIf="historyRequest" [historyRequest]="historyRequest"
						  [visible]="!!historyRequest" (visibleChange)="historyRequest = null"
						  (closeEvent)="historyRequest = null;"></he-update-history-dialog>
