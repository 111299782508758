import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {ResponsiveContent} from '../../../interfaces/responsive-content';
import {WalletBalance, WalletTransaction} from '../../../models/finance/wallet-transaction.model';
import {ADJUST_ICON_TAG, LABEL_ADJUST_WALLET, ORDER_BY_URL_PARAM} from 'src/app/shared/constants/constants';
import {Subscription} from 'rxjs';
import {IndividualPlayerConstants} from '../constant/individual-player.constants';
import {AssignPspData, PaymentRoute} from '../../../models/finance/psps.model';
import {Player} from '../../../models/player/players.model';
import {BaseLookup} from '../../../interfaces/lookup-interfaces';
import {AppConfigService, BoErrorHandlerService, BreadcrumbService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AppGuard} from '../../../guards';
import {PlayersService} from '../../../services/players.service';
import {LookupService} from '../../../services/lookup.service';
import {ExportCSVUtility, FileDownloadUtility, ServiceAction, ServiceController} from '../../../utilities';
import {PlayerTransactionService} from '../../../services/player-transaction.service';
import {PaymentProviderService} from '../../../services/payment-provider.service';
import {WithdrawalsService} from '../../../services/withdrawals.service';
import * as ColumnNames from '../../../constants/ui-db-name-mappings';
import {ColumnType, DataTableLazyLoadEvent, SearchFieldType, TableDataResponse, ValidRows} from '../../data-table-v3';
import {HttpParams} from '@angular/common/http';
import {isDefinedArray} from '../../../utilities/general-utilities/arrays.utils';
import {ToastMessageType} from '../../../models';
import {MenuItem, SelectItem} from 'primeng/api';
import {EWalletProvider, TransferDetails} from '../../../models/finance/transfer-information.model';
import {TAKE_URL_PARAM} from '../../../constants';
import {TableState, TableStateService} from '../../../services/table-state.service';
import {TenantV2} from '../../../models/player/tenant.model';

@Component({
	selector: 'he-wallet-transaction',
	templateUrl: './wallet-transaction.component.html',
	styleUrls: ['./wallet-transaction.component.scss']
})
export class WalletTransactionComponent extends ResponsiveContent<WalletTransaction> {

	filterStorageName: keyof TableState;

	@Input() tabbedTitle = IndividualPlayerConstants.TAB_NAMES.walletTransactions;
	@Input() isTabbedTable = false; // Represents false == 'globalFinancePage' and true == 'tabbedIndividualPlayerPage'

	@Output() updateDashboard: EventEmitter<void> = new EventEmitter();

	walletTypeLookup: SelectItem[] = [];
	selectedTargetData: SelectItem;

	/**
	 * @deprecated
	 * @todo combine @HostBinding('attr.is-global') tag with {@link isTabbedTable}
	 */
	@HostBinding('attr.is-global') isStandAlone;

	btnLabel = Object.freeze({
		ADJUST: LABEL_ADJUST_WALLET,
		CARDS: 'Cards',
		TRANSFERS: 'Transfers',
		TRANSFER_DETAILS: 'Transfer Details',
		BANK_DETAILS: 'Bank Details',
		EXCHANGE_HOUSE_DETAILS: 'Exchange House Details',
		PSP: 'Assign PSP'
	});

	ADJUST_ICON_TAG = ADJUST_ICON_TAG;

	transferBtnMenuItems: MenuItem[] | undefined;

	showAdjustWalletDialog = false;
	showCardsDialog = false;
	showEWalletDialog = false;
	showTransferDetailsDialog = false;
	showBankDetailsDialog = false;
	showExchangeHouseDialog = false;
	showAssignPspDialog = false;

	eWalletLabel = ''
	selectedEWalletProvider?: EWalletProvider = null;

	selectedTransactions: WalletTransaction[] = [];

	routePlayerID: number;
	oldRoutePlayerID = 0;

	operator: TenantV2;

	walletBalance: WalletBalance;
	hasAdjustWalletBalPermission = false;

	playerPspRoute: PaymentRoute;
	playerPartialData: Player;
	pspLookup: BaseLookup[];

	bankDetails: TransferDetails;

	private routeSubs$: Subscription;
	private getWalletTransactionTypesSub$: Subscription;
	private getWalletTypesSub$: Subscription;
	// private getCurrenciesSub$: Subscription;
	private getTableDataSub$: Subscription;
	private getBalanceSubs$: Subscription;
	private getPaymentRoute$: Subscription;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private appGuard: AppGuard,
				private breadcrumbService: BreadcrumbService,
				private toastDisplayService: ToastDisplayService,
				private playersService: PlayersService,
				private lookupService: LookupService,
				private walletTransService: PlayerTransactionService,
				private withdrawalsService: WithdrawalsService,
				private paymentProviderService: PaymentProviderService,
				protected tableStateService: TableStateService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.filterStorageName = this.isTabbedTable ? 'walletTransactionTab' : 'walletTransaction';
		this.isStandAlone = !this.isTabbedTable;

		this.transferBtnMenuItems = [
			{
				icon: 'pi pi-dollar',
				label: 'Bank Transfers',
				command: () => {
					this.onTransfersBtnClicked(TransferType.BANK_TRANSFER);
				}
			},
			{
				icon: 'pi pi-home',
				label: 'Exchange House',
				command: () => {
					this.onTransfersBtnClicked(TransferType.EXCHANGE_HOUSE);
				}
			},
			{
				icon: 'pi pi-credit-card',
				label: 'Cards',
				command: () => {
					this.onTransfersBtnClicked(TransferType.CARDS);
				}
			}
		]

		// Simulate filtering the res of /api/Payments/89/PaymentMethods for where
		// paymentMethodTypeID == 3, i.e. e-wallet
		const eWalletProviders = [
			{paymentMethod: 'ZainCash', paymentMethodID: 5},
			{paymentMethod: 'FastPay', paymentMethodID: 8}
		]

		eWalletProviders.forEach(provider => {
			this.transferBtnMenuItems.push(
				{
					icon: 'pi pi-bolt',
					label: provider.paymentMethod,
					command: () => {
						this.onTransfersBtnClicked(TransferType.E_WALLET, provider);
					}
				}
			)
		})

		this.initDataTable();

		if (!this.isTabbedTable) {
			this.breadcrumbService.setItems([
				{label: 'Finance'},
				{label: this.tabbedTitle}
			]);
		}

		this.routeSubs$ = this.route.paramMap.subscribe((params: ParamMap) => {
			if (this.isTabbedTable) {
				this.routePlayerID = Number(params.get('id'));

				this.getTableDataForTab(this.routePlayerID);
			} else {
				this.getTableDataForGlobal();
			}
		});

		this.hasAdjustWalletBalPermission =
			this.appGuard.hasActionPermission(ServiceController.PLAYER_BALANCE, ServiceAction.ADJUST_WALLET_BALANCE);
	}

	ngAfterViewInit() {
	}

	@HostListener('window:beforeunload', ['$event'])
	onBrowserUnload($event?: any): void {
		this.persistTableFilters(true);
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.routeSubs$, this.getWalletTransactionTypesSub$, this.getWalletTypesSub$, this.getTableDataSub$,
			this.getBalanceSubs$, this.getPaymentRoute$
		);

		this.onBrowserUnload();
	}

	/**
	 * @param type The transfer type, which should satisfy one of {@link TransferType}
	 * @param provider should be supplied if of type {@link TransferType.E_WALLET}
	 * @private
	 */
	private onTransfersBtnClicked(type: TransferType, provider?: EWalletProvider) {
		// Enforce all transfer dialogs are hidden
		this.showCardsDialog = false
		this.showExchangeHouseDialog = false
		this.showBankDetailsDialog = false
		this.showEWalletDialog = false
		this.selectedEWalletProvider = null;
		this.eWalletLabel = ''

		if (type === TransferType.E_WALLET && !provider) {
			console.error('onTransfersBtnClicked: Cannot dynamically create an eWallet without provider opts.')
			return;
		}

		if (type === TransferType.BANK_TRANSFER) {
			this.showBankDetailsDialog = true;
		} else if (type === TransferType.EXCHANGE_HOUSE) {
			this.showExchangeHouseDialog = true;
		} else if (type === TransferType.CARDS) {
			this.showCardsDialog = true;
		} else if (type === TransferType.E_WALLET) {
			this.showEWalletDialog = true
			this.selectedEWalletProvider = provider
		} else {
			console.error('onTransfersBtnClicked: TransferType not handled.')
		}
	}

	initDataTable() {
		this.dataKey = ColumnNames.WALLET_TRANS_ID.DB;

		this.cols = [
			{
				field: ColumnNames.WALLET_TRANS_ID.DB,
				header: ColumnNames.WALLET_TRANS_ID.UI
			},
			{
				field: ColumnNames.ACTION_ID.DB,
				header: ColumnNames.ACTION_ID.UI
			}
		];

		if (!this.isTabbedTable) {
			this.cols.push({
				field: ColumnNames.PLAYER_ID.DB,
				header: ColumnNames.PLAYER_ID.UI
			});
		}

		this.cols.push(
			{
				field: ColumnNames.WALLET_TRANS_DATE.DB,
				header: ColumnNames.WALLET_TRANS_DATE.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.WALLET_TRANS_TYPE.DB,
				header: ColumnNames.WALLET_TRANS_TYPE.UI
			},
			{
				field: ColumnNames.WALLET_TYPE.DB,
				header: ColumnNames.WALLET_TYPE.UI
			},
			{
				field: ColumnNames.WALLET_INVOICE_CREDIT_NOTE.DB,
				header: ColumnNames.WALLET_INVOICE_CREDIT_NOTE.UI,
				sortable: true
			},
			{
				field: ColumnNames.CURRENCY_CODE.DB,
				header: ColumnNames.CURRENCY_CODE.UI
			},
			{
				field: ColumnNames.WALLET_VAT.DB,
				header: ColumnNames.WALLET_VAT.UI,
				localeDecimalPlaces: 2
			},
			{
				field: ColumnNames.WALLET_AMOUNT.DB,
				header: ColumnNames.WALLET_AMOUNT.UI,
				localeDecimalPlaces: 2
			},
			{
				field: ColumnNames.WALLET_BALANCE.DB,
				header: ColumnNames.WALLET_BALANCE.UI,
				localeDecimalPlaces: 2
			},
			{
				field: ColumnNames.WALLET_TOTAL_BALANCE.DB,
				header: ColumnNames.WALLET_TOTAL_BALANCE.UI,
				localeDecimalPlaces: 2
			},
			/*
			// Remove requested by Brian on 02/05/24
			{
				field: ColumnNames.WALLET_BAL_BEFORE.DB,
				header: ColumnNames.WALLET_BAL_BEFORE.UI
			},
			{
				field: ColumnNames.WALLET_BAL_AFTER.DB,
				header: ColumnNames.WALLET_BAL_AFTER.UI
			},*/
			{
				field: ColumnNames.COMMENT.DB,
				header: ColumnNames.COMMENT.UI,
				sortable: false
			}
		);

		this.tableActions = [
			{
				menuItem: {
					label: 'Export to CSV',
					icon: 'ui-icon-insert-drive-file'
				},
				callback: (callbackObj) => {
					this.exportToCSV(callbackObj.data, callbackObj.isAllDataSelected);
				},
				isRowAction: false // implies isBulkAction
			}
		];

		this.searchFields = []

		this.searchFields.push(
			{
				property: ColumnNames.WALLET_TRANS_ID.DB,
				label: ColumnNames.WALLET_TRANS_ID.UI,
				type: SearchFieldType.Id
			},
			{
				property: ColumnNames.ACTION_ID.DB,
				label: ColumnNames.ACTION_ID.UI,
				type: SearchFieldType.Id
			}
		);

		if (!this.isTabbedTable) {
			this.searchFields.push({
				property: ColumnNames.PLAYER_ID.DB,
				label: ColumnNames.PLAYER_ID.UI,
				type: SearchFieldType.Id
			});
		}

		// NB: ColumnNames.ACTION_ID is declared above!!
		this.searchFields.push(
			{
				property: ColumnNames.WALLET_TRANS_DATE.DB,
				label: ColumnNames.WALLET_TRANS_DATE.UI,
				type: SearchFieldType.Date,
				isRange: true
			},
			{
				property: ColumnNames.WALLET_TRANS_TYPE_ID.DB,
				label: ColumnNames.WALLET_TRANS_TYPE_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.transTypeLookups,
				isArraySearch: false
			},
			{
				property: ColumnNames.WALLET_TYPE_ID.DB,
				label: ColumnNames.WALLET_TYPE_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.walletTypeLookups,
				isArraySearch: false
			},
			{
				property: ColumnNames.CURRENCY_ID.DB,
				label: ColumnNames.CURRENCY_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.transTypeLookups,
				isArraySearch: false
			},
			{
				property: ColumnNames.WALLET_VAT.DB,
				label: ColumnNames.WALLET_VAT.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.WALLET_INVOICE_CREDIT_NOTE.DB,
				label: ColumnNames.WALLET_INVOICE_CREDIT_NOTE.UI,
				type: SearchFieldType.StringDefault
			},
			// Removing as these are not currently columns
			/*{
				property: ColumnNames.WALLET_INVOICE_NOTE.DB,
				label: ColumnNames.WALLET_INVOICE_NOTE.UI,
				type: SearchFieldType.StringDefault
			},
			{
				property: ColumnNames.WALLET_CREDIT_NOTE.DB,
				label: ColumnNames.WALLET_CREDIT_NOTE.UI,
				type: SearchFieldType.StringDefault
			},*/
			{
				property: ColumnNames.WALLET_AMOUNT.DB,
				label: ColumnNames.WALLET_AMOUNT.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.WALLET_BALANCE.DB,
				label: ColumnNames.WALLET_BALANCE.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.WALLET_TOTAL_BALANCE.DB,
				label: ColumnNames.WALLET_TOTAL_BALANCE.UI,
				type: SearchFieldType.Number
			}
		);

		this.fetchLookups();
	}

	getTableData(filterData?: DataTableLazyLoadEvent) {
		super.getTableData(filterData);

		const stateFilter = this.tableStateService.getTableFilters(this.filterStorageName);

		this.params = this.computeTableDataHttpParams(
			filterData, undefined, stateFilter?.rowsPerPage, stateFilter?.orderBy);
		this.persistTableFilters();

		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTransactions(this.params);
	}

	getTableDataForTab(playerID: number): void {
		this.loading = true;
		this.operator = null;

		this.playersService.getOperator(playerID).subscribe({
			next: operator => {
				this.operator = operator;

				this.fetchCriticalLookup(this.operator).then(() => {
					if (!this.walletTypeLookup || this.walletTypeLookup.length < 1) {
						this.boErrorHandlerService.handleError(
							undefined, 'Target table lookup init to undefined or < 1');
					}

					this.getTableData();
				}).catch(catchErr => {
					this.loading = false;
					this.boErrorHandlerService.handleError(catchErr);
				});
			},
			error: err => {
				this.loading = false;
				this.boErrorHandlerService.handleError(err);
			}
		});
	}

	getTableDataForGlobal(): void {
		this.loading = true;

		this.fetchCriticalLookup(this.operator).then(() => {
			if (!this.walletTypeLookup || this.walletTypeLookup.length < 1) {
				this.boErrorHandlerService.handleError(
					undefined, 'Target table lookup init to undefined or < 1');
			}

			this.getTableData();
		}).catch(catchErr => {
			this.loading = false;
			this.boErrorHandlerService.handleError(catchErr);
		});
	}

	private fetchCriticalLookup(operator: TenantV2): Promise<void> {
		if (this.walletTypeLookup && (this.walletTypeLookup.length > 0)) {
			// Resolve immediately if already fetched so that API is not called again during ops like AdvancedSearch.
			//  If len is less than 1 then an attempt should be made again to fetch the lookup
			return Promise.resolve();
		}

		return new Promise<void>((resolve, reject) => {
			this.lookupService.getWalletTypes(operator?.tenantID).subscribe({
				next: res => {
					this.walletTypeLookup = res;
					this.selectedTargetData = this.walletTypeLookup[0];
					this.reassignLookupOpts(res, ColumnNames.WALLET_TYPE_ID.DB);
					resolve();
				},
				error: error => {
					reject(error)
				}
			});
		})
	}

	getTransactions(params: HttpParams) {
		// #forkJoin is not applicable here as when err, thrown for non-supported PSP playerID, also results in table failing.
		//  Could perhaps use #concat, but this approach also works!
		const observable: any = this.isTabbedTable ?
			this.walletTransService.getWalletTransactions(this.routePlayerID, params, this.selectedTargetData.value) :
			this.walletTransService.getWalletTransactions(undefined, params, this.selectedTargetData.value);

		this.getTableDataSub$ = observable.subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;
				this.offset = res.offset;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}

				// Retrieve the tenantID for use with #getPaymentProvidersLookup
				if (this.oldRoutePlayerID !== this.routePlayerID && this.isTabbedTable) {

					this.playerPspRoute = null;
					this.oldRoutePlayerID = this.routePlayerID; // to prevent triggering this section for this player again

					this.playersService.getPlayer(this.routePlayerID).subscribe({
						next: (getPlayerRes) => {
							this.playerPartialData = (getPlayerRes as TableDataResponse<Player>).resultSet[0];
							this.loading = false;
						},
						error: innerErr => {
							this.boErrorHandlerService.handleError(innerErr);
							this.loading = false;
						}
					});
				} else {
					this.loading = false;
				}
			},
			error: error => {
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError( // TODO - Re-expose once PlayerTrail error fixed
					error, error.description, 'walletTransService.getTransactions');
				this.loading = false;
			}
		});
	}

	private getPaymentRoute(paymentMethodID: number, onResSuccess?: (obj: PaymentRoute) => void) {
		this.loading = true;

		this.paymentProviderService.getPaymentRoute({playerID: this.routePlayerID, paymentMethodID: paymentMethodID})
			.subscribe({
				next: (routeRes: PaymentRoute) => {
					this.playerPspRoute = routeRes as PaymentRoute;

					if (onResSuccess) {
						onResSuccess(this.playerPspRoute);
					}

					this.loading = false;
				},
				error: err => {
					if (err[0]?.description === 'Payment route not yet assigned to player') {
						this.playerPspRoute = null; // Null, rather than, undefined, is used to flag that no results are present
					} else {
						// this.boErrorHandlerService.handleError(err); TODO - Re-expose once PlayerTrail error fixed
					}

					this.loading = false;
				}
			});
	}

	onShowAdjustWalletDialog() {
		// Since this dialog is not directly invoked via the table, show the dialog immediately when clicked and
		//  handle loading UI on the dialog itself if walletBalance is not yet defined.
		this.showAdjustWalletDialog = true;

		let params: HttpParams;
		params = this.params.set('$take', '1')
			.set('$offset', '0');

		this.getBalanceSubs$ = this.playersService.getBalance(this.routePlayerID, params).subscribe({
			next: res => {
				this.walletBalance = res;
			},
			error: error => {
				this.tableMessage = this.appConfigService.genericErrorMessage +
					'. Failed to retrieve player balance, some features may be unavailable.';
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	reassignLookupOpts = (res: any[], prop) => {
		if (isDefinedArray(res)) {
			// this.transTypeLookups = res;

			// Update the previously added transType object by reference
			const temp = this.searchFields.find(value => {
				return value.property === prop;
			});
			temp.listOptions = res;
		}
	}

	fetchLookups() {
		this.releaseSubscriptions(this.getWalletTransactionTypesSub$, this.getWalletTypesSub$);

		// get operators
		this.getWalletTransactionTypesSub$ = this.lookupService.getPlayerTransactionTypes().subscribe(
			{
				next: (res) => {
					this.reassignLookupOpts(res, ColumnNames.WALLET_TRANS_TYPE_ID.DB);
				},
				error: error => {
					this.boErrorHandlerService.handleError(error);
				}
			});

		// get currencies
		this.lookupService.getPlayerNomenclature('Currencies').then(res => {
			this.reassignLookupOpts(res.result.currencies, ColumnNames.CURRENCY_ID.DB);
		}).catch(error => {
			this.boErrorHandlerService.handleError(error);
		});
	}

	onAdjustWalletDialogClosed() {
		this.showAdjustWalletDialog = false

		if (this.isTabbedTable) {
			this.updateDashboard.emit();
		}

		this.getTransactions(this.params)
	}

	onAssignPspRequest(data: AssignPspData) {
		this.loading = true;

		this.paymentProviderService.updatePlayerPaymentRoutes(this.routePlayerID, data).subscribe({
			next: () => {
				const onPaymentRouteSuccessConsumer = (obj: PaymentRoute) => {
					this.toastDisplayService.addMessage({
						type: ToastMessageType.success,
						title: 'Success',
						description: `${obj.thirdPartyName} assigned as player PSP.`
					});
				}

				this.getPaymentRoute(data.paymentMethodID, onPaymentRouteSuccessConsumer);
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	onShowTransferDetailsDialog() {
		this.loading = true;

		this.withdrawalsService.getBankDetailsByPlayer(this.routePlayerID).subscribe({
			next: bankDetails => {
				this.loading = false;
				this.bankDetails = bankDetails;
				this.showTransferDetailsDialog = true;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	onUpdateBankDetails(data: TransferDetails) {
		this.showTransferDetailsDialog = false;
		this.loading = true;

		this.withdrawalsService.updateBankDetails(data).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `Bank details updated for player ${this.routePlayerID}.`
				});

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	onSelectedWalletTypeChange() {
		this.getTableData();
	}

	get tableFilters() {
		return this.tableStateService.getTableFilters(this.filterStorageName);
	}

	protected persistTableFilters(setOnlyInStorage = false) {
		this.tableStateService.setTableFilters(this.filterStorageName, {
			orderBy: this.params?.get(ORDER_BY_URL_PARAM),
			rowsPerPage: Number(this.params?.get(TAKE_URL_PARAM)) as ValidRows
		}, setOnlyInStorage);
	}

	exportToCSV(selectedData?: WalletTransaction[], isAllDataSelected?: boolean) {
		super.exportToCSV(selectedData, isAllDataSelected);

		this.loading = true;

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedData, isAllDataSelected, ColumnNames.WALLET_TRANS_ID.DB
		);

		// Removing as these are not currently columns
		/*
		// Add single-column, ui-parsed invoice and credit notes - which does not exist in BE - separately for CSV call
		const csvCols = deepCopy(this.cols);

		const indexOfUiField = csvCols.findIndex(entry => entry.field === ColumnNames.WALLET_INVOICE_CREDIT_NOTE.DB);

		csvCols.splice(indexOfUiField, 1, ...[
			{
				field: ColumnNames.WALLET_INVOICE_NOTE.DB,
				header: ColumnNames.WALLET_INVOICE_NOTE.UI
			},
			{
				field: ColumnNames.WALLET_CREDIT_NOTE.DB,
				header: ColumnNames.WALLET_CREDIT_NOTE.UI
			},
		]);*/

		this.walletTransService.getWalletTransactionsCsv(this.routePlayerID, exportHttpParams, this.cols).subscribe({
			next: (res: string) => {
				if (res !== undefined) {
					// download file
					FileDownloadUtility.downloadCsv(
						res,
						this.routePlayerID ? `WalletTransactionsForPlayer_${this.routePlayerID}` : 'WalletTransactionForPlayers'
					);
				}

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

}

/**
 * NB: Avoid using these enums to display UI labels as they are subject to change
 */
export enum TransferType {
	BANK_TRANSFER = 'BankTransfer',
	EXCHANGE_HOUSE = 'ExchangeHouse',
	CARDS = 'Cards',
	E_WALLET = 'eWallet'
}
