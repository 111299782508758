export const noGameLotteryImageURL = '../../../assets/lottery-logos/no-game-icon.svg';

export const lotteryImageMapping = [
	{
		groupCode: 'lottohero',
		url: '../../../assets/lottery-logos/lotto-hero.svg'
	},
	{
		groupCode: 'lottohero-secondchance',
		url: '../../../assets/lottery-logos/lotto-hero.svg'
	},
	{
		groupCode: 'lottohero-redblack',
		url: '../../../assets/lottery-logos/lotto-hero.svg'
	},
	{
		groupCode: 'lottohero-5minute',
		url: '../../../assets/lottery-logos/lotto-hero.svg'
	},
	{
		groupCode: 'lottohero-5minute-secondchance',
		url: '../../../assets/lottery-logos/lotto-hero.svg'
	},
	{
		groupCode: 'lottohero-5minute-redblack',
		url: '../../../assets/lottery-logos/lotto-hero.svg'
	},
	{
		groupCode: 'breakthru',
		url: '../../../assets/lottery-logos/breakthru-logo.png'
	},
	{
		groupCode: 'breakthru-5minute',
		url: '../../../assets/lottery-logos/breakthru-logo.png'
	},
	{
		groupCode: 'lottojoto-10minute',
		url: '../../../assets/lottery-logos/lotto-joto.png'
	},
	{
		groupCode: 'custom004-hourly-lottery',
		url: '../../../assets/lottery-logos/lotto-joto.png'
	},
	{
		groupCode: 'lottojoto',
		url: '../../../assets/lottery-logos/lotto-joto.png'
	},
	{
		groupCode: 'lottorush',
		url: '../../../assets/lottery-logos/lotto-rush.svg'
	},
	{
		groupCode: 'thebighero',
		url: '../../../assets/lottery-logos/big-hero.svg'
	},
	{
		groupCode: 'moonify',
		url: '../../../assets/lottery-logos/free-loto.svg'
	},
	{
		groupCode: 'thedailyhero',
		url: '../../../assets/lottery-logos/daily-hero.svg'
	},
	{
		groupCode: 'heromillions',
		url: '../../../assets/lottery-logos/hero-millions.svg'
	},
	{
		groupCode: 'powerball',
		url: '../../../assets/lottery-logos/power-ball.png'
	},
	{
		groupCode: 'megamillions',
		url: '../../../assets/lottery-logos/mega-millions.png'
	},
	{
		groupCode: 'lotto-6aus49',
		url: '../../../assets/lottery-logos/lotto-6-49.png'
	},
	{
		groupCode: 'euromillions',
		url: '../../../assets/lottery-logos/euro-millions.png'
	},
	{
		groupCode: 'eurojackpot',
		url: '../../../assets/lottery-logos/euro-jackpot.png'
	},
	{
		groupCode: 'custom001-gharoontreasure',
		url: '../../../assets/lottery-logos/gharoon-treasure.png'
	},
	{
		groupCode: 'live-6-36',
		url: '../../../assets/lottery-logos/live-6-36.svg'
	},
	{
		groupCode: 'mega-sena',
		url: '../../../assets/lottery-logos/mega-sena.png'
	}
];
