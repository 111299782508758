<he-base-dialog [visible]="visible"
				[header]="SINGLE_MODIFY_BAL_LABEL + ' ' + selectedAgent.username" [isLoadingLookups]="loading"
				[style]="{'max-width': '700px', width: '550px'}" (closeEvent)="onClose()"
				[submitLabel]="'Adjust'" (submitEvent)="onAdjustAgentBalance()"
				[disableSubmit]="!amountToAdjust || amountToAdjust < 0"
				skeletonInputCount="2" [confirmDialogMsg]="'Are you sure you want to adjust balance?'">
	<form class="dialog-form-placement">
		<div class="dialog-label">Current Balance:</div>
		<!--Pad with 2px to match that of p-inputNumber-->
		<div id="current-bal-value" class="he-disabled">
			{{ currencySymbol + ' ' + (selectedAgent.balance | number:'1.0-2') }}
		</div>

		<div class="dialog-label">New Balance:</div>
		<p-inputNumber placeholder="Amount" [(ngModel)]="amountToAdjust" mode="decimal" [minFractionDigits]="1"
					   [prefix]="currencySymbol + ' '" min="0" max="100000000000">
		</p-inputNumber>
	</form>
</he-base-dialog>
