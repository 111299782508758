/**
 * Class is a repository for the 'controller' part of the URLs to be generated when making HTTP requests
 */
export class ServiceController {
	public static GAME = 'api/game/';
	public static LOGIN = 'oauth2/token';
	public static LOGGING = 'api/log/';
	public static USERS = 'api/Users/';
	public static USER_GROUPS = 'api/UserGroup/';
	public static TENANTS = 'api/Tenants/';
	public static NOMENCLATURES = 'api/Nomenclature/';
	public static NOMENCLATURES_CENTRAL = 'api/CentralNomenclatures/';
	public static NOMENCLATURES_TENANT = 'api/TenantNomenclatures/';
	public static MULTILINGUAL = 'api/MultilingualReference/';
	public static PLAYER = 'api/Player/';
	public static PLAYER_FULL = 'api/PlayerFull/';
	public static PLAYER_BALANCE = 'api/PlayerBalance/';
	public static KYC = 'api/KYC/';
	public static PAYMENT_MANEGEMENT = 'api/PaymentPlanManagement/';
	public static REPORTS = 'api/Reports/';
	public static AUDIT = 'api/Audit/';
	public static GAME_ADMINISTRATION = 'api/GameAdministration/';
	public static FINANCIAL = 'api/financial/';
	public static PAGE_PERMISSIONS = 'api/pagepermissions/';
	public static ACTION_PERMISSIONS = 'api/actionpermissions/';
	public static BANK_ACCOUNT = 'api/BankAccounts/';
	public static BANK = 'api/Banks/';
	public static BANK_STATEMENT = 'api/BankStatements/';
	public static BANK_STATEMENT_TRANSACTIONS = 'api/BankStatementTransactions/';
	public static BANK_NOMINAL_MAPPING = 'api/NominalMappings/';
	public static WINNINGS_PARTICIPATIONS = 'api/Winnings/';
	public static POWER_BI = 'api/Reports/PowerBI/EmbedToken';
	public static COMPANY = 'api/Companies/';
	public static DRAW = 'api/Draw/';
	public static TICKET_BATCH = 'api/TicketBatch/';
	public static SUBSCRIPTIONS = 'api/Subscriptions/';
	public static SUBSCRIPTION_PLANS = 'api/SubscriptionPlans/';
	public static RETAIL_AGENT = 'api/retailagents/';
	public static USER_OWN = 'api/UserOwn/';
	public static RESTRICTIONS = 'api/Restrictions/';
	public static WALLET_TRANSACTION = ServiceController.PLAYER_BALANCE; // 'api/WalletTransaction/';
	public static PAYMENTS = 'api/Payments/';
	public static PAYMENT_ACCOUNTS = 'api/PaymentAccounts/';
	public static WITHDRAWALS = 'api/Withdrawals/';
	public static PURCHASES = 'api/Purchases/';
	public static FREE_TICKETS = 'api/FreeTickets/';
	public static TOP_UP_CARDS = 'api/PrepaidCards/';
	public static PLAYER_AUDIT = 'api/ChangeTrails/';
	public static PLAYER_NOTES = 'api/PlayerNotes/';
	public static PLAYER_MESSAGES = 'api/Messages/';
}
