import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
	ApproveManualResultsData,
	ApproveManualResultsRequest,
	ChangeDrawDateData,
	ChangeDrawDateRequest,
	DrawWinningPlayer,
	DropDrawData,
	DropDrawRequest,
	ManualInsertResultsRequest
} from '../models';
import {AppConfigService} from '../../../helio-core-services/services/app-config.service';
import {
	ActiveDraw,
	Draw,
	DrawSales,
	DrawWinStats,
	PreviousDraw,
	PreviousDrawOverview,
	UpcomingDrawOverview
} from '../../../shared/models/draws/draw.model';
import {ServiceController} from '../../../shared/utilities/service-utilities/service-controller.urls';
import {ServiceAction} from '../../../shared/utilities/service-utilities/service-action.urls';
import {TableDataResponse} from '../../../shared/components/data-table-v3/shared/data-table-server-response.model';
import {ResponseBodyType} from '../../../shared/enums/response-body-type.enum';
import {GameFilter} from '../enums';
import {map} from 'rxjs/operators';
import {FormatParams} from '../../../shared';
import {GameFormat, LotteryResult} from '../../manual-draw-entry-dialog';
import {lotteryImageMapping, noGameLotteryImageURL} from '../lottery-image-mapping';
import {PublishDrawResultsDTO} from '../../../shared/models/games/publish-draw-results.model';
import {BaseServiceImpl} from '../../../shared/services/base-service-impl';

@Injectable()
export class DrawProcessingService extends BaseServiceImpl {
	private upcomingDrawsLastRetrieved: Draw[] = [];
	private pastDrawsLastRetrieved: Draw[] = [];

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.DRAW, appConfigService.serviceBaseURL);
	}

	getDrawByID(drawID: number): Observable<Draw> {
		return this.formatDrawHelper(
			this.get(ServiceAction.DRAW_GET_BY_ID, [drawID])
		);
	}

	/**
	 * Returns the latest previous Draw for all games - i.e. one entry per game.
	 */
	getAllLatestPreviousDraws(searchParams?: HttpParams): Observable<TableDataResponse<Draw>> {
		return this.formatDataTableDrawHelper(
			this.validateDataTableRes(
				['gameGroupID', 'gameGroupName'],
				ServiceAction.DRAW_GET_PREVIOUS_DRAWS,
				undefined, searchParams
			)
		);
	}

	getAllLatestPreviousDrawsCsv(searchParams?: HttpParams): Observable<string> {
		return this.getCsv(ServiceAction.DRAW_GET_PREVIOUS_DRAWS, undefined, searchParams, ResponseBodyType.Text);
	}

	/**
	 * Returns the latest previous Draw for a particular game.
	 */
	getLatestPreviousDrawForGame(gameGroupID: number, searchParams?: HttpParams): Observable<PreviousDraw> {
		// Validating format and expected len as API is not returning a singly outer object
		return this.validateDataTableRes(
			['gameGroupID', 'gameGroupName'],
			ServiceAction.DRAW_GET_PREVIOUS_DRAWS,
			[gameGroupID], searchParams
		).pipe(map((res: TableDataResponse<PreviousDraw>) => {
			const lastPreviousDraws = res.resultSet;
			const draw = (lastPreviousDraws.length === 1) ? lastPreviousDraws[0] as PreviousDraw : null;

			if (draw) {
				draw.drawDate = new Date(draw.drawDate);
			}

			return draw as PreviousDraw;
		}));
	}

	getAllPreviousDrawsForGame(gameGroupID: number, searchParams?: HttpParams): Observable<TableDataResponse<PreviousDrawOverview>> {
		return this.validateDataTableRes([], ServiceAction.DRAW_GET_PREVIOUS_DRAWS_FOR_GAME, [gameGroupID], searchParams)
			.pipe(
				map(res => {
					res.resultSet = res.resultSet.map((entry: any) => {
						// Parse Date object
						entry.drawDate = new Date(entry.drawDate);

						return entry;
					});

					return res as TableDataResponse<PreviousDrawOverview>;
				}));
	}

	getAllPreviousDrawsForGameCsv(gameGroupID: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const tempParams = searchParams.set('format', 'csv');

		return this.getCsv(ServiceAction.DRAW_GET_PREVIOUS_DRAWS_FOR_GAME, [gameGroupID], tempParams,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	/**
	 * * Get Upcoming Draw for each Game (Draw) (grouped for all tenants)
	 */
	getAllUpcomingDraws(searchParams?: HttpParams, gameFilter: GameFilter = GameFilter.ALL): Observable<TableDataResponse<Draw>> {
		let params: HttpParams = searchParams ?? new HttpParams();
		params = searchParams.set('gameFilter', gameFilter);

		return this.formatDataTableDrawHelper(
			this.validateDataTableRes([], ServiceAction.DRAW_GET_UPCOMING_DRAWS, undefined, params)
		);
	}

	getUpcomingDrawsCsv(searchParams?: HttpParams): Observable<string> {
		return this.getCsv(ServiceAction.DRAW_GET_UPCOMING_DRAWS, undefined, searchParams, ResponseBodyType.Text);
	}

	/**
	 * * Get Upcoming Draws for ONE Game (Draw) (grouped for all tenants)
	 */
	getUpcomingDrawForGame(gameGroupID: number): Observable<UpcomingDrawOverview> {
		return this.get(ServiceAction.DRAW_GET_UPCOMING_DRAWS_FOR_GAME, [gameGroupID], undefined).pipe(
			map(res => {
				res.drawDate = new Date(res.drawDate);
				return res as UpcomingDrawOverview;
			}));
	}

	getActiveDrawsForGame(gameGroupID: number, searchParams?: HttpParams): Observable<TableDataResponse<ActiveDraw>> {
		return this.validateDataTableRes([], ServiceAction.DRAW_GET_ACTIVE_DRAWS_FOR_GAME, [gameGroupID], searchParams)
			.pipe(
				map(res => {
					res.resultSet = res.resultSet.map((entry: any) => {
						// Parse Date object
						entry.drawDate = new Date(entry.drawDate);

						return entry;
					});

					return res as TableDataResponse<ActiveDraw>;
				}));
	}

	getActiveDrawsForGameCsv(gameGroupID: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const tempParams = searchParams.set('format', 'csv');

		return this.getCsv(ServiceAction.DRAW_GET_ACTIVE_DRAWS_FOR_GAME, [gameGroupID], tempParams,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	/**
	 * Get Upcoming Draw for ALL TENANTS
	 */
	getActiveDrawsByTenant(drawID: number, searchParams?: HttpParams): Observable<TableDataResponse<DrawSales>> {
		return this.validateDataTableRes([], ServiceAction.DRAW_GET_SALES_TOTAL_BY_TENANT, [drawID], searchParams).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: any) => {
					// Parse Date object
					entry.drawDate = new Date(entry.drawDate);

					return entry;
				});

				return res as TableDataResponse<DrawSales>;
			}));
	}

	getPastDrawsByTenant(drawID: number, searchParams?: HttpParams): Observable<TableDataResponse<DrawWinStats>> {
		return this.validateDataTableRes([], ServiceAction.DRAW_GET_WINNING_STATS_BY_TENANT, [drawID], searchParams);
	}

	getActiveTenantDrawsCsv(gameGroupID: number, searchParams?: HttpParams): Observable<string> {
		return this.getCsv(ServiceAction.DRAW_GET_FUTURE_TENANT_DRAWS, [gameGroupID], searchParams, ResponseBodyType.Text);
	}

	getDrawSalesTotalByTenant(gameGroupID: number, searchParams?: HttpParams): Observable<TableDataResponse<DrawSales>> {
		return this.validateDataTableRes([], ServiceAction.DRAW_GET_SALES_TOTAL_BY_TENANT, [gameGroupID], searchParams).pipe(map(res => {
			res.resultSet = res.resultSet.map((entry: DrawSales) => {
				const draw: DrawSales = entry;
				draw.drawDate = new Date(entry.drawDate);

				return draw;
			})

			return res as TableDataResponse<DrawSales>;
		}));
	}

	getDrawWinningStatsByTenant(drawID: number, searchParams?: HttpParams): Observable<TableDataResponse<DrawWinStats>> {
		return this.validateDataTableRes([], ServiceAction.DRAW_GET_WINNING_STATS_BY_TENANT, [drawID], searchParams);
	}

	/**
	 * All data queries used for no nesting when only 1 tenant i.e. using old query
	 */
	getPastTenantDraws(gameGroupID: number, searchParams?: HttpParams): Observable<TableDataResponse<Draw>> {
		return this.formatDataTableDrawHelper(
			this.get(ServiceAction.DRAW_GET_PAST_TENANT_DRAWS, [gameGroupID], searchParams)
		);
	}

	/**
	 * CSV Exports for outer nested table exports all data i.e. using old query
	 */
	getPastTenantDrawsCsv(gameGroupID: number, searchParams?: HttpParams): Observable<string> {
		return this.getCsv(ServiceAction.DRAW_GET_PAST_TENANT_DRAWS, [gameGroupID], searchParams, ResponseBodyType.Text);
	}

	/**
	 * OTHER ACTIONS NEEDED FOR THIS MODULE
	 */

	getDrawWinningPlayers(drawID: number, tenantID: number, searchParams?: HttpParams): Observable<TableDataResponse<DrawWinningPlayer>> {
		return this.validateDataTableRes(
			['totalWinAmount', 'prizeCurrencyCode', 'playerID'], ServiceAction.DRAW_GET_WINNING_PLAYERS,
			[drawID, tenantID], searchParams
		);
	}

	getDrawWinningPlayersCsv(drawID: number, tenantID: number, searchParams?: HttpParams): Observable<string> {
		return this.getCsv(ServiceAction.DRAW_GET_WINNING_PLAYERS, [drawID, tenantID], searchParams, ResponseBodyType.Text);
	}

	getPublishableDraws(drawID: number): Observable<PublishDrawResultsDTO> {
		/*return of(getPublishableDrawsDummyData).pipe(delay(200))*/

		return this.get(
			undefined, [drawID, ServiceAction.DRAW_GET_PUBLISHABLE_DRAWS], undefined).pipe(
			map((res: PublishDrawResultsDTO) => {
				res.drawDate = new Date(res.drawDate);
				res.gameGroupImageUrl = this.getDrawImageUrl(res.gameGroupName);

				if (res.subDraws) {
					res.subDraws = res.subDraws.map((entry: PublishDrawResultsDTO) => {
						// Parse Date object
						entry.drawDate = new Date(entry.drawDate);
						entry.gameGroupImageUrl = this.getDrawImageUrl(entry.gameGroupName);

						return entry;
					});
				}

				return res as PublishDrawResultsDTO;
			}));
	}

	publishDrawResults(drawIDs: number[]): Observable<any> {
		return this.edit(ServiceAction.DRAW_PUBLISH_RESULT, {drawIDs: drawIDs}, null);
	}

	getManuallyInsertedResults(drawID: number): Observable<LotteryResult[]> {
		return this.get(ServiceAction.DRAW_GET_INSERTED_RES, [drawID]).pipe(
			map(res => {
				return res.lotteryResult;
			})
		);
	}

	/**
	 * Returns the board information for a particular game group
	 */
	getBoardInfo(drawID: number): Observable<GameFormat> {
		return this.get(ServiceAction.DRAW_GET_BOARD_INFO, [drawID]);
	}

	cancelDraw(data: DropDrawData) {
		// TODO - Temporarily add redundant field, DrawStatus, for now - until BE removes from API
		const tempData: any = data as DropDrawRequest;
		tempData.DrawStatus = 'Cancelled';

		return this.post(ServiceAction.DRAW_CANCEL, tempData, null, [String(data.drawID)]);
	}

	voidDraw(data: DropDrawData) {
		// TODO - Temporarily add redundant field, DrawStatus, for now - until BE removes from API
		const tempData: any = data as DropDrawRequest;
		tempData.DrawStatus = 'Voided';

		return this.post(ServiceAction.DRAW_VOID, tempData, null, [String(data.drawID)]);
	}

	changeDrawDate(data: ChangeDrawDateData) {
		return this.post(ServiceAction.DRAW_CHANGE_DATE, (data as ChangeDrawDateRequest), null, [String(data.drawID)]);
	}

	insertManualResultsForDraw(drawID: number, data: ManualInsertResultsRequest) {
		return this.post(
			ServiceAction.DRAW_INSERT_MANUAL_RES, data as ManualInsertResultsRequest, null, [drawID]);
	}

	approveManualResultsForDraw(data: ApproveManualResultsData) {
		return this.post(
			ServiceAction.DRAW_APPROVE_MANUAL_RES, data as ApproveManualResultsRequest, null, [String(data.drawID)]);
	}

	private getDrawImageUrl(draw: Draw | string): string {
		const gameGroupCode = (typeof draw === 'string') ? draw : draw?.gameGroupCode;

		if (lotteryImageMapping.find(x => x.groupCode === gameGroupCode) !== undefined) {
			return lotteryImageMapping.find(x => x.groupCode === gameGroupCode).url;
		}

		return noGameLotteryImageURL;
	}

	private formatDrawHelper(o: Observable<any>): Observable<Draw> {
		// Convert string date to Date.js before passing on to callers
		return o.pipe(map(res => {
			res.drawDate = new Date(res.drawDate);
			res.gameGroupImageUrl = this.getDrawImageUrl(res);

			return res as Draw;
		}));
	}

	private formatDataTableDrawHelper(o: Observable<any>): Observable<TableDataResponse<Draw>> {
		// Convert all string dates in res.resultSet to Date.js before passing on to callers
		return o.pipe(map(res => {
			res.resultSet = res.resultSet.map(entry => {
				const draw: Draw = entry;
				draw.drawDate = new Date(entry.drawDate);
				draw.gameGroupImageUrl = this.getDrawImageUrl(draw);

				return draw;
			})

			return res;
		}));
	}
}
