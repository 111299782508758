import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DrawProcessingService, DrawWinningPlayer} from '../shared';
import {
	ColumnType, DataTableLazyLoadEvent, ExportCSVUtility, FileDownloadUtility
} from '../../shared';
import {HttpParams} from '@angular/common/http';
import {SearchFieldType} from '../../shared/components/data-table-v3';
import {Router} from '@angular/router';
import {AppConfigService, BoErrorHandlerService} from '../../helio-core-services';
import {CommonTable} from '../../shared/interfaces/common-table';
import {Subscription} from 'rxjs';

@Component({
	selector: 'he-draw-winning-players',
	styleUrls: ['./draw-winning-players.component.scss'],
	templateUrl: './draw-winning-players.component.html'
})
export class DrawWinningPlayersComponent extends CommonTable<DrawWinningPlayer> implements OnInit, OnDestroy {
	@Input() drawID: number;
	@Input() tenantID: number;

	@Output() navigateToPlayerEvent = new EventEmitter<DrawWinningPlayer>();

	selectedWinningPlayers: DrawWinningPlayer[] = [];

	private getDrawWinningPlayersSub$: Subscription;

	constructor(
		private drawProcService: DrawProcessingService,
		protected appConfigService: AppConfigService,
		protected boErrorHandlerService: BoErrorHandlerService
	) {
		super(appConfigService, boErrorHandlerService);

		this.tableMessage = this.appConfigService.tableEmptyMessage_loading;
	}

	ngOnInit() {
		this.initDataTable();

		this.getTableData();
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getDrawWinningPlayersSub$);
	}

	initDataTable() {
		this.dataKey = 'playerID';

		this.cols = [
			{field: 'drawID', header: 'Draw ID', sortable: false},
			{field: 'playerID', header: 'Player ID'},
			{field: 'playerExtID', header: 'Player Ext. ID'},
			{field: 'prizeCurrencyCode', header: 'Currency'},
			{field: 'totalWinAmount', header: 'Prize Money Awarded', columnType: ColumnType.Money}
		];

		this.searchFields = [
			{property: 'playerID', label: 'Player ID', type: SearchFieldType.Number},
			{property: 'playerExtID', label: 'Player Ext. ID', type: SearchFieldType.Number},
			{property: 'totalWinAmount', label: 'Prize Money Awarded', type: SearchFieldType.Number}
		];

		this.tableActions = [
			{
				menuItem: {
					label: 'Export to CSV',
					icon: 'ui-icon-insert-drive-file'
				},
				callback: (callbackObj) => {
					this.exportToCSV(callbackObj.data as DrawWinningPlayer[], callbackObj.isAllDataSelected);
				},
				isRowAction: false
			}
		];

		if (!this.appConfigService.isCentralServer) {
			this.tableActions.push({
				menuItem: {
					label: 'View Player',
					icon: 'ui-icon-person'
				},
				callback: (callbackObj) => {
					const draw = callbackObj.data[0] as DrawWinningPlayer;
					this.navigateToPlayerEvent.emit(draw);
				},
				isBulkAction: false
			});
		}
	}

	getTableData(filterData?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(filterData, forceSearchParam);

		this.params = this.computeTableDataHttpParams(filterData);
		this.beforeTableDataServiceCall(this.getDrawWinningPlayersSub$);

		this.getDrawWinningPlayersSub$ = this.drawProcService.getDrawWinningPlayers(this.drawID, this.tenantID, this.params).subscribe({
			next: (res) => {
				this.loading = false;
				this.data = res.resultSet as DrawWinningPlayer[];
				this.totalRecords = res.totalRowCount;

				if (res.resultSet.length === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}
			},
			error: error => {
				this.loading = false;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error, undefined, `Getting Winning Players for Draw ${this.drawID}`);
			}
		});
	}

	exportToCSV(selectedWinners: DrawWinningPlayer[], isAllDataSelected: boolean): void {
		this.selectedWinningPlayers = selectedWinners;

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, this.selectedWinningPlayers, isAllDataSelected, 'playerID'
		);

		this.drawProcService.getDrawWinningPlayersCsv(this.drawID, this.tenantID, exportHttpParams).subscribe({
			next: res => {
				if (res !== undefined) {
					// download file
					FileDownloadUtility.downloadCsv(res, `Winning Players for Draw ID: ${this.drawID} `);

					// clear selected statements
					this.selectedWinningPlayers = [];
				}
			},
			error: error => {
				this.boErrorHandlerService.handleError(error, undefined, 'Exporting to CSV');
			}
		});
	}
}
