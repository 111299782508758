import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Paginator, PaginatorState} from 'primeng/paginator';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {NotesUpdateBase, NotesUpdateRequest, PlayerNotesDTO} from '../../models/player/player-audit.model';
import {ComponentHelper} from '../../interfaces/component.helper';
import {PlayerNotesService} from '../../services/player-notes.service';
import {AppConfigService, BoErrorHandlerService, ConfirmDialogDisplayService, LoginService} from '../../../helio-core-services';
import {Confirmation, Message} from 'primeng/api';
import {escapeSpecialChars, formatDateWithLocale} from '../../utilities/general-utilities/string.utility';
import {getLang} from '../../utilities/general-utilities/html.utils';
import {LoadingManager} from '../../classes/loading-manager';

@Component({
	selector: 'he-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends ComponentHelper implements OnInit, OnDestroy {

	@Input() loading = false;
	@Output() submitEvent = new EventEmitter<string>();
	@Output() editNoteEvent = new EventEmitter<NotesUpdateRequest>();
	@Output() deleteNoteEvent = new EventEmitter<NotesUpdateBase>();

	private routePlayerId: number;

	private readonly locale: string;

	formGroup: FormGroup;

	first = 0;
	readonly numOfItemsPerLoad = 10; // Number of additional items per load
	private _take: number;

	totalRecords: number;
	data: PlayerNotesDTO[];

	private paginatorState: PaginatorState = {
		first: 0,
		page: 0,
		pageCount: 0,
		rows: 0,
	}

	historyRequest: NotesUpdateBase;

	private searchParams = new HttpParams();

	private notesLoadingManager: LoadingManager;
	private getPlayerNotesSub$: Subscription;
	private routeSub$: Subscription;

	constructor(
		protected appConfigService: AppConfigService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private confirmationDisplayService: ConfirmDialogDisplayService,
		private loginService: LoginService,
		private playerNotesService: PlayerNotesService,
		protected boErrorHandlerService: BoErrorHandlerService
	) {
		super();

		// this.searchParams = this.getSearchParams(this.numOfItemsPerLoad);
		this.locale = getLang();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({
			newNote: new FormControl('', [Validators.required, this.htmlContentValidator()])
		});

		this.routeSub$ = this.route.paramMap.subscribe((params: ParamMap) => {
			this.routePlayerId = Number(params.get('id'));

			this.getPlayerNotes(this.numOfItemsPerLoad);
		});
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getPlayerNotesSub$, this.routeSub$);
	}

	private getSearchParams(take: number): HttpParams {
		return this.searchParams
			.set('take', take)
			.set('offset', 0)
			.set('playerID', this.routePlayerId)
			.set('orderBy', 'playerNoteID desc');
	}

	disablePreviousNotes() {
		this.data.forEach(note => note.readonly = true);
	}

	htmlContentValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const textContent = PlayerNotesService.extractTextFromAllParagraphs(control.value) ?? '';

			if (textContent.length < 1) {
				return {'htmlContentValidator': 'Content must be at least one character long'};
			}

			return null;
		};
	}

	onSaveNote() {
		const note = this.formGroup.controls['newNote'].value;
		this.disablePreviousNotes();
		this.submitEvent.emit(escapeSpecialChars(note))
	}

	onEditPreviousNote(dto: PlayerNotesDTO) {
		this.disablePreviousNotes(); // ensures that after call returns all notes are in default readonly state before new data is shown
		this.editNoteEvent.emit({playerNoteID: dto.playerNoteID, note: escapeSpecialChars(dto.note)});
	}

	onCancelEditPreviousNote(dto: PlayerNotesDTO) {
		dto.readonly = true;
		this._invokeGetPlayerNotes();
	}

	onDeletePreviousNote(dto: PlayerNotesDTO) {
		const confMessage: Confirmation = {
			message: `Please confirm you wish to delete the selected note.`,
			accept: () => {
				this.deleteNoteEvent.emit({playerNoteID: dto.playerNoteID});
			},
			reject: () => {
				// Do nothing
			}
		};

		this.confirmationDisplayService.showConfirmDialog(confMessage);
	}

	onShowPreviousNoteHistory(dto: PlayerNotesDTO) {
		this.historyRequest = {playerNoteID: dto.playerNoteID};
	}

	onPageChange(state: PaginatorState) {
		this.paginatorState = state;

		// i.e. for the first page = 0, take = (0 * 10) + 10
		// and the second page = 1, take = (1 * 10) + 20
		const take = (state.page * state.rows) + state.rows;

		// console.log('onPageChange: take = ', take);

		this.getPlayerNotes(take);
	}

	onLoadMoreNotes(paginator: Paginator) {
		// console.log('onLoadMoreNotes: ');
		const totalPages = Math.ceil(this.totalRecords / this.numOfItemsPerLoad);

		if ((this.first / this.numOfItemsPerLoad) < totalPages - 1) {
			this.first += this.numOfItemsPerLoad;
			paginator.changePage(this.first / this.numOfItemsPerLoad);
		}
	}

	formatDate(note: PlayerNotesDTO) {
		const date: Date = note?.updatedDate ?? note.createdDate;
		return formatDateWithLocale(date, this.locale);
	}

	isOriginalCreator(dto: PlayerNotesDTO): boolean {
		return this.loginService.loggedInUserID === dto.userID.toString();
	}

	/**
	 * Intended for use only with @ViewChild, for reloading PreviousNotes section data after an add event.
	 */
	public _invokeGetPlayerNotes() {
		this.getPlayerNotes(this._take);
	}

	public _clearEditor(): void {
		this.formGroup.controls['newNote'].setValue('');
		this.formGroup.controls['newNote'].updateValueAndValidity();
	}

	private getMessage(detail: string, severity: 'success' | 'info' | 'warning' | 'danger')
		: Message[] | undefined {
		return [
			{severity, summary: '', detail}
		];
	}

	private getPlayerNotes(take: number): void {
		this._take = take;
		this.searchParams = this.getSearchParams(this._take);

		this.notesLoadingManager = new LoadingManager();
		this.notesLoadingManager.tag = 'NotesComponent#getPlayerNotes';

		this.notesLoadingManager.init()
			.then(loading => {
				this.loading = loading;
			});

		this.getPlayerNotesSub$ = this.playerNotesService.getNotes(this.searchParams).subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;
				// this.offset = res.offset;

				this.notesLoadingManager.handleLoadingCompletion()
					.then(loading => {
						this.loading = loading;
					});
			},
			error: error => {
				// const msg = this.appConfigService.genericErrorMessage;
				// this.previousNotesMessage = this.getMessage(msg, 'danger');

				this.boErrorHandlerService.handleError(error, error.description);

				this.notesLoadingManager.handleLoadingCompletion()
					.then(loading => {
						this.loading = loading;
					});
			}
		});
	}

	onEditPreviousNoteClicked(dto: PlayerNotesDTO) {
		// Explicitly set to false as cancel should only now be via the cancel action btn.
		// ensures that clicking multiple times does not negate this desired behaviour
		dto.readonly = false;
	}
}
