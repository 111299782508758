import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonTable} from '../../interfaces/common-table';
import {Subscription} from 'rxjs';
import {AppConfigService, BoErrorHandlerService} from '../../../helio-core-services';
import {ActivatedRoute} from '@angular/router';
import * as ColumnNames from '../../constants/ui-db-name-mappings';
import {ColumnType, DataTableLazyLoadEvent} from '../data-table-v3';
import {HttpParams} from '@angular/common/http';
import {PlayerNotesService} from '../../services/player-notes.service';
import {ChangeTrail, NotesUpdateBase} from '../../models/player/player-audit.model';

@Component({
	selector: 'he-update-history-dialog',
	templateUrl: './update-history-dialog.component.html',
	styleUrls: ['./update-history-dialog.component.scss']
})
export class UpdateHistoryDialogComponent extends CommonTable<ChangeTrail> implements OnInit, OnDestroy {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() historyRequest: NotesUpdateBase;

	@Output() closeEvent = new EventEmitter<void>();

	getTableDataSub$: Subscription

	routePlayerID: number;

	originalNote: string | null = null;

	private routeSubs$: Subscription;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private playerNotesService: PlayerNotesService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.initDataTable();

		this.routeSubs$ = this.route.params.subscribe(params => {
			this.routePlayerID = params.id;

			if (this.params) {
				this.getTableData(undefined);
			}
		});

		this.getTableData();
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.routeSubs$, this.getTableDataSub$);
	}

	initDataTable() {
		this.dataKey = ColumnNames.PLAYER_NOTE_UPDATED_ON.DB;

		this.cols = [
			{
				field: ColumnNames.PLAYER_NOTE_UPDATED_ON.DB,
				header: ColumnNames.PLAYER_NOTE_UPDATED_ON.UI,
				columnType: ColumnType.Date,
				sortable: false
			},
			{
				field: ColumnNames.PLAYER_NOTE_CHANGE_VALUE.DB,
				header: ColumnNames.PLAYER_NOTE_CHANGE_VALUE.UI,
				sortable: false
			}
		];

		this.searchFields = []
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTableDataHelper();
	}

	private getTableDataHelper() {
		this.getTableDataSub$ = this.playerNotesService.getNoteHistory(this.historyRequest).subscribe({
			next: res => {
				this.data = res.changeTrails;
				this.originalNote = res.originalNote;
				this.totalRecords = this.data.length;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.originalNote = null;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}
}
