import { Component, OnInit } from '@angular/core';
import { DrawType } from '../shared';
import { BreadcrumbService } from '../../helio-core-services';

@Component({
	selector: 'he-current-draws',
	styleUrls: ['./current-draws.component.scss'],
	templateUrl: './current-draws.component.html'
})

export class CurrentDrawsComponent implements OnInit {
	overview: DrawType = DrawType.ACTIVE;

	constructor(private breadcrumbService: BreadcrumbService) {}

	ngOnInit() {
		this.breadcrumbService.setItems([
			{ label: 'Draws' },
			{ label: 'Current Draws' }
		]);
	}
}
