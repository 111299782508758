<he-base-dialog header="{{dialogTitle}}" [(visible)]="visible" [isLoadingLookups]="isLoadingLookups"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="onCloseDialog()"
				[rejectBtnOnly]="!forCRUD" [useConfirmDialog]="false" [submitLabel]="submitLabel"
				(submitEvent)="onSubmit()" [disableSubmit]="!form.valid">

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<label for="accHolderName">Beneficiary Name</label>
		<input pInputText id="accHolderName" formControlName="accHolderName" autocomplete="off" type="text"/>

		<label for="nationalIdNum">National ID No.</label>
		<input pInputText id="nationalIdNum" formControlName="nationalIdNum" autocomplete="off" type="text"/>

		<label for="bankName">Bank Name</label>
		<input pInputText id="bankName" formControlName="bankName" autocomplete="off" type="text"/>

		<label for="bankBranch">Bank Branch</label>
		<input pInputText id="bankBranch" formControlName="bankBranch" autocomplete="off" type="text"/>

		<label for="bankCity">City</label>
		<input pInputText id="bankCity" formControlName="bankCity" autocomplete="off" type="text"/>

		<label for="bankState">State</label>
		<input pInputText id="bankState" formControlName="bankState" autocomplete="off" type="text"/>

		<label>{{'Country'}}</label>
		<p-dropdown [inputId]="'bankBranchCountry'" [options]="unsanctionedCountries" appendTo="body"
					formControlName="bankBranchCountry" placeholder="Please select a country">
		</p-dropdown>

		<label for="bankAccNumber">Account Number</label>
		<input pInputText id="bankAccNumber" formControlName="bankAccNumber" autocomplete="off" type="text"/>

		<label for="sortCode">Sort Code</label>
		<input pInputText id="sortCode" formControlName="sortCode" autocomplete="off" type="text"/>

		<label for="iban">IBAN</label>
		<input pInputText id="iban" formControlName="iban" autocomplete="off" type="text"/>

		<label for="swiftCode">SWIFT Code</label>
		<input pInputText id="swiftCode" formControlName="swiftCode" autocomplete="off" type="text"/>

		<label for="ifsc">IFSC</label>
		<input pInputText id="ifsc" formControlName="ifsc" autocomplete="off" type="text"/>

		<label for="routingNum">Routing No.</label>
		<input pInputText id="routingNum" formControlName="routingNum" autocomplete="off" type="text"/>
	</form>
</he-base-dialog>
