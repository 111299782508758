import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {RetailAgent} from '../../../models/retail-agent.model';
import {CommonTable} from '../../../interfaces/common-table';
import {AgentUtils} from '../../../utilities/agent.utils';
import {RetailAgentService} from '../../../services/retail-agent.service';
import {AppConfigService, BoErrorHandlerService, ToastDisplayService} from '../../../../helio-core-services';
import {CurrencyPipe} from '@angular/common';
import {ToastMessageType} from '../../../models';
import {Subscription} from 'rxjs';

@Component({
	selector: 'he-adjust-agent-balance-dialog',
	templateUrl: './adjust-agent-balance-dialog.component.html',
	styleUrls: ['./adjust-agent-balance-dialog.component.scss']
})
export class AdjustAgentBalanceDialogComponent extends CommonTable<any> implements OnInit, OnDestroy {
	@Input() visible = false;
	@Output() closeEvent = new EventEmitter<void>()

	MODIFY_BAL_LABEL = 'Adjust Balance';
	SINGLE_MODIFY_BAL_LABEL = this.MODIFY_BAL_LABEL + ' for Agent:';

	@Input() selectedAgent = new RetailAgent();
	currencySymbol: string;
	amountToAdjust: number;

	isLoadingLookups = false;
	loading = false

	private adjustRetailAgentBalanceSub$: Subscription;

	constructor(
		protected appConfigService: AppConfigService,
		private retailAgentService: RetailAgentService,
		protected boErrorHandlerService: BoErrorHandlerService,
		private toastDisplayService: ToastDisplayService,
		private currencyPipe: CurrencyPipe
	) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.currencySymbol = AgentUtils.parseCurrencyCode(this.selectedAgent, this.currencyPipe);
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.adjustRetailAgentBalanceSub$,
		);
	}

	onClose() {
		this.closeEvent.emit()
	}

	onAdjustAgentBalance(): void {
		this.beforeTableDataServiceCall(this.adjustRetailAgentBalanceSub$);

		this.adjustRetailAgentBalanceSub$ = this.retailAgentService.adjustRetailAgentBalance(
			this.selectedAgent.retailAgentID, {newBalance: this.amountToAdjust}).subscribe({
			next: (balance: { balance: number }) => {
				this.toastDisplayService.addMessage({
					title: 'Success',
					description: `Agent balance has been successfully adjusted!\n\n
								Balance is now ${this.selectedAgent.currencyCode} ${parseFloat(balance.balance.toString()).toFixed(2)}`,
					type: ToastMessageType.success
				});

				this.loading = false
				this.onClose()
			},
			error: error => {
				this.handleDataRequestError('AdjustRetailAgentBalance', error);
			}
		});
	}
}
