import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {LoginService} from '../../helio-core-services';
import {ServiceAction, ServiceController} from '../utilities';
import {CONST_LOCAL_STORAGE} from '../constants/constants';
import {MENU_PATH_TEST} from '../constants/navigation-titles';

@Injectable()
export class AppGuard implements CanActivate {
	constructor(private router: Router, private loginService: LoginService) {
	}

	public static getLoggedOperatorIDs(): string[] {
		const rights = sessionStorage.getItem('access_rights')
		return rights ? JSON.parse(rights).TenantIDs[0].split(',') : [];
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const hasPageRights = this.hasPageRights(state);

		if (!hasPageRights) {
			return this.redirectToNoAccessRights();
		}

		return of(true);
	}

	/**
	 * Temp-ish solution for handling the dynamic path nature of /user-management/users/<id>
	 * @todo update this as the addition of more paths becomes necessary
	 * @example /user-management/logs/1234 returns /user-management/logs
	 * @summary It is imperative that allowed list of static path extractions are explicitly declare.
	 * This is because an implicit parsing solution does not scale. Were the app to start including
	 * string typed IDs, it would fail. There is also the concern of handling multiple id placeholders,
	 * such as /user-management/12345/anotherPath/6789. For more comments check #7880 on the backlog.
	 */
	extractStaticPath(path: string): string | null {
		const urlPath = path.trim();

		const segments = urlPath.split('/'); // Split the path into segments using / as delimiter

		// If the last segment is a number, remove it
		const lastSegment = segments[segments.length - 1];
		if (!isNaN(Number(lastSegment))) {
			segments.pop();
		}

		return segments.join('/');
	}

	/**
	 * @example ====== Note on Dynamic URL ======
	 *          in the where agents/:id is used for path and which transforms to /retail-agents/agents/194
	 *          - the :id portion is INCLUDED in {@link RouterStateSnapshot}, i.e. url is /retail-agents/agents/194
	 *          - and so permissions will currently be denied as the BE page-permissions object does not include these
	 *          --- solution is to remove AppGuard from these routes, esp if managed on a tab by tab basis.
	 */
	hasPageRights(value: RouterStateSnapshot | string): boolean {
		let url = (typeof value === 'string') ? value : value?.url;

		if (url.includes('/my-profile') || url.includes('/logout')) {
			return true;
		}

		// extract static path if necessary
		url = this.extractStaticPath(url) ?? url;

		// get page rights object from token/local storage and check url exists
		const pagePermissionList = this.getPagePermissions();

		// Clearly defined permission
		const hasPermission = pagePermissionList.includes(url)

		// Todo: Temp solution to allow FE unique path structure
		// Special cases to deal with when id is embedded in url or the full path is not add in page_permissions
		const baseTopupPath = '/finance/topup-cards'
		const baseReconciliationPath = '/retail-agents/reconciliation'
		const basePlayersPath = '/player-management/players/'

		// const testingPath = MENU_PATH_TEST

		let hasNestedSpecialPermission = false;

		if (url.startsWith(baseTopupPath) ||
			url.startsWith(baseReconciliationPath) ||
			url.startsWith(basePlayersPath)
			// || url.startsWith(testingPath)
		) {
			hasNestedSpecialPermission = pagePermissionList.includes(baseTopupPath) ||
				pagePermissionList.includes(baseReconciliationPath) ||
				pagePermissionList.includes(basePlayersPath)
			// || pagePermissionList.includes(testingPath)
		}

		// console.log('AppGuard:' + url + ' ***  hasPermission=', hasPermission, ' -- hasNestedSpecial=', hasNestedSpecialPermission)

		return hasPermission || hasNestedSpecialPermission;
	}

	public hasActionPermission(controller: ServiceController, action: ServiceAction): boolean {
		// requestedAction = api/PlayerBalance/GetRewardPointTransactions
		const requestedAction = `${controller}${action}`.toLowerCase().replace('api/', '');
		const actionArr = this.getActionPermissions();

		let hasAction = false;

		for (const serviceAction of actionArr) {
			// action = PlayerBalance/GetRewardPointTransactions
			// requestedAction = PlayerBalance/GetRewardPointTransactions
			if (requestedAction === serviceAction.toLowerCase()) {
				hasAction = true;
				break;
			}
		}

		return hasAction;
	}

	get loggedOutOrExpiredSession() {
		return !this.loginService.isLoggedInAndTokenValid
	}

	private getPagePermissions(): string[] {
		const currentStorageState = sessionStorage.getItem(CONST_LOCAL_STORAGE.PAGES_FLATTEN);

		if (!currentStorageState) {
			// 1. Add Login and Home pages, to allowed pages, as a bare minimum, to allow initial access -
			// since permissions are no longer delivered via JWT and aren't init before this setup call
			// 2. Placing it here and not under LoginService#saveSessionData is necessary for e2e to work correctly
			sessionStorage.setItem(CONST_LOCAL_STORAGE.PAGES_FLATTEN, JSON.stringify(['/login', '/home']));
		}

		// return JSON.parse(JSON.stringify(dummyPagePermissions)); // For altering page_permissions to test the effect of missing value
		return JSON.parse(sessionStorage.getItem(CONST_LOCAL_STORAGE.PAGES_FLATTEN));
	}

	private getActionPermissions(): string[] {
		return JSON.parse(sessionStorage.getItem(CONST_LOCAL_STORAGE.ACTIONS));
	}

	private redirectToNoAccessRights() {
		const queryParams = {redirectURL: this.router.url};
		this.router.navigate(['no-access-rights'], {queryParams: queryParams});

		return of(false);
	}

}
