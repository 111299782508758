import {Injectable} from '@angular/core';
import {interval, Observable, takeWhile} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CountdownTimerService {

	public static TIMER_PLACEHOLDER = '--:--';

	private static transform(toTime: Date, countdownOverText: string = 'progress', now: Date = new Date()): string {
		if (toTime !== null && now !== undefined) {
			const nowTime = new Date(now).getTime();
			const countdownToTime = new Date(toTime).getTime();

			const diff = countdownToTime - nowTime; // this is in milliseconds, e.g. 1631 for 1.6s

			const lessThan1Sec = (diff / 1000) < 1;

			if (lessThan1Sec) {
				return countdownOverText;
			}

			return this.getRoundedClockString(diff);
		}

		return CountdownTimerService.TIMER_PLACEHOLDER;
	}

	/**
	 * Transform a given time value that is given in milliseconds or seconds into
	 * a clock string such as: "10 sec", "1 min 30 secs", etc.
	 *
	 * @param time either in milliseconds or seconds, the default format is in milliseconds
	 * @param format the default format is in milliseconds, set to seconds if time value is in such.
	 */
	public static getRoundedClockString(time: number, format: 'milliseconds' | 'seconds' = 'milliseconds'): string {
		const tempTime = (format === 'seconds') ? time * 1000 : time;

		const timeInSeconds: number = Math.floor((tempTime / 1000) % 60);
		const timeInMinutes: number = Math.floor(tempTime / (1000 * 60) % 60);
		const timeInHours: number = Math.floor(tempTime / (1000 * 60 * 60) % 24);
		const timeInDays: number = Math.floor(tempTime / (1000 * 60 * 60 * 24));

		if (timeInDays < 1) {
			if (timeInHours < 1) {
				if (timeInMinutes < 1) {
					return (timeInSeconds === 1) ? `${timeInSeconds} sec` : `${timeInSeconds} sec`;
				}

				return (timeInMinutes === 1) ? `${(timeInMinutes + 1)} min` : `${(timeInMinutes + 1)} min`;
			} else {
				return (timeInHours === 1)
					? (timeInMinutes === 0 ? `${timeInHours} hour` : `${timeInHours} hour ${timeInMinutes} min`)
					: (timeInMinutes === 0 ? `${timeInHours} hours` : `${timeInHours} hours ${timeInMinutes} min`);
			}
		}
		return (timeInDays === 1) ? `${timeInDays} day` : `${timeInDays} days`;
	}

	public static getExactClockString(time: number, format: 'milliseconds' | 'seconds' = 'milliseconds'): string {
		if (!time) {
			return '';
		}

		const tempTime = (format === 'seconds') ? time * 1000 : time;

		const timeInSeconds: number = Math.floor((tempTime / 1000) % 60);
		const timeInMinutes: number = Math.floor(tempTime / (1000 * 60) % 60);
		const timeInHours: number = Math.floor(tempTime / (1000 * 60 * 60) % 24);
		const timeInDays: number = Math.floor(tempTime / (1000 * 60 * 60 * 24));

		let clockStr = '';

		if (timeInDays > 0) {
			clockStr = timeInDays + ' day' + (timeInDays === 1 ? '' : 's')
		}

		if (timeInHours > 0) {
			clockStr = clockStr + ' ' + timeInHours + ' hour' + (timeInHours === 1 ? '' : 's')
		}

		if (timeInMinutes > 0) {
			clockStr = clockStr + ' ' + timeInMinutes + ' min'
		}

		if (timeInSeconds > 0) {
			clockStr = clockStr + ' ' + timeInSeconds + ' sec'
		}

		return clockStr.trim();
	}

	startCountdown(targetDate: Date): Observable<string> {
		let activeInterval = true;

		return interval(1000).pipe(
			map(() => {
				if (targetDate && (new Date() >= targetDate)) {
					activeInterval = false;
				}

				return CountdownTimerService.transform(targetDate);
			}),
			takeWhile(() => activeInterval)
		);
	}
}
