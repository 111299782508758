import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {CommonTable} from '../../../../shared/interfaces/common-table';
import {AppConfigService, BoErrorHandlerService, ConfirmDialogDisplayService, ToastDisplayService} from '../../../../helio-core-services';
import {BankCard} from '../../../../shared/models/finance/transfer-information.model';
import * as ColumnNames from '../../../../shared/constants/ui-db-name-mappings';
import {ColumnType, DataTableActionCallback, DataTableLazyLoadEvent, ToastMessageType} from '../../../../shared';
import {HttpParams} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {BankCardService} from '../../../../player-management/shared/services/bank-card.service';
import {ActivatedRoute} from '@angular/router';
import {Confirmation} from 'primeng/api';
import {DIALOG_CONFIRM_MSG_CRITICAL} from '../../../../shared/constants/constants';

@Component({
	selector: 'he-bank-cards-dialog',
	templateUrl: './bank-cards-dialog.component.html',
	styleUrls: ['./bank-cards-dialog.component.scss']
})
export class BankCardsDialogComponent extends CommonTable<BankCard> implements OnInit, AfterViewInit, OnDestroy {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Output() closeEvent = new EventEmitter<void>();

	selectedCard: BankCard;

	cardsTable: CommonTableImpl;

	routePlayerID: number;

	showSetAsDefaultDialog = false;
	showSDeleteDialog = false;

	getTableDataSub$: Subscription
	private routeSubs$: Subscription;
	private setAsDefaultSubs$: Subscription;
	private removeCardSubs$: Subscription;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private bankCardService: BankCardService,
				private confirmationDisplayService: ConfirmDialogDisplayService,
				private toastDisplayService: ToastDisplayService,
				private renderer: Renderer2,
				private elmRef: ElementRef,
				protected boErrorHandlerService: BoErrorHandlerService) {
		// this.cardsTable = new CommonTableImpl(appConfigService, boErrorHandlerService);
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.initDataTable();

		this.routeSubs$ = this.route.params.subscribe(params => {
			this.routePlayerID = params.id;

			if (this.params) {
				this.getTableData(undefined);
			}
		});

		this.getTableData();
	}

	ngAfterViewInit() {
		this.addElevateDropDownClass();
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.routeSubs$, this.getTableDataSub$, this.setAsDefaultSubs$, this.removeCardSubs$
		);
	}

	addElevateDropDownClass = () => {
		const body = document.querySelector('body');
		body.classList.add(ELEVATE_DROP_DOWN);
	}

	removeElevateDropDownClass = () => {
		const body = document.querySelector('body');
		body.classList.remove(ELEVATE_DROP_DOWN);
	}

	initDataTable() {
		this.dataKey = ColumnNames.CARD_ID.DB;

		// const sourceField: string = sourceId ? ColumnNames.SOURCE_NAME.DB : ColumnNames.SOURCE_TYPE.DB;

		this.cols = [
			{
				field: ColumnNames.CARD_TYPE.DB,
				header: ColumnNames.CARD_TYPE.UI,
				columnType: ColumnType.ImageSnippet
			},
			{
				field: ColumnNames.CARD_EXPIRY_DATE.DB,
				header: ColumnNames.CARD_EXPIRY_DATE.UI
			},
			{
				field: ColumnNames.CARD_NUMBER.DB,
				header: ColumnNames.CARD_NUMBER.UI
			},
			{
				field: ColumnNames.CARD_ADDED_DATE.DB,
				header: ColumnNames.CARD_ADDED_DATE.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.CARD_LAST_USED_DATE.DB,
				header: ColumnNames.CARD_LAST_USED_DATE.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.CARD_IS_DEFAULT.DB,
				header: ColumnNames.CARD_IS_DEFAULT.UI,
				columnType: ColumnType.TrueFalse
			}
		];

		this.tableActions = [
			{
				menuItem: {
					label: 'Set as Default',
					icon: 'ui-icon-favorite'
				},
				callback: (callbackObj: DataTableActionCallback) => {
					this.selectedCard = callbackObj.data[0];
					this.onCogActionClicked(
						'Are you sure you want to set card as default?', this.selectedCard, this.setAsDefaultHandler);
				}
			},
			{
				menuItem: {
					label: 'Delete',
					icon: 'ui-icon-delete'
				},
				callback: (callbackObj) => {
					this.selectedCard = callbackObj.data[0];
					this.onCogActionClicked(DIALOG_CONFIRM_MSG_CRITICAL, this.selectedCard, this.deleteHandler);
				}
			}
		];

		this.searchFields = []

		this.fetchLookups();
	}

	fetchLookups() {
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);

		this.getTableDataHelper();
	}

	private getTableDataHelper() {
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTableDataSub$ = this.bankCardService.getCards(this.routePlayerID, this.params).subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onCloseDialog() {
		this.removeElevateDropDownClass();
		this.closeEvent.emit();
	}

	onCogActionClicked(msg: string, selectedMethod: BankCard, handler: (selectedMethod: BankCard) => void) {
		const confirmHandler = (isVisible: boolean) => {
			this.renderer.setStyle(this.elmRef.nativeElement, 'visibility', isVisible ? 'visible' : 'hidden');
			this.visible = isVisible; // necessary so the contents of BankCardsSDialog, namely he-base-dialog, is also hidden or shown
		}

		const confMessage: Confirmation = {
			message: msg,
			accept: () => {
				confirmHandler(true);
				this.beforeTableDataServiceCall(this.getTableDataSub$);
				handler(selectedMethod);
			},
			reject: () => {
				confirmHandler(true);
			}
		};

		confirmHandler(false);
		this.confirmationDisplayService.showConfirmDialog(confMessage);
	}

	setAsDefaultHandler = (selectedMethod: BankCard) => {
		this.releaseSubscriptions(this.setAsDefaultSubs$)

		this.setAsDefaultSubs$ = this.bankCardService.setAsDefault(this.routePlayerID, selectedMethod).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Payment method set as default.'
				});

				this.getTableDataHelper();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableDataHelper();
			}
		});
	}

	deleteHandler = (selectedMethod: BankCard) => {
		this.releaseSubscriptions(this.removeCardSubs$)

		this.removeCardSubs$ = this.bankCardService.remove(this.routePlayerID, selectedMethod).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Payment method deleted.'
				});

				this.getTableDataHelper();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableDataHelper();
			}
		});
	}
}

export class CommonTableImpl extends CommonTable<any> {
}

// Use to temporarily add the class so body attached DropDown opt can be elevated above a dialog (when shown)
export const ELEVATE_DROP_DOWN = 'elevateDropDown';
