export interface AuthImpl {
	client_id: string;
	client_secret: string;
}

export class Login implements AuthImpl {
	client_id: string;
	client_secret: string;

	constructor(
		public username: string = '',
		public password: string = '',
		public grant_type: string = 'password'
	) {
	}
}

export interface AuthData extends AuthImpl {
	grant_type: string;
	refresh_token: string;
	action_type?: string;
}

export interface LogoutRes {
	IsSuccess: boolean;
	ErrorMsg: any;
	ErrorCode: any;
}
