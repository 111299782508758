import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApproveManualResultsData, DrawProcessingService, ManualApprovalOpts, ManualEntryType, ManualInsertResultsData} from '../shared';
import {Draw, ToastMessageType} from '../../shared/models';
import {BoErrorHandlerService, GameAdministrationService, ToastDisplayService} from '../../helio-core-services';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {isDefinedArray} from '../../shared/utilities/general-utilities/arrays.utils';

/**
 * For the insertion and approval of manual draws.
 */
@Component({
	selector: 'he-manual-draw-entry-dialog[draw][entryType][gameFormat]',
	styleUrls: [
		'./manual-draw-entry-dialog.component.scss',
		'./../../shared/components/dialog/common-draw-dialog-style.scss'
	],
	templateUrl: './manual-draw-entry-dialog.component.html'
})
export class ManualDrawEntryDialogComponent implements OnInit {
	@Input() draw: Draw;
	@Input() entryType: ManualEntryType;
	@Input() visible = false;

	@Output() closeEvent = new EventEmitter<void>();
	@Input() requestCallback: (data: ManualInsertResultsData | ApproveManualResultsData) => Promise<void>;

	isFormInit = false;

	// To ensure that when transition from on approval to the other, the previous res is not briefly vis
	// this works because gameFormat is being used for the templates *ngIf condition
	staleValues = false;

	loading = false

	formGroup: FormGroup;

	private _gameFormat$ = new BehaviorSubject<GameFormat>(null);
	private _lotteryResults$ = new BehaviorSubject<LotteryResult[]>(null);

	BOARD_IDENTIFIER_PREFIX = 'boardNum';
	boards: number[][]; // element 0 represents the main board

	approvalOpts = ManualApprovalOpts;

	submitLabel = '';
	canEnableSubmit = false;

	readonly INSERT_INFO = 'To manually insert the draw results, please insert each number within the provided ' +
		'textboxes and provide a reason';

	readonly APPROVE_INFO = 'To approve the manually inserted results, please click on the provided \'Approve \' button, or ' +
		'reject by clicking on \'Reject Results\'';

	constructor(
		private drawPrcService: DrawProcessingService,
		private gameAdminService: GameAdministrationService,
		private errorService: BoErrorHandlerService,
		private toastDisplayService: ToastDisplayService,
		private formBuilder: FormBuilder
	) {
	}

	@Input() set gameFormat(value: GameFormat) {
		this._gameFormat$.next(value);
	}

	get gameFormat() {
		return this._gameFormat$.getValue();
	}

	@Input() set lotteryResults(value: LotteryResult[]) {
		this._lotteryResults$.next(value);
	}

	get lotteryResults() {
		return this._lotteryResults$.getValue();
	}

	ngOnInit() {
		if (!this.entryType) {
			throw new EvalError('IllegalState: entryType (dialog type) must be defined.')
		}

		// Init the boards if #gameFormat present, or difer till later
		this.boards = [];
		this.formGroup = this.formBuilder.group({});

		this._gameFormat$.asObservable().subscribe({
			next: value => {
				this.onGameFormatOrLotteryChange();
			}
		});

		this._lotteryResults$.asObservable().subscribe({
			next: () => {
				this.onGameFormatOrLotteryChange();
			}
		});

		let interval;
		let count = 5;

		if (this.entryType === 'insertion') {
			this.submitLabel = 'Insert Results';
			this.canEnableSubmit = true;
		} else {
			this.submitLabel = 'Submit - 00:05';
			interval = window.setInterval(() => {
				count--;

				this.submitLabel = `Submit - 00:0${count}`;

				if (count <= -1) {
					this.submitLabel = `Submit`;
					this.canEnableSubmit = true;
					window.clearInterval(interval);
				}
			}, 1000)
		}

		this.onGameFormatOrLotteryChange();
	}

	onGameFormatOrLotteryChange() {
		this.staleValues = false;

		if (this.entryType === 'insertion') {
			// && !this.isFormInit
			if (this.gameFormat?.gameBoards) {
				this.initFormHelper();
			}
		} else {
			// For approval both lotteryResults and gameBoards are required.
			//  && !this.isFormInit is removed to allow moving from one row to another to update the results to approve
			if (this.lotteryResults && this.gameFormat?.gameBoards) {
				this.initFormHelper();
			}
		}
	}

	onSubmit() {
		// Currently combines main and secondary board(s) - i.e. ["A", "B", "C"] + ["D"] ==> ["A", "B", "C", "D"]
		const newResults: number[] = []
		for (let i = 0; i < this.boards.length; i++) {
			newResults.push(...this.formGroup?.get(this.BOARD_IDENTIFIER_PREFIX + i).value)
		}

		const reason = this.formGroup?.get('reason').value;

		let request: ManualInsertResultsData | ApproveManualResultsData

		// IF ELSE use to allow support compile time error check of the data structure (ManualInsertResultsData | ApproveManualResultsData)
		if (this.entryType === 'insertion') {
			request = {
				drawID: this.draw.drawID,
				newResults,
				reason
			} as ManualInsertResultsData
		} else {
			request = {
				drawID: this.draw.drawID,
				resultNumbers: newResults,
				isRejected: this.formGroup?.get('isRejected')?.value,
				comment: reason
			} as ApproveManualResultsData
		}

		this.loading = true

		if (this.requestCallback) {
			this.requestCallback(request).then(data => {
				this.loading = false
			}).catch(err => this.loading = false)
		} else {
			console.error('ManualDrawEntryDialogComponent: approval requestCallback has not been defined!')
		}
	}

	onCloseDialog(show: boolean) {
		// This is preferred since setting this.gameFormat = undefined seems to cause the form to be destroyed before data is extracted
		this.staleValues = true;
	}

	getFormArrayCtrAndInitVal(name: string, at: number, array: number[]): FormControl {
		const ctrl = (this.formGroup?.controls[name] as FormArray).at(at) as FormControl;

		if (this.entryType === 'approval') {
			ctrl.setValue(array[at]);
			ctrl.disable();
		}

		return ctrl;
	}

	private initFormHelper(): void {
		if (!isDefinedArray(this.gameFormat.gameBoards)) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: 'Error',
				description: 'Game board is not defined, please notify customer support.'
			});
		}

		this.gameFormat.gameBoards.forEach((gameBoard, index) => {
			if (this.entryType === 'insertion') {
				this.boards[index] = Array(gameBoard.drawnNumbers);
			} else {
				this.boards[index] = this.lotteryResults[index].numbers;

				if (gameBoard.drawnNumbers !== this.boards[index].length) {
					throw new RangeError('IllegalState: the inserted manual results does not match validation expectations.')
				}
			}
		});

		const boardsFormArrayObj: any = {};
		// TODO - Enforce that the board is sorted at this point using "boardNum" from 1 ... max

		this.gameFormat.gameBoards.forEach((board, index) => {
			const boardValidationArr: FormControl[] = [];

			// This generates an array of FormControls for each number with a specific board
			// Each of this is then used towards validation for that specific board
			for (let i = 0; i < board.drawnNumbers; i++) {
				boardValidationArr.push(
					new FormControl('',
						[
							Validators.required,
							Validators.min(board.minNumber),
							Validators.max(board.maxNumber)
						])
				);
			}

			// This produces entries for the FormGroup, in the form:
			//  field: new FormArray(arr)
			boardsFormArrayObj[this.BOARD_IDENTIFIER_PREFIX + index] = new FormArray(boardValidationArr);
		});

		this.formGroup = this.formBuilder.group({
			...boardsFormArrayObj,
			// bonusSelection: new FormArray(bonusSelectionArr),
			reason: new FormControl('', {
				validators: [Validators.required, Validators.minLength(10), Validators.maxLength(5000)]
			})
		});

		if (this.entryType === 'approval') {
			this.formGroup.addControl('isRejected', new FormControl(null, [
				Validators.required
			]));
		}

		this.isFormInit = true;
	}
}

/**
 * @example EuroJackpot result = 27, 40, 14, 24, 11 | 11, 12
 *          boardNum: 1 is 27, 40, 14, 24, 11 and boardNum: 2 is denoted by 11, 12.
 */
export interface GameFormat {
	gameGroupName: string,
	gameGroupId: number,
	gameVertical: string,
	numBoards: number,
	gameBoards: GameBoard[]
}

export interface GameBoard {
	boardNum: number,
	minNumber: number,
	maxNumber: number,
	drawnNumbers: number,
	wagerColumnsMin: number,
	wagerColumnsMax: number,
	reuseNumbers: boolean
}

export interface LotteryResult {
	boardNum: number,
	numbers: number[]
}
