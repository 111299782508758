<he-base-dialog [header]="'Transfer Details' + (provider.paymentMethod ? ' - ' : ' ') + provider.paymentMethod"
				[(visible)]="visible" [isLoadingLookups]="false"
				[style]="{'max-height': '80%', height: '900px', width: '100%'}" (visibleChange)="onCloseDialog()"
				rejectBtnOnly="true" rejectLabel="Close" class="basic-dialog-table">

	<!--[selectedRows]="selectedCards"-->
	<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data" [loading]="loading"
					  (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords"
					  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields">
		<div class="he-specific-table-actions">
			<button class="helio-orange-btn" pButton type="button" (click)="showCreateDialog = true"
					[label]="'Add eWallet Details'" [title]="'Add eWallet Details'" icon="pi pi-plus"
					[disabled]="loading"></button>
		</div>
	</he-data-table-v3>

</he-base-dialog>

<he-e-wallet-editor-dialog *ngIf="showCreateDialog" [(visible)]="showCreateDialog" operation="Create"
						   style="position: absolute" forCRUD="true" [selectedEWallet]="selectedRow"
						   [submitLabel]="'Add'" [provider]="provider" [playerPartialData]="playerPartialData"
						   (closeEvent)="showCreateDialog = false; selectedRow = null;"
						   (submitEvent)="onCreateEWalletDetailsSubmit($event)"></he-e-wallet-editor-dialog>

<he-e-wallet-editor-dialog *ngIf="showEditDialog" [(visible)]="showEditDialog" operation="Edit"
						   style="position: absolute" forCRUD="true" [selectedEWallet]="selectedRow"
						   [submitLabel]="'Edit'" [provider]="provider" [playerPartialData]="playerPartialData"
						   (closeEvent)="showEditDialog = false; selectedRow = null;"
						   (submitEvent)="onEditEWalletDetailsSubmit($event)"></he-e-wallet-editor-dialog>
