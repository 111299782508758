import * as fileSaver from 'file-saver';

export class FileDownloadUtility {

	private static filenameHasExt(filename: string) {
		const regEx = /^[A-Za-z0-9_-]+.[A-Za-z]+/g;
		return regEx.test(filename);
	}
	/**
	 * Function to download CSV File
	 * @param responseData File contents retrieved from server
	 * @param filename Filename of the downloaded file - do not include extension
	 */
	public static downloadCsv(responseData: any, filename: string): void {
		const encoder = new TextEncoder();
		const BOM = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM bytes
		const encodedData = encoder.encode(responseData);

		const blob = new Blob([BOM, encodedData], { type: 'text/csv;charset=utf-8;' });
		const finalFilename = `${filename}${(this.filenameHasExt(filename) ? '' : '.csv')}`;

		fileSaver.saveAs(blob, finalFilename);
	}

	public static downloadZip(responseData: Blob, filename: string): void {
		// const blob = new Blob(responseData, { type: 'application/zip' });
		fileSaver.saveAs(responseData, `${filename}${(this.filenameHasExt(filename) ? '' : '.zip')}`);
	}

	/**
	 * This relies on the correct file ext being already set in the filename string.
	 */
	public static downloadAsIs(responseData: Blob, filename: string): void {
		fileSaver.saveAs(responseData, `${filename}`);
	}
}
