import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ColumnType, PrimeNGColumn} from '../shared';
import {AppConfigService} from '../../../../helio-core-services/services/app-config.service';
import {REPLACE_DIRECTIVE_DEFAULT} from '../../../directives/replace-invalid-with.directive';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {BehaviorSubject} from 'rxjs';
import {ColumnDataPipe} from './column-data.pipe';
import {EMPTY_CURRENCY_CODE} from '../../../utilities/general-utilities/currency.utility';

@Component({
	selector: 'he-column-data',
	styleUrls: [
		'./column-data.component.scss'
	],
	templateUrl: './column-data.component.html'
})
export class ColumnDataComponent implements OnInit {
	private _data$ = new BehaviorSubject<any>(undefined);
	@Input() column: PrimeNGColumn;

	columnType: typeof ColumnType = ColumnType;

	dateFormat: string;
	timezoneOffset: string;
	numberFormat: string;
	moneyFormat: string;

	@HostBinding('attr.column-type') colType;

	replacementStr = REPLACE_DIRECTIVE_DEFAULT;
	invalidValues: string[] = ['null', 'undefined', '{}', '[]', ''];

	rawValue: any;
	consumerValue: any; // This is the value extracted using the consumer this.column.extractData
	valueUsed: string; // Assigns the value set on the component so that it can be used for its html title

	constructor(
		protected appConfigService: AppConfigService,
		private currencyPipe: CurrencyPipe,
		private decimalPipe: DecimalPipe,
		private colDataPipe: ColumnDataPipe,
		private datePipe: DatePipe
	) {
	}

	public static containsBindingSyntax(s: string) {
		const regEx = /^{{2}[\w\s]*}{2}$/g // Example target: {{user}}
		return s ? regEx.test(s) : false;
	}

	@Input() set data(value: any) {
		this._data$.next(value);
	}

	get data() {
		return this._data$.getValue();
	}

	ngOnInit() {
		this.dateFormat = this.appConfigService.defaultDateFormat ?? 'dd/MM/yyyy HH:mm:ss \'UTC\'';
		// offset is +0000 is important to attain UTC = GMT, i.e. Daylight Saving Time (DST) is not taken into account
		this.timezoneOffset = this.appConfigService.defaultTimezoneOffset ?? '+0000'; // set default since defaults to sys if undefined
		this.numberFormat = this.appConfigService.defaultNumberFormat;
		this.moneyFormat = this.appConfigService.defaultMoneyFormat;

		this.colType = this.column.columnType;

		this._data$.asObservable().subscribe({
			next: (value) => {
				if (value) {
					this.rawValue = this.getValue(value, this.column.field);
					this.valueUsed = this.replaceIfInvalid(this.rawValue);

					this.consumerValue = this.column?.extractData ? this.column.extractData(this.rawValue) : null;
				} else {
					this.rawValue = null;
					this.valueUsed = null;
					this.consumerValue = null;
				}
			}
		});
	}

	getTooltipString(data: any[]): string {
		return data.join(', ');
	}

	getValue(data: any, field: string): any {
		let value = this.colDataPipe.transform(data, field);

		if (this.column?.columnType === this.columnType.Number) {
			value = this.decimalPipe.transform(value, this.numberFormat);
		} else if (this.column?.columnType === this.columnType.Date) {
			value = this.datePipe.transform(value, this.getDateFormat(), this.timezoneOffset, this.getSystemLocale());
		} else if (this.column?.columnType === this.columnType.Money) {
			value = this.decimalPipe.transform(value, this.moneyFormat);
		}

		return value;
	}

	replaceIfInvalid(value: any) {
		// console.log('replaceIfInvalid: ') // this.column.field

		// Not using "!value" for flag; in order to differentiate from value === 0, which is falsy
		const flag = value === undefined || value === null || this.invalidValues.includes(value)
			|| ColumnDataComponent.containsBindingSyntax(value);

		let tempValueUsed = value;

		if (flag) {
			return this.replacementStr; // return "N/A"
		}

		// if decimalPlaces is defined, attempt to transform else just return the value as is;
		if (this.column.localeCurrencyCodeField) {
			try {
				// In the event of undefined code, use empty str to still apply other currency formating
				const currencyCode = this.data[this.column.localeCurrencyCodeField] ?? EMPTY_CURRENCY_CODE
				tempValueUsed = this.transformToCurrencyLocale(value, currencyCode);
			} catch (e) {
				tempValueUsed = value;
			}
		} else if (this.column.localeDecimalPlaces) {
			tempValueUsed = this.transformToDecimalLocale(value, this.column.localeDecimalPlaces);
		}

		return tempValueUsed;
	}

	getDateFormat() {
		return this.column.dateFormat ?? this.dateFormat;
	}

	private transformToCurrencyLocale(amount: any, code: string): string {
		try {
			let formattedValue: string = amount;
			const locale = this.getSystemLocale();

			const val: number = Number(amount);

			if (typeof val === 'number' && !isNaN(val)) {
				formattedValue = this.currencyPipe.transform(amount, code, 'symbol', '1.2-2');
			}

			return formattedValue;
		} catch (error) {
			console.warn('Error transforming currency amount:', error);
			return amount;
		}
	}

	private transformToDecimalLocale(value: any, decimalPlaces: number): string {
		try {
			let formattedValue: string = value;
			const locale = this.getSystemLocale();

			const val: number = Number(value);

			if (typeof val === 'number' || !isNaN(Number(val))) {
				const roundedValue = this.truncateToTwoDecimalPlaces(value);
				formattedValue = this.decimalPipe.transform(roundedValue, this.determineDecimalFormat(roundedValue, decimalPlaces), locale);
				// formattedValue = roundedValue.toString();
			}

			return formattedValue;
		} catch (error) {
			console.warn('Error transforming value:', error);
			return value;
		}
	}

	private getSystemLocale(): string {
		// Implement logic to get the system locale.
		// For example: returns 'da' for Denmark locale: mapping the value given in register-locales.ts import
		return navigator.language || 'en-US';
	}

	/**
	 * Dynamically create a format string to use with decimalPipe#transform based on the actual
	 * decimal places present in the value.
	 * @example This ensures that 45 is represented as is and not as 45.00
	 */
	private determineDecimalFormat(value: number, decimalPlaces: number): string {
		const decimalPart = value.toString().split('.')[1];

		if (decimalPart) {
			return `1.${decimalPlaces}-${decimalPlaces}`;
		} else {
			return `1.0-0`; // whole number
		}
	}

	/**
	 * Required as Angular does not support specifying rounding behavior directly.
	 */
	private truncateToTwoDecimalPlaces(value: number) {
		const regex = /^-?\d+\.\d{2}/;
		const match = value.toString().match(regex);
		return match ? parseFloat(match[0]) : value;
	}
}
