<ng-container [ngSwitch]="column.columnType">
	<!-- String -->
	<!-- 1. so that all cases are not traverse for each cell only to get the commonly used String case,
	        assume undefined cases are string - this is currently compatible with the current app-wide
	        impl, where string cases are not explicitly defined as columnType.String
	     2. If an expected cell is not formatted in the expected way, chances are the columnType field on columns
	         has not been set and thus it is implicitly taken as string, to fix such bugs: declare "columnType:
	         ColumnType.Date", for example, in the "columns" object of the appropriate table's component -->

	<ng-container *ngSwitchCase="undefined">
		{{ valueUsed }}
	</ng-container>

	<ng-container *ngSwitchCase="columnType.String">
		{{ valueUsed }}
	</ng-container>

	<!-- Number -->
	<ng-container *ngSwitchCase="columnType.Number">
		{{ valueUsed }}
	</ng-container>

	<!-- Date -->
	<ng-container *ngSwitchCase="columnType.Date">
		<ng-container *ngIf="(this.rawValue) === '9999-01-01T00:00:00Z'; else notIndefiniteDate">
			Indefinite
		</ng-container>

		<ng-template #notIndefiniteDate>
			{{ valueUsed }}
		</ng-template>
	</ng-container>

	<!-- Money -->
	<ng-container *ngSwitchCase="columnType.Money">
		{{ valueUsed }}
	</ng-container>

	<!-- True/False - Check or Cross -->
	<ng-container *ngSwitchCase="columnType.TrueFalse">
		<i *ngIf="this.rawValue === false" class="fa ui-icon-close"></i>
		<i *ngIf="this.rawValue === true" class="fa ui-icon-check"></i>
	</ng-container>

	<!-- Tooltip -->
	<ng-container *ngSwitchCase="columnType.Tooltip">
		<span *ngIf="consumerValue?.length === column.tooltipMin">
			{{ getTooltipString(consumerValue) }}
		</span>
		<span *ngIf="consumerValue?.length > column.tooltipMin">
			<span appendTo="body"
				  pTooltip="{{ getTooltipString(consumerValue) }}">
				{{ consumerValue?.length }} {{ column.tooltipLabel }}
			</span>
		</span>
	</ng-container>

	<!-- List -->
	<ng-container *ngSwitchCase="columnType.List">
		<span>{{ consumerValue }}</span>
	</ng-container>

	<!-- Toggle -->
	<ng-container *ngSwitchCase="columnType.Toggle">
		<span class="datatable-toggle-span false" *ngIf="(this.rawValue) === true"
			  (click)="column.toggleClick(data)">{{ column.toggleTrueText }}</span>
		<span class="datatable-toggle-span true" *ngIf="(this.rawValue) === false"
			  (click)="column.toggleClick(data)">{{ column.toggleFalseText }}</span>
	</ng-container>

	<!-- Link -->
	<ng-container *ngSwitchCase="columnType.Link">
		<ng-container *ngIf="column.getRouterLink(data); else invalidHyperLink">
			<a *ngIf="!column.dynamicLinkText" [routerLink]="column.getRouterLink(data)">{{ column.linkText }}</a>
			<a *ngIf="column.dynamicLinkText"
			   [routerLink]="column.getRouterLink(data)">{{ column.getLinkText(data) }}</a>
		</ng-container>

		<ng-template #invalidHyperLink>
			<span *ngIf="!column.dynamicLinkText">{{ column.linkText }}</span>
			<span *ngIf="column.dynamicLinkText">{{ column.getLinkText(data) }}</span>
		</ng-template>
	</ng-container>

	<!-- List -->
	<div *ngSwitchCase="columnType.ImageSnippet" style="height: 30px; width: 42px">
		<img [src]="data?.src" [alt]="'Credit/debit card symbol'"
			 style="object-fit: contain; width: 100%; height: 100%;"/>
	</div>

	<!-- Action -->
	<ng-container *ngSwitchCase="columnType.Action">
		<span [class.he-disabled]="!column?.enableActionPredicate(data)" class="datatable-action-span"
			  (click)="column.actionClick(data)">{{ column.actionText }}</span>
	</ng-container>

	<!-- FAB -->
	<ng-container *ngSwitchCase="columnType.FAB">
		<!-- It is important that "pi" in icon is left for prime icon column.fabIcon or others substituted in
		programmatically to work. It is done this way to allow easy use of classList.remove, as passing "pi pi-menu",
		for example, throws an err - i.e. one class needs to be passed to the method at once. This should not posses
		any confusion later as this button will be component-ise into TwoStateColumnFAB-->
		<button #iconBtn pButton icon="pi {{column.fabIcon}}" class="p-button-rounded"
				(click)="column.actionClick({data, iconBtn: iconBtn})"></button>
	</ng-container>
</ng-container>


