import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryGroupLookup} from '../../../interfaces/lookup-interfaces';
import {RE_ALPHABETIC_WS, RE_ALPHANUMERIC, RE_ALPHANUMERIC_WS, RE_NUMERIC} from '../../../constants/constants';
import {AppGuard} from '../../../guards';
import {LookupService} from '../../../services/lookup.service';
import {BoErrorHandlerService, ToastDisplayService} from '../../../../helio-core-services';
import {forkJoin, Subscription} from 'rxjs';
import {ExchangeHouse, ExchangeHouseRequest} from '../../../models/finance/transfer-information.model';
import {Player} from '../../../models/player/players.model';
import {DialogEditorType} from '../dialog-opts';
import {ToastMessageType} from '../../../models';
import {ComponentHelper} from '../../../interfaces/component.helper';
import {BankCardService} from '../../../../player-management/shared/services/bank-card.service';

@Component({
	selector: 'he-exchange-house-editor-dialog',
	templateUrl: './exchange-house-editor-dialog.component.html',
	styleUrls: ['./exchange-house-editor-dialog.component.scss']
})
export class ExchangeHouseEditorDialogComponent extends ComponentHelper implements OnInit, OnDestroy {
	@Input() visible = false;
	@Input() playerID: number;
	_routePlayerID: number

	@Input() playerPartialData: Player;

	@Input() dialogTitle: 'Exchange House' | 'Add Exchange House' | 'Edit Exchange House' = 'Exchange House';
	@Input() submitLabel = 'Submit';

	@Input() type: DialogEditorType;
	@Input() editable = true;

	@Input() exchangeHouse: ExchangeHouse;
	@Input() tenantID?: number;

	@Output() closeEvent = new EventEmitter<void>();
	// @Output() submitEvent = new EventEmitter<ExchangeHouseRequest>();

	form: FormGroup;

	allCountries: CountryGroupLookup[] = [];
	unsanctionedCountries: CountryGroupLookup[] = [];

	isLoadingLookups = false;

	loading = false;

	private createExchangeHouseSub$: Subscription
	private updateExchangeHouseSub$: Subscription

	constructor(
		private appGuard: AppGuard,
		private formBuilder: FormBuilder,
		private bankCardService: BankCardService,
		private lookupService: LookupService,
		private toastDisplayService: ToastDisplayService,
		private boErrorHandlerService: BoErrorHandlerService) {
		super();
	}

	ngOnInit() {
		// this.canUpdateBankDetails = this.appGuard.hasActionPermission(ServiceController.WITHDRAWALS, ServiceAction.UPDATE_BANK_DETAILS);

		if (this.type === DialogEditorType.EDIT && !this.exchangeHouse) {
			throw new Error('ExchangeHouseEditorDialogComponent#ngOnInit:' +
				' this.exchangeHouse must be defined for EDIT mode.')
		}

		this._routePlayerID = this.playerID

		this.formInit();
		this.setFormData();

		this.setState(true); // so that all editable inputs can take user input
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.createExchangeHouseSub$, this.updateExchangeHouseSub$,)
	}

	onSubmit() {
		const rawValue = this.form.getRawValue();

		const request: ExchangeHouseRequest = {
			playerID: null,
			// paymentOrderID: this.exchangeHouse?.paymentOrderID,
			// playerPaymentAccountID: this.exchangeHouse?.playerPaymentAccountID,

			beneficiaryName: rawValue['accHolderName'],
			mobileNumber: rawValue['mobileNumber'],
			nationalIDNumber: rawValue['nationalIdNum'],

			bankName: rawValue['bankName'],
			bankCity: rawValue['bankCity'],
			bankState: rawValue['bankState'],
			bankBranchCountryID: rawValue['bankBranchCountry']
		}

		if (this.type === DialogEditorType.CREATE) {
			this.onCreateExchangeHouseSubmit(request)
		} else if (this.type === DialogEditorType.EDIT) {
			this.onEditExchangeHouseSubmit(request)
		}
	}

	onCreateExchangeHouseSubmit(request: ExchangeHouseRequest) {
		this.loading = true
		request.playerID = this._routePlayerID;

		this.releaseSubscriptions(this.createExchangeHouseSub$);

		this.createExchangeHouseSub$ = this.bankCardService.addExchangeHouse(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Exchange house added.'
				});

				this.loading = false
				this.onCloseDialog()
			},
			error: error => {
				this.loading = false
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onEditExchangeHouseSubmit(request: ExchangeHouseRequest) {
		this.loading = true
		request.playerPaymentAccountID = this.exchangeHouse.playerPaymentAccountID;

		this.releaseSubscriptions(this.updateExchangeHouseSub$);

		this.updateExchangeHouseSub$ = this.bankCardService.updateExchangeHouse(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Exchange house updated.'
				});

				this.loading = false
				this.onCloseDialog()
			},
			error: error => {
				this.loading = false
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	private formInit() {
		let accHolderName = '';

		// Populate fullName for accHolderName input with this.playerPartialData for create new;
		if (this.type === DialogEditorType.CREATE) {
			accHolderName = this.playerPartialData.fullName;
		} else if (this.type === DialogEditorType.EDIT) {
			accHolderName = this.exchangeHouse.beneficiaryName;
		} else {
			console.error('ExchangeHouseEditorDialogComponent:' +
				' Neither Create nor Edit has been set as type for formInit.')
		}

		this.form = this.formBuilder.group({
			accHolderName: new FormControl(accHolderName, {
				validators: [Validators.required, Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			mobileNumber: new FormControl(this.exchangeHouse ? this.exchangeHouse.mobileNumber : '', {
				validators: [Validators.pattern(RE_NUMERIC)]
			}),
			nationalIdNum: new FormControl(this.exchangeHouse ? this.exchangeHouse.nationalIDNumber : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			bankName: new FormControl(this.exchangeHouse ? this.exchangeHouse.bankName : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC_WS)]
			}),
			bankCity: new FormControl(this.exchangeHouse ? this.exchangeHouse.bankCity : '', {
				validators: [Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			bankState: new FormControl(this.exchangeHouse ? this.exchangeHouse.bankState : '', {
				validators: [Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			bankBranchCountry: new FormControl(undefined)
		});

		this.setState(false);
	}

	setState(enabled: boolean) {
		for (const entry in this.form.controls) {
			if (entry && enabled) {
				this.form.controls[entry].enable();
			} else if (entry && !enabled) {
				this.form.controls[entry].disable();
			}
		}

		this.form.updateValueAndValidity();
	}

	private setFormData() {
		this.isLoadingLookups = true;

		const observables: any = {
			getPlayerNomenclature: this.lookupService.getPlayerNomenclature()
		};

		forkJoin(observables).subscribe({
			next: (res: any) => {
				this.allCountries = res.getPlayerNomenclature.result.countries;
				this.unsanctionedCountries = res.getPlayerNomenclature.result.unblockedCountries;

				if (this.type === DialogEditorType.EDIT && this.exchangeHouse) {
					// this.form.get('countryOfBirth').setValue(this.bankDetails.countryOfBirthId);
					this.form.get('bankBranchCountry').setValue(this.exchangeHouse.bankBranchCountryID);
				}

				this.isLoadingLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}
}
