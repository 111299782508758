import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfigService} from './app-config.service';
import {ServiceAction, ServiceController} from '../../shared/utilities/service-utilities';
import {DataTableServerResponse, TableDataResponse} from '../../shared/components/data-table-v3';
import {Group} from '../../shared/models/group';
import {BaseServiceImpl} from '../../shared/services/base-service-impl';

/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class GroupsService extends BaseServiceImpl {

	public totalElementCount: number;

	/**
	 * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
	 */
	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.USER_GROUPS, appConfigService.serviceBaseURL);
	}

	createGroup(group: Group) {
		return this.post(undefined, group);
	}

	editGroup(group: Group) {
		return this.edit(undefined, group);
	}

	getGroup(groupID: number, searchParams?: HttpParams): Observable<Group> {
		return this.get(undefined, [groupID], searchParams);
	}

	getGroups(searchParams?: HttpParams): Observable<TableDataResponse<any>> {
		return this.validateDataTableRes([],undefined, undefined, searchParams);
	}

	remove(groupID: number): Observable<void> {
		return this.delete(ServiceAction.GENERIC_DELETE, [groupID])
	}
}
